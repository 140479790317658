<template>
  <div class="infochild">
    <!-- 日期范围检索 -->
    <myRangePickerVue
      @searchTime="searchTime"
      :defaultTime="defaultTime"
      :type="'second'"
    />
    <a-spin :spinning="spinning">
      <div v-if="!show || spinning">
        <Empty
          :style="{ height: spinning && init ? 0 : '230px' }"
          description="暂无数据"
        />
      </div>
      <div v-show="show" :id="title"></div>
    </a-spin>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  onMounted,
  ref,
  onUpdated,
  getCurrentInstance,
  onUnmounted,
} from "vue";
import moment from "moment";
import { Chart, registerInteraction } from "@antv/g2";
import { message, Empty, Spin as ASpin } from "ant-design-vue";
import myRangePickerVue from "./myRangePicker.vue";
export default {
  components: {
    myRangePickerVue,
    Empty,
    ASpin,
  },
  setup(props) {
    const { proxy } = getCurrentInstance();
    let data = reactive({
      province: 1,
      title: "12312",
      chart: null,
      data: [],
      info1: [],
      defaultTime: [moment().add(-1, "M"), moment()],
      moment,
      show: true,
      spinning: false,
      init: false,
    });
    const refData = toRefs(data);
    onUpdated(() => {});
    onMounted(() => {
      data.title = props.title;
    });
    const newChart = () => {
      if (data.chart) {
        data.chart.clear(true);
      }
      const data1 = data.info1;
      const chart = new Chart({
        container: data.title,
        autoFit: true,
        height: 230,
      });
      data.chart = chart;
      chart.data(data1);
      chart.scale({
        Time: {
          alias: "日期",
          type: "time",
          mask: "MM-DD HH:mm",
        },
        _value: {
          max: props.maxValue ? props.maxValue : null,
        },
      });

      chart.tooltip({
        showCrosshairs: true,
        // 鼠标上是否显示全部的数据
        shared: true,
      });

      chart.axis("_value", {
        label: {
          formatter: (val) => {
            return val;
          },
        },
      });

      chart
        .line()
        .position("Time*_value")
        .shape("smooth")
        .color("_field")
        .tooltip("Time*_field*_value", (Time, devicename, Value) => {
          Value = Value.toFixed(2);
          switch (devicename) {
            case "CPU温度":
              return {
                name: devicename,
                value: Value + " ℃",
              };
            default:
              return {
                name: devicename,
                value: Value + " %",
              };
          }
        });

      chart.interaction("brush-x");
      chart.render();
    };
    registerInteraction("drag-move", {
      start: [{ trigger: "plot:mousedown", action: "scale-translate:start" }],
      processing: [
        {
          trigger: "plot:mousemove",
          action: "scale-translate:translate",
          throttle: { wait: 100, leading: true, trailing: false },
        },
      ],
      end: [{ trigger: "plot:mouseup", action: "scale-translate:end" }],
    });
    const getData1 = () => {
      data.spinning = true;
      data.show = true;
      proxy.$req
        .get("/v6/deice/hardware", {
          startTime: data.startTime,
          endTime: data.endTime,
          id: props.id,
        })
        .then((response) => {
          if (response.code != 0) {
            message.error(response.message);
            return;
          }
          const info = JSON.parse(response.data);
          data.info1 = info;
          if (!data.info1.length) {
            data.show = false;
          }
          if (!data.chart) {
            data.init = true;
            newChart();
          } else {
            data.chart.changeData(data.info1);
          }
        })
        .finally(() => {
          data.spinning = false;
        });
    };
    const searchTime = (startTime, endTime) => {
      data.startTime = startTime;
      data.endTime = endTime;
      getData1();
    };
    const ruleForm = ref(null);
    onUnmounted(() => {
      if (data.chart) {
        data.chart.clear(true);
      }
    });
    return {
      searchTime,
      ruleForm,
      getData1,
      ...refData,
    };
  },
  props: {
    id: Number,
    title: String,
    maxValue: Number,
  },
};
</script>

<style>
.timeSearch {
  height: 60px;
}

.infochildtitle {
  position: relative;
  float: left;
  width: 150px;
  height: 150px;
  margin: 3px;
  border: 1px solid rgb(173, 172, 172);
  text-align: center;
}
</style>
