<template>
  <div v-if="title" class="asdwadwad2">
    <div class="asdwadwad21">
      <div class="asdwadwad211">CPU使用率</div>
      <div v-if="externalValue.data" class="asdwadwad212123">
        {{ externalValue.data }}
        <span style="font-weight: initial; font-size: 12px">%</span>
      </div>
      <div
        v-else
        class="asdwadwad212123"
        style="font-weight: initial; font-size: 13px; color: red"
      >
        暂无数据
      </div>
    </div>
    <div class="asdwadwad21">
      <div class="asdwadwad211">CPU温度</div>
      <div v-if="externalValue.remain" class="asdwadwad212123">
        {{ externalValue.remain }}
        <span style="font-weight: initial; font-size: 12px">℃</span>
      </div>
      <div
        v-else
        class="asdwadwad212123"
        style="font-weight: initial; font-size: 13px; color: red"
      >
        暂无数据
      </div>
    </div>
    <div class="asdwadwad21">
      <div class="asdwadwad211">FLASH使用寿命</div>
      <div v-if="externalValue.storage" class="asdwadwad212123">
        {{ externalValue.storage }}
        <span style="font-weight: initial; font-size: 12px">%</span>
      </div>
      <div
        v-else
        class="asdwadwad212123"
        style="font-weight: initial; font-size: 13px; color: red"
      >
        暂无数据
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, ref, onUpdated } from "vue";

// import { Chart } from "@antv/g2";
export default {
  setup() {
    let data = reactive({
      title: "dasdaw",
      chart: null,
      data: [],
    });
    const refData = toRefs(data);
    onUpdated(() => {});
    onMounted(() => {});
    const ruleForm = ref(null);
    return {
      ruleForm,
      ...refData,
    };
  },
  props: {
    externalValue: Object,
  },
};
</script>

<style>
.asdwadwad2 {
  width: 128px;
  height: 200px;
  float: left;
}
.asdwadwad21 {
  height: 60px;
  width: 128px;
  margin-top: 5px;
}
.asdwadwad211 {
  height: 30px;
  width: 128px;
  padding-left: 20px;
  line-height: 40px;
  font-size: 14px;
}
.asdwadwad212123 {
  height: 30px;
  width: 128px;
  padding-left: 20px;
  line-height: 25px;
  font-size: 14px;
  color: black;
  font-weight: 700;
}
</style>
