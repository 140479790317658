<template>
  <Modal
    :maskClosable="false"
    :visible="true"
    title="添加APK文件"
    @cancel="close"
    :footer="null"
    width="520px"
  >
    <Spin tip="文件上传中..." :spinning="spinning">
      <Form
        name="adddevicetypes"
        ref="ruleForm"
        @finish="postdata"
        :model="form"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 20 }"
      >
        <FormItem
          name="name"
          :rules="{ required: true, validator: validator }"
          label="APK名称"
        >
          <Input
            placeholder="请输入要APK的名称"
            v-model:value="form.name"
            :maxlength="30"
            allowClear
          />
        </FormItem>
        <FormItem
          name="versionname"
          :rules="{ validator: validator }"
          label="APK版本"
        >
          <Input
            placeholder="请输入要APK的备注"
            v-model:value="form.versionname"
            :maxlength="30"
            :disabled="true"
          />
        </FormItem>
        <FormItem name="file" :rules="{ validator: validator }" label="APK文件">
          <Upload
            v-model:file-list="fileList"
            name="file"
            @change="filechange"
            :before-upload="beforeUpload"
            accept=".apk"
          >
            <Button :disabled="fileDisable">上传APK文件</Button>
          </Upload>
        </FormItem>
        <FormItem
          name="details"
          :rules="{ validator: validator }"
          label="更新说明"
        >
          <InputTextArea
            placeholder="请输入要安装的APK的更新说明"
            v-model:value="form.details"
            :maxlength="30"
            allowClear
            :rows="4"
          />
        </FormItem>
        <FormItem :wrapperCol="{ span: 20, offset: 8 }">
          <Space>
            <Button type="primary" html-type="submit">确定</Button>
            <Button @click="close(false)">取消</Button>
          </Space>
        </FormItem>
      </Form>
    </Spin>
  </Modal>
</template>

<script>
import {
  Modal,
  Form,
  Input,
  Upload,
  Button,
  Space,
  notification,
  Spin,
} from "ant-design-vue";
import { reactive, ref, toRefs, getCurrentInstance } from "vue";
import Que from "./que";
const OSS = require("ali-oss");
const AppInfoParser = require("app-info-parser");
export default {
  components: {
    Modal,
    Form,
    FormItem: Form.Item,
    Input,
    Upload,
    Button,
    Space,
    Spin,
    InputTextArea: Input.TextArea,
  },
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    let data = reactive({
      fileList: [],
      fileDisable: false,
      spinning: false,
    });
    const close = (result) => {
      emit("my-emit", result);
    };
    let form = reactive({
      name: "",
      details: "",
      file: "",
      edition: "",
      packagename: "",
      appname: "",
      versionname: "",
    });
    const postdata = async () => {
      const response = await Que.post(proxy, form);
      if (response) {
        close(true);
      }
    };
    const validator = async (rule, value) => {
      switch (rule.field) {
        case "name":
          if (!value) {
            return Promise.reject("名称不能为空");
          }
          break;
        case "file":
          if (!value) {
            return Promise.reject("文件不能为空");
          }
          break;
        default:
          break;
      }
      return Promise.resolve();
    };
    const ruleForm = ref();
    const beforeUpload = () => {
      return false;
    };
    const filechange = async (file) => {
      data.fileDisable = true;
      data.spinning = true;
      if (file.fileList.length == 0) {
        setFileURL("");
        form.edition = "";
        return;
      }
      form.file = "";
      var filename = file.file.name;
      // 检测名称
      var filenameList = filename.split(".");
      if (filenameList[filenameList.length - 1] != "apk") {
        notification["error"]({
          message: "文件错误",
          description: "文件格式错误.",
        });
        data.fileList = [];
        setFileURL("");
        return;
      }
      var version = await appParseInfo(file.file);
      if (!version) {
        notification["error"]({
          message: "上传失败",
          description: "无法解析APK.",
        });
        setFileURL("");
        data.fileList = [];
        return;
      }

      // versionCode: result.versionCode,
      //   versionName: result.versionName,
      //   packageName: result.package,
      //   appName: file.name,

      form.edition = version.versionCode;
      form.versionname = version.versionName;
      form.packagename = version.packageName;
      form.appname = version.appName;
      if (filename.length > 30) {
        filename = filename.substr(filename.length - 20);
      }
      const respURL = await Que.urlPut(proxy, {
        filename: filename,
      });
      if (!respURL) {
        setFileURL("");
        data.fileList = [];
        return;
      }
      data.fileList = [file.file];
      multipartUpload(file.file, respURL);
    };
    const setFileURL = (url) => {
      data.spinning = false;
      data.fileDisable = false;
      form.file = url;
      ruleForm.value.validate(["file"]);
    };
    async function multipartUpload(file, ossPara) {
      const client = new OSS({
        // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
        region: ossPara.region.split(".")[0],
        // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
        accessKeyId: ossPara.accessKeyId,
        accessKeySecret: ossPara.accessKeySecret,
        // 从STS服务获取的安全令牌（SecurityToken）。
        stsToken: ossPara.securityToken,
        // 填写Bucket名称，例如examplebucket。
        bucket: ossPara.bucket,
      });

      // let tempCheckpoint = null;

      try {
        // 填写Object完整路径。Object完整路径中不能包含Bucket名称。
        // 您可以通过自定义文件名（例如exampleobject.txt）或目录（例如exampledir/exampleobject.txt）的形式，实现将文件上传到当前Bucket或Bucket中的指定目录。
        await client.multipartUpload(ossPara.ossname, file, {
          progress: function (p) {
            data.tip = "上传文件: " + (p * 100).toFixed(2) + "%";
            // 断点记录点。浏览器重启后无法直接继续上传，您需要手动触发上传操作。
            // tempCheckpoint = checkpoint;
            // console.log(tempCheckpoint);
          },
          meta: { year: 2020, people: "test" },
          mime: "application/octet-stream",
        });
        notification["success"]({
          message: "上传成功",
          description: "文件上传成功.",
        });
        setFileURL(ossPara.ossname);
      } catch (e) {
        console.log(e);
        notification["error"]({
          message: "上传失败",
          description: "文件上传失败.",
        });
        setFileURL("");
      }
      data.spinning = false;
    }

    const appParseInfo = async (file) => {
      const parser = new AppInfoParser(file);
      try {
        const result = await parser.parse();
        console.log("app info ----> ", result);
        console.log("file buffer ----> ", parser.file);
        return {
          versionCode: result.versionCode,
          versionName: result.versionName,
          packageName: result.package,
          appName:
            result.application.label.length > 0
              ? result.application.label[0]
              : "未知应用",
        };
      } catch (error) {
        console.log(error);
      }
      return null;
    };
    return {
      close,
      postdata,
      form,
      validator,
      ruleForm,
      ...toRefs(data),
      beforeUpload,
      filechange,
      appParseInfo,
    };
  },
};
</script>

<style></style>
