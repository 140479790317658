<template>
  <div>
    <div style="text-align: right">
      <a-button type="primary" @click="$refs.operateModalVue.showModal()">创建预警</a-button>
    </div>
    <Table :columns="columns" :rowKey="(record) => record.id" :pagination="false" :data-source="dataSource"
      :loading="loading" :scroll="{ x: 800 }">
      <template #index="{ index }">
        {{ index + 1 }}
      </template>
      <template #createtime="{ text }">
        {{
                moment(parseInt(text)).utcOffset(480).format("YYYY-MM-DD HH:mm:ss")
                }}
      </template>
      <template #emails="{ text }">
        <div v-if="text">
          <p style="margin: 0" v-for="item in text.split(',')" :key="item">
            <a>{{ item }}</a>
          </p>
        </div>
        <div v-else>{{ text }}</div>
      </template>
      <template #items="{ text }">
        <a-tag :color="warnItemsColorsDict.get(text)">{{ text }}</a-tag>
      </template>
      <template #types="{ text }">
        <a-tag>{{ warnTypesDict.get(text) }}</a-tag>
      </template>
      <template #operation="{ record }">
        <a-popconfirm title="确定删除吗" ok-text="确定" cancel-text="取消" @confirm="handleDelte(record.id)">
          <a-button type="link" :style="{
                          color: 'red',
                        }">
            <DeleteOutlined />
            删除
          </a-button></a-popconfirm>
      </template>
    </Table>
    <Pagination :total="total" :showTotal="(total) => `一共 ${total} 条数据`" :pageSize="limit" show-quick-jumper
      :showSizeChanger="true" @change="paginationChange" @showSizeChange="showSizeChange" v-model:current="offset"
      :pageSizeOptions="['10', '20', '30', '50', '100']" style="float: right">
      <template #buildOptionText="props">
        <span>{{ props.value }}/页</span>
      </template>
    </Pagination>
    <operateModalVue ref="operateModalVue" @handleQuery="getData" />
  </div>
</template>

<script>
import {
  Table,
  notification,
  Pagination,
  Tag as ATag,
  Button as AButton,
  Popconfirm as APopconfirm,
} from "ant-design-vue";
import { DeleteOutlined } from "@ant-design/icons-vue";
import { reactive, toRefs, onMounted, getCurrentInstance, ref } from "vue";
import moment from "moment";
import operateModalVue from "./operateModal.vue";
import { warnTypesDict, warnItemsColorsDict } from "../../data";
export default {
  components: {
    APopconfirm,
    AButton,
    ATag,
    Table,
    Pagination,
    DeleteOutlined,
    operateModalVue,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const detailsVue = ref(null);
    let data = reactive({
      moment,
      columns: [
        {
          title: "序号",
          key: "index",
          width: 60,
          slots: { customRender: "index" },
          fixed: "left",
        },
        {
          title: "接收预警邮箱",
          dataIndex: "emails",
          slots: {
            customRender: "emails",
          },
          width: 200,
        },
        {
          title: "所属商户组",
          dataIndex: "groupid",
          customRender: function ({ text }) {
            if (text == -1) {
              return "全部设备";
            }
            let showLabel = undefined;
            if (text) {
              showLabel = new Map(
                data.groupList.map((dict) => [dict.id, dict.name])
              ).get(Number(text));
            }
            return showLabel ? showLabel : "-";
          },
        },
        {
          title: "预警项",
          dataIndex: "items",
          slots: {
            customRender: "items",
          },
        },
        {
          title: "预警方式",
          dataIndex: "types",
          slots: {
            customRender: "types",
          },
        },
        {
          title: "比较值",
          dataIndex: "values",
        },

        {
          title: "创建时间",
          dataIndex: "createtime",
          slots: {
            customRender: "createtime",
          },
        },
        {
          title: "操作",
          dataIndex: "operation",
          slots: {
            customRender: "operation",
          },
        },
      ],
      dataSource: [],
      total: 0,
      loading: false,
      limit: 10,
      offset: 1,
      selectTimeout: null,
      group: 0,
      groupList: [],
    });
    const refData = toRefs(data);

    const getData = () => {
      data.loading = true;
      proxy.$req
        .get("/v6/warning", {
          limit: data.limit,
          offset: (data.offset - 1) * data.limit,
        })
        .then((response) => {
          data.loading = false;
          if (response.code) {
            notification["error"]({
              message: "获取预警列表失败",
              description: response.message,
            });
            return;
          }
          data.dataSource = response.data;
          data.total = parseInt(response.total);
        });
    };
    const handleDelte = (id) => {
      proxy.$req.del("/v6/warning/" + id, {}).then((response) => {
        if (response.code) {
          notification["error"]({
            message: "删除预警失败",
            description: response.message,
          });
        } else {
          notification["success"]({
            message: "删除预警成功",
            description: response.message,
          });
        }
        getData();
      });
    };
    onMounted(() => {
      getData();
      getGroupList();
    });
    const paginationChange = () => {
      getData();
    };
    const showSizeChange = (current, size) => {
      data.offset = 1;
      data.limit = size;
      getData();
    };
    const changeSelect = () => {
      if (data.selectTimeout) {
        clearTimeout(data.selectTimeout);
      }
      data.selectTimeout = setTimeout(() => {
        getData();
      }, 500);
    };
    const getGroupList = () => {
      proxy.$req
        .get("/v6/deice/group", {
          limit: data.limit,
          offset: (data.offset - 1) * data.limit,
          sn: data.sn,
          group: data.group,
        })
        .then((response) => {
          if (response.code) {
            notification["error"]({
              message: "获取商户组信息失败",
              description: response.message,
            });
            return;
          }
          data.groupList = response.data;
        });
    };

    return {
      warnItemsColorsDict,
      handleDelte,
      getData,
      warnTypesDict,
      detailsVue,
      ...refData,
      paginationChange,
      showSizeChange,
      changeSelect,
    };
  },
};
</script>

<style>
.divserach {
  padding: 10px;
  float: left;
  position: relative;
}
</style>
