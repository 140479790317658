<template>
  <a-card :title="title" :bordered="true" style="min-height: 350px">
    <a-date-picker
      :allowClear="false"
      @change="searchTime"
      v-model:value="defaultTime"
      :type="'second'" />
    <br />
    <a-spin :spinning="spinning">
      <div v-if="info.length == 0" style="height: 235px">
        <Empty description="暂无数据" />
      </div>
      <div :id="title"></div> </a-spin
  ></a-card>
</template>

<script>
import {
  reactive,
  toRefs,
  getCurrentInstance,
  watch,
  onMounted,
  onUnmounted,
} from "vue";
import { Chart } from "@antv/g2";
import {
  Card as ACard,
  Empty,
  Spin as ASpin,
  DatePicker as ADatePicker,
  notification,
} from "ant-design-vue";
import moment from "moment";
export default {
  components: { ACard, Empty, ASpin, ADatePicker },
  setup(props) {
    const { proxy } = getCurrentInstance();
    let data = reactive({
      info: [],
      chart: null,
      title: props.title,
      defaultTime: moment(),
      startTime: moment("00:00:00", "HH:mm:ss").unix(),
      endTime: moment("23:59:59", "HH:mm:ss").unix(),
      spinning: false,
      init: false,
      total: 0,
    });
    const getData = () => {
      data.spinning = true;
      proxy.$req
        .get(props.url, {
          group: props.group,
          types: props.types,
          startTime: data.startTime,
          endTime: data.endTime,
        })
        .then((response) => {
          if (response.code != 0) {
            notification["error"]({
              message: "获取数据失败",
              description: response.message,
            });
            return;
          }

          if (data.chart) {
            data.chart.destroy();
            data.chart = null;
          }
          data.info = JSON.parse(response.data);
          data.total = 0;
          data.info.forEach((d) => {
            data.total += d.value;
          });
          if (!data.info.length) {
            return;
          }
          newChart();
        })
        .finally(() => {
          data.spinning = false;
        });
    };
    const newChart = () => {
      if (data.init) {
        return;
      }
      const chart = new Chart({
        container: props.title,
        autoFit: true,
        height: 235,
      });
      data.chart = chart;
      chart.coordinate("theta", {
        radius: 0.65,
        innerRadius: 0.6,
      });

      chart.data(data.info);

      chart.scale("value", {
        formatter: (val) => {
          return val + "台";
        },
      });

      chart.tooltip({
        showTitle: true,
        showMarkers: false,
      });

      chart
        .interval()
        .position("value")
        .color("label", ["#063d8a", "#1770d6", "#47abfc", "#38c060"])
        .label("label*value", {
          layout: [
            { type: "pie-spider" },
            {
              type: "limit-in-plot",
              cfg: { action: "ellipsis" /** 或 translate */ },
            },
          ],
          labelHeight: 20,
          content: (obj) => `${obj.label} (${obj.value}台)`,
          labelLine: {
            style: {
              lineWidth: 0.5,
            },
          },
        })
        .adjust("stack");

      // 辅助文本
      chart
        .annotation()
        .text({
          position: ["50%", "50%"],
          content: "全部",
          style: {
            fontSize: 14,
            fill: "black",
            textAlign: "center",
          },
          offsetY: -20,
        })
        .text({
          position: ["50%", "50%"],
          content: data.total,
          style: {
            fontSize: 20,
            fill: "red",
            textAlign: "center",
          },
          offsetY: 5,
        });

      chart.interaction("element-active");

      chart.render();
    };
    const searchTime = (_, time) => {
      data.startTime = moment(time + " 00:00:00").unix();
      data.endTime = moment(time + " 23:59:59").unix();
      getData();
    };
    watch(
      () => props,
      () => {
        getData();
      },
      { deep: true }
    );
    onMounted(() => {
      getData();
    });
    onUnmounted(() => {
      if (data.chart) {
        data.chart.destroy();
      }
      data.init = true;
    });
    return { searchTime, ...toRefs(data) };
  },
  props: {
    url: String,
    title: String,
    group: Number,
    types: Number,
    refresh: Boolean,
  },
};
</script>
