<template>
  <div class="fhsrdgbvhsfsfdsfds">
    <div v-if="!show">
      <Empty style="height: 150px" description="暂无内存数据" />
    </div>
    <div
      v-show="show"
      id="title123213"
      style="width: 250px; height: 150px"
    ></div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, ref, onUnmounted, watch } from "vue";
import { Chart } from "@antv/g2";
import { Empty } from "ant-design-vue";
export default {
  components: { Empty },
  setup(props) {
    let data = reactive({
      title: null,
      chart: null,
      data: [],
      show: true,
    });
    const refData = toRefs(data);
    onUnmounted(() => {
      // 销毁页面
      if (data.chart) {
        data.chart.destroy();
        data.chart = null;
      }
    });
    onMounted(() => {
      data.title = props.externalValue.title;
      newChart();
    });
    watch(
      () => props,
      () => {
        newChart();
      },
      { deep: true }
    );

    const getColor = (val) => {
      const color = ["green", "#FFBF00", "#F5222D"];
      var stylecolor = 0;
      if (val < props.externalValue.warn) {
        stylecolor = 0;
      } else if (val < props.externalValue.police) {
        stylecolor = 1;
      } else {
        stylecolor = 2;
      }
      return color[stylecolor];
    };
    const newChart = () => {
      data.show = true;
      if (!props.externalValue.value) {
        data.show = false;
      }
      if (data.chart) {
        data.chart.destroy();
        data.chart = null;
      }
      const dataValue = props.externalValue.value;
      const mag = Math.ceil(props.externalValue.max / 50.0);
      const color = getColor(props.externalValue.value);
      const data1 = [];
      for (
        let i = props.externalValue.min;
        i <= props.externalValue.max;
        i = i + mag
      ) {
        data1.push({
          type: i + "",
          value: 10,
        });
      }

      const data2 = [];
      for (const v of data1) {
        const item = {};
        item.type = v.type;
        item.value = 10;
        if (
          parseInt(v.type) <= dataValue &&
          parseInt(v.type) + mag > dataValue
        ) {
          item.value = 14;
        } else if (parseInt(v.type) > dataValue) {
          item.value = 0;
        }
        data2.push(item);
      }
      const chart = new Chart({
        container: "title123213",
        autoFit: true,
        height: 150,
      });
      data.chart = chart;
      chart.coordinate("polar", {
        startAngle: (-9 / 8) * Math.PI,
        endAngle: (1 / 8) * Math.PI,
        radius: 1,
      });
      chart.scale({
        type: {
          range: [0, 1],
        },
        value: {
          sync: true,
        },
        min: 0,
        max: 160,
      });
      chart.legend(false);
      chart.tooltip(false);
      // 绘制仪表盘背景
      const view1 = chart.createView();
      view1.data(data1);
      view1.axis(false);
      view1.coordinate("polar", {
        startAngle: (-9 / 8) * Math.PI,
        endAngle: (1 / 8) * Math.PI,
        innerRadius: 0.75,
        radius: 0.8,
      });
      //   绘制仪表盘底色
      view1.interval().position("type*value").color("#CBCBCB").size(4);

      // 绘制刻度数据
      const view2 = chart.createView();
      view2.data(data1);
      view2.axis("value", false);
      view2.axis("type", {
        grid: null,
        line: null,
        tickLine: null,
        label: {
          offset: -10,
          style: (val) => {
            const color = getColor(val);
            return {
              textAlign: "center",
              fill: color,
              fontSize: 10,
            };
          },
          formatter: (val) => {
            if (val == props.externalValue.min) {
              return props.externalValue.min;
            } else if (val == props.externalValue.max) {
              return props.externalValue.max;
            } else if (
              val >= props.externalValue.warn &&
              val < props.externalValue.warn + mag
            ) {
              return props.externalValue.warn;
            } else if (
              val >= props.externalValue.police &&
              val < props.externalValue.police + mag
            ) {
              return props.externalValue.police;
            }
            return "";
          },
        },
      });
      view2.coordinate("polar", {
        startAngle: (-9 / 8) * Math.PI,
        endAngle: (1 / 8) * Math.PI,
        innerRadius: 1,
        radius: 0.65,
      });
      view2.interval().position("type*value").color("#000000").size(4);

      // 绘制刻度
      const view3 = chart.createView();
      view3.data(data2);
      view3.axis(false);
      view3.coordinate("polar", {
        startAngle: (-9 / 8) * Math.PI,
        endAngle: (1 / 8) * Math.PI,
        innerRadius: 0.75,
        radius: 0.8,
      });
      view3.interval().position("type*value").color(color).size(4);

      view3.annotation().text({
        position: ["50%", "90%"],
        content:
          props.externalValue.remain +
          " MB/" +
          (props.externalValue.total / 1000).toFixed(2) +
          " GB",
        style: {
          fill: "black",
          fontSize: 13,
          textAlign: "center",
          textBaseline: "middle",
        },
      });
      view3.annotation().text({
        position: ["50%", "70%"],
        content: parseInt(props.externalValue.title),
        style: {
          fill: color,
          fontSize: 27,
          textAlign: "center",
          textBaseline: "middle",
        },
      });
      view3.annotation().text({
        position: ["58%", "72%"],
        content: "%",
        style: {
          fill: color,
          fontSize: 13,
          textAlign: "center",
          textBaseline: "middle",
        },
      });

      view3.annotation().text({
        position: ["50%", "52%"],
        content: "内存使用率",
        style: {
          fill: "black",
          fontSize: 13,
          textAlign: "center",
          textBaseline: "middle",
        },
      });
      chart.render();
    };
    const ruleForm = ref(null);
    return {
      ruleForm,
      ...refData,
    };
  },
  props: {
    externalValue: Object,
  },
};
</script>

<style>
.pictureVueitem {
  margin-left: 150px;
}
.pictureVueDiv {
  float: left;
}
.fhsrdgbvhsfsfdsfds {
  /* background: rgb(214, 213, 213); */
  float: left;
}
</style>
