<template>
  <Drawer :title="'设备详情-' + info.sn" width="1000" :visible="true" :body-style="{ paddingBottom: '80px' }"
    @close="onClose">
    <Tabs v-model:activeKey="activeKey">
      <TabPane key="1" tab="基础信息">
        <baseinfoVue :info="info" v-if="activeKey == '1'" />
      </TabPane>
      <TabPane key="2" tab="详情信息" force-render>
        <exhaustiveVue :info="info" v-if="activeKey == '2'" />
      </TabPane>
      <TabPane key="5" tab="外挂信息">
        <Waigua :detailsinfo="info" v-if="activeKey == '5'" />
      </TabPane>
      <TabPane key="3" tab="网络信息">
        <internetVue :info="info" v-if="activeKey == '3'" />
      </TabPane>
      <TabPane key="4" tab="使用情况">
        <useinfoVue :info="info" v-if="activeKey == '4'" />
      </TabPane>
      <TabPane key="6" tab="下行指令">
        <Instruction :info="info" v-if="activeKey == '6'" />
      </TabPane>
    </Tabs>
  </Drawer>
</template>

<script>
import { reactive, toRefs } from "vue";
import { Drawer, Tabs } from "ant-design-vue";
import baseinfoVue from "./detailsinfo/baseinfo.vue";
import exhaustiveVue from "./detailsinfo/exhaustive.vue";
import internetVue from "./detailsinfo/internet.vue";
import useinfoVue from "./detailsinfo/useinfo.vue";
import Waigua from "./detailsinfo/waigua.vue";
import Instruction from "./detailsinfo/instruction.vue";


export default {
  components: {
    Drawer,
    Tabs,
    TabPane: Tabs.TabPane,
    baseinfoVue,
    exhaustiveVue,
    internetVue,
    useinfoVue,
    Waigua,
    Instruction,
  },
  setup(props, { emit }) {
    const data = reactive({
      activeKey: "1",
    });
    const onClose = () => {
      emit("my-emit");
    };
    return { ...toRefs(data), onClose };
  },
  props: {
    info: Object,
  },
};
</script>

<style></style>