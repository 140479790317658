<template>
  <div v-show="dataSource.length" class="fix_map">
    <p style="font-weight: 600">设备异常分布</p>
    <a-list
      size="small"
      class="demo-loadmore-list"
      item-layout="horizontal"
      :data-source="dataSource"
      :bordered="true"
    >
      <template #loadMore>
        <div
          :style="{
            textAlign: 'center',
            marginTop: '12px',
            height: '32px',
            lineHeight: '32px',
          }"
        >
          <a v-if="!loadingMore" @click="loadMore">已经到底啦~</a>
          <a v-else @click="loadMore">加载更多</a>
        </div>
      </template>
      <template #renderItem="{ item }">
        <a-list-item>
          <a-list-item-meta>
            <template #title>
              <a @click="changeMap(item)">{{ item.devicename }}</a>
            </template>
            <template #description>
              <div class="deivceInfoTablesninfo">
                <span style="color: black">
                  {{ item.location_province }} {{ item.location_city }}
                  {{ item.location_district }}
                </span>
                <br />
                <span style="color: green">
                  {{
                    moment(parseInt(item.Time))
                      .utcOffset(480)
                      .format("YYYY-MM-DD HH:mm:ss")
                  }}
                </span>
              </div>
            </template>
          </a-list-item-meta>
        </a-list-item>
      </template>
    </a-list>
  </div>
</template>
<script>
import { List as AList, notification } from "ant-design-vue";
import { reactive, toRefs } from "vue";
import moment from "moment";
export default {
  components: {
    AList,
    AListItem: AList.Item,
    AListItemMeta: AList.Item.Meta,
  },
  setup(props, { emit }) {
    // const { proxy } = getCurrentInstance();
    let data = reactive({
      moment,
      dataSource: props.dataSource,
      spinning: true,
      visible: true,
    });
    const refData = toRefs(data);
    const changeMap = (item) => {
      if (!item.location_latitude || !item.location_longitude) {
        notification["error"]({
          message: "没有定位信息",
          description: "请更新相关APP到最新版本",
        });
        return;
      }
      emit("changeMap", item);
    };
    const loadMore = () => {
      emit("loadMore");
    };
    return {
      loadMore,
      changeMap,
      ...refData,
    };
  },
  props: {
    url: String,
    dataSource: Array,
    loadingMore: Boolean,
  },
};
</script>

<style scoped>
:deep(.ant-card-body) {
  padding: 5px;
}
.fix_map {
  background: rgb(251, 250, 250, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 100%;
  z-index: 10;
  overflow: auto;
}
</style>
