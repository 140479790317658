<template>
	<div class="home">
		<div class="logindiv">
			<div class="logintitle">修改密码</div>
			<Form :rules="rules" ref="formRef" :model="form" @finish="finish">
				<formItem required has-feedback name="name">
					<Input
						size="large"
						:maxlength="30"
						allowClear
						placeholder="请输入登录账号"
						v-model:value="form.name"
						style="width: 360px"
					/>
				</formItem>
				<formItem required has-feedback name="password">
					<Password
						class="logininput1"
						size="large"
						:maxlength="30"
						allowClear
						placeholder="请输入登录密码"
						v-model:value="form.password"
						style="width: 360px"
					/>
				</formItem>
				<Row :gutter="16">
					<Col :span="12">
						<formItem required has-feedback name="code">
							<Input
								size="large"
								:maxlength="6"
								allowClear
								placeholder="请输入验证码"
								v-model:value="form.code"
							/>
						</formItem>
					</Col>
					<Col :span="8">
						<Button
							size="large"
							style="margin-left: 60px"
							:disabled="codeShow || !nameCheck"
							@click="sendCode"
						>
							<span v-if="codeShow" style="width: 80px">{{ codeTime }}</span>
							<span v-else style="width: 80px">获取验证码</span>
						</Button>
					</Col>
				</Row>
				<formItem>
					<Button
						class="logininput1"
						size="large"
						type="primary"
						html-type="submit"
					>
						修改
					</Button>
					<a style="float: right" href="#/">登录</a>
				</formItem>
			</Form>
		</div>
	</div>
</template>

<script>
	import { Input, Form, Button, notification, Row, Col } from "ant-design-vue";
	import { ref, toRefs, getCurrentInstance, reactive } from "vue";
	import router from "@/router";
	import md5 from "js-md5";
	export default {
		components: {
			Input,
			Form,
			Password: Input.Password,
			Button,
			formItem: Form.Item,
			Row,
			Col
		},
		setup() {
			let data = reactive({
				codeShow: false,
				codeTime: 60,
				codeInterval: null,
				nameCheck: false
			});
			const refData = toRefs(data);
			const { proxy } = getCurrentInstance();
			const form = ref({
				name: "",
				password: "",
				code: ""
			});
			const formRef = ref();
			const validator = (rule, value) => {
				switch (rule.field) {
					case "name":
						data.nameCheck = false;
						if (!value) {
							return Promise.reject("请输入正确的登录账号");
						}
						if (value.length < 4) {
							return Promise.reject("请输入正确的登录账号");
						}
						if (!proxy.$req.checkEmail(value)) {
							return Promise.reject("请输入正确的登录账号");
						}
						data.nameCheck = true;
						break;
					case "password":
						if (!value) {
							return Promise.reject("请输入正确的登录密码");
						}
						if (value.length < 4) {
							return Promise.reject("请输入正确的登录密码");
						}
						if (!proxy.$req.checPassword(value)) {
							return Promise.reject("请输入正确的登录密码");
						}
						break;
					case "code":
						if (value.length != 6) {
							return Promise.reject("请输入正确的验证码");
						}
						break;
				}
				return Promise.reject();
			};
			const rules = {
				name: [{ validator: validator, trigger: "change" }],
				password: [{ validator: validator, trigger: "change" }],
				code: [{ validator: validator, trigger: "change" }]
			};
			const finish = (Event) => {
				proxy.$req
					.put("/v6/reset", {
						name: Event.name,
						password: md5(Event.password),
						code: Event.code
					})
					.then((response) => {
						if (response.code) {
							notification["error"]({
								message: "修改密码失败",
								description: response.message
							});
							return;
						}
						notification["success"]({
							message: "修改密码成功",
							description: response.message
						});
						router.push("/");
					});
			};
			const sendCode = () => {
				proxy.$req
					.put("/v6/reset/code", {
						name: form.value.name
					})
					.then((response) => {
						if (response.code) {
							notification["error"]({
								message: "获取验证码失败",
								description: response.message
							});
						} else {
							notification["success"]({
								message: "获取验证码成功"
							});
						}
					});

				data.codeShow = true;
				data.codeTime = 60;
				data.codeInterval = setInterval(() => {
					data.codeTime--;
					if (data.codeTime <= 0) {
						clearInterval(data.codeInterval);
						data.codeShow = false;
					}
				}, 1000);
			};
			return { ...refData, form, rules, formRef, finish, sendCode };
		}
	};
</script>

<style>
	.loginform {
		width: 360px;
		text-align: center;
	}

	.logininput1 {
		width: 360px;
	}
	.home {
		height: 100%;
		width: 100%;
		text-align: center;
	}
	.logindiv {
		height: 300px;
		width: 360px;
		margin: 0 auto;
		position: relative;
		top: 20%;
	}
	.logintitle {
		width: 100%;
		height: 100px;
		line-height: 100px;
		font-size: 25px;
	}
	.logininput {
		width: 360px;
	}
</style>
