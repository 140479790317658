<template>
  <div>
    <Card title="信号强度" :bordered="false">
      <hardwareChartVue
        :startTime="moment().startOf('day')"
        :id="info.id"
        title="hardwareChart"
        url="/v6/deice/hardware"
        :maxValue="120"
        :name="'信号值'"
      />
    </Card>
    <Card title="设备日在线时长" :bordered="false">
      <lineChartVue
        :startTime="moment().add(-1, 'M').startOf('day')"
        :id="info.id"
        title="onlineChartVue"
        url="/v6/deice/online"
        :minValue="0"
        :maxValue="1500"
        :name="'在线时长(分)'"
      />
    </Card>
  </div>
</template>

<script>
import { Card } from "ant-design-vue";
import { reactive, toRefs } from "vue";
import lineChartVue from "@/global/pubilc/lineChart.vue";
import moment from "moment";
import hardwareChartVue from "@/global/pubilc/hardwareChart.vue";

export default {
  components: { Card, lineChartVue, hardwareChartVue },
  setup() {
    const data = reactive({ moment });

    return { ...toRefs(data) };
  },
  props: {
    info: Object,
  },
};
</script>

<style>
</style>