<template>
  <div>
    <div>
      <Breadcrumb style="margin: 16px 0; float: left">
        <BreadcrumbItem>
          <!-- <span style="color: green">主页</span> -->
        </BreadcrumbItem>
        <BreadcrumbItem v-for="(v, k) in breadcrumbList" :key="k">
          <span @click="breadClick(v)" style="color: green">
            {{ v.value }}
          </span>
        </BreadcrumbItem>
      </Breadcrumb>
    </div>
    <div class="avatarclass" v-if="userInfo.name">
      <Dropdown placement="bottomRight">
        <div class="avatarspan">
          <Avatar :size="'large'">
            <!-- <template #icon><UserOutlined /></template> -->
            <template #icon>
              <img src="@/assets/touxiang.png" />
            </template>
          </Avatar>
          <span class="avatarspan1">
            {{ userInfo.name }}
          </span>
        </div>
        <template #overlay>
          <Menu @click="click">
            <MenuItem :key="0">
              <UserOutlined />
              <span> <a href="javascript:;">账号信息</a></span>
            </MenuItem>
            <MenuItem :key="1">
              <SyncOutlined />
              <span> <a href="javascript:;">修改密码</a></span>
            </MenuItem>
            <MenuItem :key="2">
              <LogoutOutlined />
              <span> <a href="javascript:;">退出登录</a></span>
            </MenuItem>
          </Menu>
        </template>
      </Dropdown>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive, toRefs, getCurrentInstance } from "vue";
import { Menu, Avatar, Dropdown, Breadcrumb } from "ant-design-vue";
import {
  UserOutlined,
  LogoutOutlined,
  SyncOutlined,
} from "@ant-design/icons-vue";
import publics from "./public.js";
import router from "@/router";

export default {
  name: "head1",
  components: {
    Menu,
    MenuItem: Menu.Item,
    Avatar,
    UserOutlined,
    Dropdown,
    LogoutOutlined,
    SyncOutlined,
    Breadcrumb,
    BreadcrumbItem: Breadcrumb.Item,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    let data = reactive({
      userInfo: {},
    });
    onMounted(async () => {
      data.userInfo = await publics.getUserInfo();
    });
    const click = ({ key }) => {
      switch (key) {
        case 0:
          router.push("/userinfo/info");
          break;
        case 1:
          router.push("/userinfo/update");
          break;
        case 2:
          proxy.$req.setCookie();
          publics.setCookie();
          router.push("/");
          break;
      }
    };
    const refData = toRefs(data);
    const breadClick = () => {
      // var path = "";
      // for (const iter of props.breadcrumbList) {
      // 	path = path + "/" + iter.key;
      // 	if (iter == v) {
      // 		break;
      // 	}
      // }
      // console.log(path, "=========");
      // router.push(path);
    };
    return { ...refData, click, breadClick };
  },
  props: {
    breadcrumbList: Array,
  },
};
</script>

<style>
.avatarclass {
  position: relative;
  float: right;
  cursor: pointer;
}
.avatarspan {
  height: 100%;
}
.avatarspan1 {
  margin-left: 5px;
}
</style>
