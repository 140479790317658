<template>
  <div>
    <Menu mode="inline" v-model:selectedKeys="selectedKeys" @click="click" v-model:openKeys="openKeys">
      <MenuItem key="group">
      <TableOutlined />
      <span>商户组</span>
      </MenuItem>
      <MenuSubMenu key="deviceInfo">
        <template #title>
          <BarcodeOutlined />
          <span>智能设备</span>
        </template>
        <MenuItem key="deviceMonitor">
        <ClusterOutlined />
        <span>设备监控</span>
        </MenuItem>
        <MenuItem key="deviceWarn">
        <FundOutlined />
        <span>设备预警</span>
        </MenuItem>
      </MenuSubMenu>
      <MenuItem key="instruction">
      <TableOutlined />
      <span>指令下发</span>
      </MenuItem>
      <MenuSubMenu key="apk">
        <template #title>
          <UploadOutlined />
          <span>APK升级</span>
        </template>
        <MenuItem key="apkinfo">
        <SendOutlined />
        <span>APK列表</span>
        </MenuItem>
        <MenuItem key="apkinstall">
        <ThunderboltOutlined />
        <span>升级任务</span>
        </MenuItem>
      </MenuSubMenu>
      <MenuItem key="wechat">
      <WechatOutlined />
      <span>微信绑定</span>
      </MenuItem>
      <MenuSubMenu key="userinfo">
        <template #title>
          <span>
            <UserOutlined />
            <span>账号信息</span>
          </span>
        </template>
        <MenuItem key="info">
        <span>账号详情</span>
        </MenuItem>
        <MenuItem key="update">
        <span>修改密码</span>
        </MenuItem>
      </MenuSubMenu>
    </Menu>
  </div>
</template>

<script>
import { Menu } from "ant-design-vue";
import router from "@/router";
import {
  ClusterOutlined,
  UserOutlined,
  TableOutlined,
  UploadOutlined,
  SendOutlined,
  BarcodeOutlined,
  ThunderboltOutlined,
  WechatOutlined,
  FundOutlined,
} from "@ant-design/icons-vue";
import { onMounted, reactive, toRefs } from "vue";
import publics from "./public.js";
export default {
  name: "menu1",
  components: {
    Menu,
    MenuItem: Menu.Item,
    MenuSubMenu: Menu.SubMenu,
    UserOutlined,
    BarcodeOutlined,
    TableOutlined,
    UploadOutlined,
    SendOutlined,
    ClusterOutlined,
    ThunderboltOutlined,
    WechatOutlined,
    FundOutlined,
  },
  setup() {
    // const { proxy } = getCurrentInstance();

    let data = reactive({
      selectedKeys: [0],
      openKeys: [],
    });
    const click = ({ keyPath }) => {
      var path = keyPath.reverse().join("/");
      router.push("/" + path);
    };
    onMounted(() => {
      data.selectedKeys = publics.getRount();
      if (data.selectedKeys.length > 1) {
        data.openKeys = data.selectedKeys;
      }
    });
    const refData = toRefs(data);
    return {
      click,
      ...refData,
    };
  },
};
</script>

<style></style>
