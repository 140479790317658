<template>
  <Modal
    :maskClosable="false"
    :visible="true"
    title="添加升级任务"
    @cancel="close"
    :footer="null"
    width="520px"
  >
    <Spin tip="任务上传中..." :spinning="spinning">
      <Form
        name="adddevicetypes"
        ref="ruleForm"
        @finish="postdata"
        :model="form"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 20 }"
      >
        <FormItem
          name="name"
          :rules="{ required: true, validator: validator }"
          label="升级名称"
        >
          <Input
            placeholder="请输入要升级的名称"
            v-model:value="form.name"
            :maxlength="30"
            allowClear
          />
        </FormItem>
        <!-- <FormItem
          name="details"
          :rules="{ validator: validator }"
          label="升级备注"
        >
          <Input
            placeholder="请输入要升级的备注"
            v-model:value="form.details"
            :maxlength="30"
            allowClear
          />
        </FormItem> -->
        <FormItem
          name="apkid"
          :rules="{ validator: validator }"
          label="升级APK"
        >
          <Select v-model:value="form.apkid">
            <SelectOption :key="0" style="color: red">未选择</SelectOption>
            <SelectOption v-for="v in apkList" :key="v.ID">
              {{ v.name }} : {{ v.versionname }}
            </SelectOption>
          </Select>
        </FormItem>
        <FormItem :wrapperCol="{ span: 20, offset: 8 }">
          <Space>
            <Button type="primary" html-type="submit">确定</Button>
            <Button @click="close(false)">取消</Button>
          </Space>
        </FormItem>
      </Form>
    </Spin>
  </Modal>
</template>

<script>
import {
  Modal,
  Form,
  Input,
  Button,
  Space,
  Spin,
  Select,
} from "ant-design-vue";
import { reactive, ref, toRefs, getCurrentInstance, onMounted } from "vue";
import Que from "./que";
import QueApk from "../apk/que";
export default {
  components: {
    Modal,
    Form,
    FormItem: Form.Item,
    Input,
    Button,
    Space,
    Spin,
    Select,
    SelectOption: Select.Option,
  },
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    let data = reactive({
      apkList: [],
      spinning: false,
    });
    const close = (result) => {
      emit("my-emit", result);
    };
    let form = reactive({
      name: "",
      details: "",
      apkid: 0,
    });
    const postdata = async () => {
      const response = await Que.post(proxy, form);
      if (response) {
        close(true);
      }
    };
    const validator = async (rule, value) => {
      switch (rule.field) {
        case "name":
          if (!value) {
            return Promise.reject("名称不能为空");
          }
          break;
        case "apkid":
          if (!value) {
            return Promise.reject("请选择升级APK");
          }
          break;
        default:
          break;
      }
      return Promise.resolve();
    };
    const ruleForm = ref();
    const getApk = () => {
      QueApk.get(proxy, { limit: 999 }).then((response) => {
        if (response) {
          data.apkList = response.data;
        }
      });
    };
    onMounted(() => {
      getApk();
    });
    return {
      close,
      postdata,
      form,
      validator,
      ruleForm,
      ...toRefs(data),
      getApk,
    };
  },
};
</script>

<style></style>
