<template>
  <div class="whitelist">
    <Button
      style="width: 100%"
      @click="visible = true"
      :disabled="whitelists.length >= 15"
    >
      <PlusOutlined />
      新增白名单 ( {{ whitelists.length }} )
    </Button>
    <Empty v-if="whitelists.length == 0" description="安装应用白名单为空" />
    <div
      ref="whitelistshow"
      :class="showAllStatus ? 'whitelistshow' : 'whitelistshowAll'"
      v-else
    >
      <div v-for="(v, k) in whitelists" :key="k">
        <Card size="small">
          <Row v-for="(v1, k1) in v" :key="k1">
            <Col :span="8">
              <span style="font-weight: bold">{{ k1 }}</span>
            </Col>
            <Col :span="16">{{ v1 }}</Col>
          </Row>
          <Button
            type="danger"
            ghost
            size="small"
            style="width: 100%"
            @click="del(k)"
          >
            删除
          </Button>
        </Card>
      </div>
    </div>
    <whitelistaddVue v-if="visible" @close="childClose" />
    <div size="small" class="showAlldiv" @click="showAll">
      <span v-if="showAllStatus">
        <DoubleLeftOutlined :rotate="-90" />
        展开全部
      </span>
      <span v-else>
        <DoubleLeftOutlined :rotate="90" />
        收回
      </span>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, onBeforeMount, ref } from "vue";
import { Button, Empty, Card, Row, Col } from "ant-design-vue";
import { PlusOutlined, DoubleLeftOutlined } from "@ant-design/icons-vue";
import whitelistaddVue from "./whitelistadd.vue";

export default {
  components: {
    Button,
    PlusOutlined,
    Empty,
    Card,
    Row,
    Col,
    whitelistaddVue,
    DoubleLeftOutlined,
  },
  setup(props, { emit }) {
    let data = reactive({
      whitelists: [],
      visible: false,
      showAllStatus: true,
    });
    onMounted(() => {});
    onBeforeMount(() => {
      data.whitelists = props.whitelist;
    });
    const change = () => {
      emit("update:whitelist", data.whitelists);
    };
    const del = (index) => {
      data.whitelists.splice(index, 1);
      change();
    };
    const whitelistshow = ref();
    const childClose = (result) => {
      if (result != null) {
        data.whitelists.push(result);
      }
      data.visible = false;
    };
    const showAll = () => {
      data.showAllStatus = !data.showAllStatus;
    };
    return { ...toRefs(data), change, whitelistshow, del, childClose, showAll };
  },
  props: {
    whitelist: Array,
  },
};
</script>

<style>
.whitediv {
  border: 1px solid wheat;
  padding: 5px;
}
.dynamicDeleteButton {
  cursor: pointer;
  position: relative;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
  top: -20px;
  margin-left: 5px;
}
.dynamicDeleteButton:hover {
  color: red;
}
.dynamicDeleteButton[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
.whitelist {
  border: 1px solid rgb(227, 227, 227);
}
.whitelistshow {
  overflow: auto;
  height: 120px;
}
.whitelistshowAll {
  overflow: visible;
}
.showAlldiv {
  width: 100%;
  text-align: center;
  background: wheat;
}
.showAlldiv:hover {
  cursor: pointer;
  background: rgb(233, 231, 231);
}
</style>