<template>
  <indexVue>
    <div class="divserach">
      <span>商户组：</span>
      <Input style="width: 240px" @change="changeSelect" allowClear v-model:value="name" />
    </div>
    <div class="divAdd">
      <Button @click="addClick(true)">
        <PlusOutlined />
        新增
      </Button>
    </div>
    <Table :columns="columns" :rowKey="(record) => record.ID" :pagination="false" :data-source="dataSource"
      :loading="loading" style="float: left; width: 100%">
      <template #index="{ index }">
        {{ index + 1 }}
      </template>
      <template #updatetime="{ text }">
        {{
                moment(parseInt(text)).utcOffset(480).format("YYYY-MM-DD HH:mm:ss")
                }}
      </template>
      <template #createtime="{ text }">
        {{
                moment(parseInt(text)).utcOffset(480).format("YYYY-MM-DD HH:mm:ss")
                }}
      </template>
      <template #operation="{ record }">
        <Space>
          <Button @click="detailsClick(record.ID)" type="link">
            <CopyOutlined />
            详情
          </Button>
          <Popconfirm title="确定删除该商户组吗" ok-text="确定" cancel-text="取消" @confirm="delGroup(record.ID)">
            <Button type="link" :style="{
                              color: 'red',
                            }">
              <DeleteOutlined />
              删除
            </Button>
          </Popconfirm>
        </Space>
      </template>
    </Table>
    <Pagination :total="total" :showTotal="(total) => `一共 ${total} 个商户组`" :pageSize="limit" show-quick-jumper
      :showSizeChanger="true" @change="paginationChange" @showSizeChange="showSizeChange" v-model:current="offset"
      :pageSizeOptions="['10', '20', '30', '50', '100']" style="float: right">
      <template #buildOptionText="props">
        <span>{{ props.value }}/页</span>
      </template>
    </Pagination>
    <infoupdateVue @my-emit="addClick(false)" v-if="addShow" :nameDis="null" />
  </indexVue>
</template>

<script>
import {
  Table,
  notification,
  Pagination,
  Input,
  Button,
  Space,
  Popconfirm,
} from "ant-design-vue";
import { reactive, toRefs, onMounted, getCurrentInstance } from "vue";
import {
  PlusOutlined,
  CopyOutlined,
  DeleteOutlined,
} from "@ant-design/icons-vue";
import router from "@/router";
import moment from "moment";
import infoupdateVue from "./infoupdate.vue";
import indexVue from "@/components/menu/index.vue";

export default {
  components: {
    Table,
    Pagination,
    Input,
    Button,
    PlusOutlined,
    CopyOutlined,
    DeleteOutlined,
    Space,
    Popconfirm,
    infoupdateVue,
    indexVue,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    let data = reactive({
      moment,
      columns: [
        {
          title: "序号",
          key: "index",
          width: 60,
          slots: { customRender: "index" },
          fixed: "left",
        },
        {
          title: "商户组名称",
          dataIndex: "name",
        },
        {
          title: "商户组备注",
          dataIndex: "details",
        },
        {
          title: "商户组创建时间",
          dataIndex: "createtime",
          slots: {
            customRender: "createtime",
          },
        },
        {
          title: "商户组更新时间",
          dataIndex: "updatetime",
          slots: {
            customRender: "updatetime",
          },
        },
        {
          title: "操作",
          dataIndex: "operation",
          slots: {
            customRender: "operation",
          },
        },
      ],
      dataSource: [],
      total: 0,
      loading: false,
      limit: 10,
      offset: 1,
      selectTimeout: null,
      name: "",
      addShow: false,
    });
    const refData = toRefs(data);
    const getData = () => {
      data.loading = true;
      proxy.$req
        .get("/v6/group/list", {
          limit: data.limit,
          offset: (data.offset - 1) * data.limit,
          name: data.name,
        })
        .then((response) => {
          data.loading = false;
          if (response.code) {
            notification["error"]({
              message: "获取设备列表失败",
              description: response.message,
            });
            return;
          }
          data.dataSource = response.data;
          data.total = parseInt(response.total);
        });
    };
    onMounted(() => {
      getData();
    });
    const paginationChange = () => {
      getData();
    };
    const showSizeChange = (current, size) => {
      data.offset = 1;
      data.limit = size;
      getData();
    };
    const changeSelect = () => {
      if (data.selectTimeout) {
        clearTimeout(data.selectTimeout);
      }
      data.selectTimeout = setTimeout(() => {
        getData();
      }, 500);
    };
    const addClick = (result) => {
      data.addShow = result;
      if (!result) {
        getData();
      }
    };
    const detailsClick = (id) => {
      router.push("/group/details?id=" + id);
    };
    const delGroup = (id) => {
      proxy.$req.del("/v6/group/" + id, {}).then((response) => {
        if (response.code) {
          notification["error"]({
            message: "删除商户组失败",
            description: response.message,
          });
        } else {
          notification["success"]({
            message: "删除商户组成功",
            description: response.message,
          });
        }
        getData();
      });
    };
    return {
      ...refData,
      paginationChange,
      showSizeChange,
      changeSelect,
      addClick,
      detailsClick,
      delGroup,
    };
  },
};
</script>

<style>
.divserach {
  padding: 10px;
  float: left;
  position: relative;
}

.divAdd {
  float: right;
  position: relative;
  padding: 10px;
}
</style>
