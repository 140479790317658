<template>
  <indexVue>
    <div>
      <Input style="width: 350px" v-model:value="username"></Input>
      <Button type="primary" @click="click">检索</Button>
    </div>
    <div style="background-color: #ececec; overflow: auto">
      <Card
        v-for="v in dataSoure"
        :key="v.id"
        style="width: 300px; margin: 5px; float: left"
        :title="v.nickname"
        :bordered="false"
        size="small"
      >
        <p>
          绑定账号：<Tag color="#2db7f5">{{ v.alipay }}</Tag>
        </p>
        <p>
          状态：
          <Tag v-if="v.status == 2" color="#f50">待审核</Tag>
          <Tag v-else color="#87d068">审核通过</Tag>
        </p>
        <!-- <p>备注：{{ v.details }}</p> -->
        <template #actions style="padding: 0px">
          <Button
            size="small"
            type="primary"
            @click="putData(v.id, 3)"
            :disabled="v.status != 2"
          >
            绑定
          </Button>
          <Button size="small" type="danger" @click="putData(v.id, 1)">
            解除
          </Button>
        </template>
      </Card>
    </div>
    <Empty v-if="dataSoure.length == 0" />
  </indexVue>
</template>

<script>
import indexVue from "@/components/menu/index.vue";
import { getCurrentInstance, onMounted, reactive, toRefs } from "vue";
import { message, Input, Button, Card, Tag, Empty } from "ant-design-vue";
export default {
  components: { Input, Button, Card, Tag, Empty, indexVue },
  setup() {
    const { proxy } = getCurrentInstance();
    let data = reactive({
      username: "",
      dataSoure: [],
    });
    const getData = () => {
      proxy.$req
        .get("/v6/wechatuser/user", {
          username: data.username,
        })
        .then((response) => {
          if (response.code) {
            message.error(response.message);
            return;
          }
          data.dataSoure = response.data;
        });
    };
    const click = () => {
      getData();
    };
    onMounted(() => {
      getData();
    });
    const putData = (id, status) => {
      console.log(id, status);
      proxy.$req
        .put("/v6/wechatuser/user", {
          id: id,
          status: status,
        })
        .then((response) => {
          if (response.code) {
            message.error(response.message);
            return;
          }
          getData();
        });
    };
    return { ...toRefs(data), click, putData };
  },
  props: {
    authinfo: Object,
  },
};
</script>

<style>
</style>