<template>
  <div>
    <a-card :bordered="true">
      <a-spin :spinning="spinning">
        <div
          style="height: 60vh; justify-content: center; position: relative"
          id="map"
        />
      </a-spin>
    </a-card>
    <deivceInfoTableVue
      id="deivceInfoTableVue"
      :dataSource="dataInfo"
      :loadingMore="loadingMore"
      @changeMap="changeMap"
      @loadMore="loadMore"
    />
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance, watch, onUnmounted } from "vue";
import { Card as ACard, Spin as ASpin } from "ant-design-vue";
import { Scene, PointLayer } from "@antv/l7";
import { GaodeMap } from "@antv/l7-maps";
import deivceInfoTableVue from "./deivceInfoTable.vue";
import moment from "moment";
export default {
  components: { ACard, ASpin, deivceInfoTableVue },
  setup(props) {
    const { proxy } = getCurrentInstance();
    let data = reactive({
      dataInfo: [],
      chart: null,
      title: props.title,
      spinning: false,
      limit: 20,
      offset: 1,
      scene: null,
      plane: null,
      pointLayer: null,
      loadingMore: true,
      init: false,
    });
    const getData = () => {
      data.spinning = true;
      data.loadingMore = true;
      proxy.$req
        .get(props.url, {
          group: props.group,
          types: props.types,
          startTime: props.startTime,
          endTime: props.endTime,
          limit: data.limit,
          offset: (data.offset - 1) * data.limit,
        })
        .then((response) => {
          if (response.code != 0) {
            return;
          }
          let result = JSON.parse(response.data);
          if (!result.length) {
            data.loadingMore = false;
          }
          result.forEach((r) => {
            r.icon = "icon1";
            data.dataInfo.push(r);
          });
          if (data.scene) {
            data.scene.removeLayer(data.pointLayer);
            data.pointLayer = null;
            unclickPointer();
          }
          newChart();
        })
        .finally(() => {
          data.spinning = false;
          initMsgInfo();
        });
    };
    const newChart = () => {
      if (data.init) {
        return;
      }
      if (data.scene) {
        data.scene.destroy();
      }
      const scene = new Scene({
        id: "map",
        map: new GaodeMap({
          center: [105, 35],
          pitch: 0,
          style: "amap://styles/453e2f8e11603fc8f7548fe18959e9e9",
          zoom: 3,
        }),
        logoPosition: "topright",
      });
      data.scene = scene;
      getPointerLayer();
    };
    const getPointerLayer = () => {
      const fontFamily = "iconfont";
      data.scene.addIconFont("icon1", "&#xe6d4;");
      const fontPath =
        "//at.alicdn.com/t/font_2534097_fcae9o2mxbv.woff2?t=1622200439140";
      data.scene.addFontFace(fontFamily, fontPath);
      const pointLayer = new PointLayer({})
        .source(data.dataInfo, {
          parser: {
            type: "json",
            x: "location_longitude",
            y: "location_latitude",
          },
        })
        .shape("icon", "text")
        .size(20)
        .color("location_latitude", [
          "#094D4A",
          "#146968",
          "#1D7F7E",
          "#289899",
          "#34B6B7",
          "#4AC5AF",
          "#5FD3A6",
          "#7BE39E",
          "#A1EDB8",
          "#CEF8D6",
        ])
        .style({
          textAnchor: "center", // 文本相对锚点的位置 center|left|right|top|bottom|top-left
          padding: [0, 0], // 文本包围盒 padding [水平，垂直]，影响碰撞检测结果，避免相邻文本靠的太近
          stroke: "#ffffff", // 描边颜色
          fontFamily,
          iconfont: true,
          textAllowOverlap: true,
        });
      const plane = initPlane();
      data.plane = plane;
      pointLayer.on("click", (e) => {
        onClickPointer(e);
      });
      pointLayer.on("unclick", () => {
        unclickPointer();
      });
      if (!data.pointLayer) {
        data.scene.addLayer(pointLayer);
        data.pointLayer = pointLayer;
      }
      data.scene.destroy();
    };
    const unclickPointer = () => {
      data.plane.style.right = "-380px";
      data.scene.setZoom(3);
      data.scene.setCenter([105, 35], {
        padding: {
          right: 0,
        },
      });
    };
    const onClickPointer = (e) => {
      const { lng, lat } = e.lngLat;
      const deviceMsg = e.feature;
      data.scene.setCenter(
        [
          deviceMsg.location_longitude
            ? Number(deviceMsg.location_longitude)
            : lng,
          deviceMsg.location_latitude
            ? Number(deviceMsg.location_latitude)
            : lat,
        ],
        {
          // padding: {
          //   right: 120,
          // },
        }
      );
      data.scene.setZoom(16);
      data.plane.style.right = "0px";
      data.plane.innerHTML = `
        <div style="font-weight:600;">
          <p>设备异常信息<p/>
          <p>设备SN: ${deviceMsg.devicename}</p>
          <p>异常次数: <a style="color:red"> ${
            deviceMsg.exception_count
          }</a></p>
          <p>包名: <a style="color:black;font-weight:normal"> ${
            deviceMsg.exception_package
          }</a></p>
          <p>异常类型: <a style="color:black;font-weight:normal"> ${
            deviceMsg.exception_type
          }</a></p>
          <p>异常信息: <a style="color:black;font-weight:normal"> ${
            deviceMsg.exception_name
          }</a></p>
          <p>异常详细信息: <a style="color:black;font-weight:normal"> ${
            deviceMsg.exception_detail
          }</a></p>
          <p>异常发生时间: <a style="color:black;font-weight:normal"> ${moment(
            parseInt(deviceMsg.Time)
          )
            .utcOffset(480)
            .format(`YYYY-MM-DD HH:mm:ss`)}</a></p>
          <p>地址:<a style="font-weight:normal"> ${
            deviceMsg.location_province
          } ${deviceMsg.location_city} ${deviceMsg.location_district}</a></p>
          <p>经度: ${deviceMsg.location_longitude}</p>
          <p>纬度: ${deviceMsg.location_latitude}</p>
        </div>
        `;
    };
    function initPlane() {
      const el = document.createElement("div");
      el.style.background = "#fff";
      el.style.position = "absolute";
      el.style.padding = "10px";
      el.style.top = "0";
      el.style.right = "-380px";
      el.style.width = "350px";
      el.style.height = "100%";
      el.style.zIndex = "10";
      el.style.transition = "0.5s";
      el.style.overflow = "auto";
      el.style.marginRight = "right";
      const wrap = document.getElementById("map");
      wrap.appendChild(el);
      return el;
    }

    function initMsgInfo() {
      // el.innerText = '123'
      const wrap = document.getElementById("map");
      wrap.appendChild(document.getElementById("deivceInfoTableVue"));
    }
    const changeMap = (item) => {
      const data = { lngLat: {}, feature: item };
      onClickPointer(data);
    };
    const loadMore = () => {
      data.offset = data.offset + 1;
      getData();
    };
    watch(
      () => props,
      () => {
        data.dataInfo = [];
        data.offset = 1;
        getData();
      },
      { deep: true }
    );
    onUnmounted(() => {
      data.init = true;
      if (data.scene) {
        data.scene.destroy();
      }
    });
    return { loadMore, changeMap, ...toRefs(data) };
  },
  props: {
    url: String,
    title: String,
    maxValue: Number,
    minValue: Number,
    group: Number,
    types: Number,
    color: String,
    startTime: Number,
    endTime: Number,
  },
};
</script>
<style scoped>
.control51 {
  min-width: 150px;
  min-height: 20px;
  height: 180px;
}
</style>
