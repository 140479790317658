<template>
  <a-modal
    title="设备日使用流量分布"
    :width="800"
    :visible="visible"
    @cancel="onClose"
    :footer="false"
  >
    <lineChartVue
      :startTime="moment().startOf('month')"
      :sn="sn"
      title="flowChartVue"
      url="/v6/distributed/network/flow/month/date"
      :maxValue="120"
      :minValue="-120"
      v-if="visible && sn"
    />
  </a-modal>
</template>
<script>
import { defineComponent, reactive, ref, toRefs } from "vue";
import { Modal as AModal } from "ant-design-vue";
import moment from "moment";
import lineChartVue from "./lineChart.vue";
export default defineComponent({
  components: {
    AModal,
    lineChartVue,
  },
  setup() {
    let data = reactive({ basicInfo: {}, sn: "" });
    const refData = toRefs(data);
    const visible = ref(false);
    const showDrawer = (sn) => {
      data.sn = sn;
      visible.value = true;
    };
    const onClose = () => {
      visible.value = false;
    };

    return {
      ...refData,
      moment,
      visible,
      showDrawer,
      onClose,
    };
  },
});
</script>
<style scoped>
:deep(.ant-descriptions-item-label) {
  font-weight: 700;
  font-size: 12px;
}
:deep(.ant-card-body) {
  padding: 0px;
}
:deep(.ant-drawer-body) {
  padding-top: 0px;
}
</style>
