<template>
  <Modal v-model:visible="visible" :width="600" okTtext="确认" cancelText="取消" title="商户组添加设备" @cancel="close"
    :maskClosable="false" :style="{
            top: '30%',
          }">
    <div class="addgroupdevice">
      <UploadDragger v-model:fileList="fileList" name="file" :multiple="false" :beforeUpload="beforeUpload"
        @change="handleChange" :disabled="disabled" accept=".xlsx" :showUploadList="false">
        <Spin :spinning="disabled">
          <p class="ant-upload-drag-icon">
            <InboxOutlined></InboxOutlined>
          </p>
          <p class="ant-upload-text">单击或拖动文件到该区域进行上传</p>
          <p class="ant-upload-hint" style="width: 550px">
            仅支持上传单个xlsx表格文件
          </p>
        </Spin>
      </UploadDragger>
      <a href="/添加设备模板.xlsx" style="text-decoration: underline">
        <div class="downdevicemobo">
          <ArrowDownOutlined />
          <span> 商户组添加设备模板.xlsx </span>
        </div>
      </a>
    </div>
  </Modal>
</template>

<script>
import { Modal, Upload, notification, Spin } from "ant-design-vue";
import { reactive, toRefs, getCurrentInstance } from "vue";
import { InboxOutlined, ArrowDownOutlined } from "@ant-design/icons-vue";
import xlsx from "xlsx";
import Que from "./que";
export default {
  components: {
    Modal,
    UploadDragger: Upload.Dragger,
    InboxOutlined,
    Spin,
    ArrowDownOutlined,
  },
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    let data = reactive({
      visible: true,
      fileList: [],
      disabled: false,
      drawerShow: false,
      drawerList: [],
    });
    const refData = toRefs(data);
    const close = () => {
      emit("my-emit");
    };
    const handleChange = (info) => {
      if (
        info.file.type !=
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        if (info.fileList.length > 1) {
          data.fileList = [info.fileList[0]];
        } else {
          data.fileList = [];
        }
        notification["error"]({
          message: "选择文件失败",
          description: "仅支持上传单个xlsx表格文件",
        });
        return;
      }

      if (info.fileList.length) {
        data.fileList = [info.file];
        parsingExcel(info.file);
      } else {
        data.fileList = [];
      }
    };
    const beforeUpload = () => {
      return false;
    };
    const retParsingExcel = (v) => {
      notification["error"]({
        message: "添加失败",
        description: v,
      });
      data.disabled = false;
    };
    const parsingExcel = async (file) => {
      data.disabled = true;
      const fileReader = new FileReader();
      fileReader.onload = async (ev) => {
        const result = ev.target.result;
        const workbook = xlsx.read(result, {
          type: "binary",
        }); // 读取数据
        const wsname = workbook.SheetNames[0]; // 取第一张表
        const ws = xlsx.utils.sheet_to_json(workbook.Sheets[wsname]);
        if (ws.length > 3000) {
          return retParsingExcel("单次只能添加3000台设备");
        }
        data.drawerShow = true;
        var snList = [];
        for (const v of ws) {
          let sn = v["SN"];
          if (!sn) {
            return retParsingExcel("表格错误");
          }
          if (sn.length != 19 || sn.substr(0, 3) != "MLA") {
            console.log(sn.length != 19, sn.substr(0, 3) != "MLA");
            return retParsingExcel("SN错误:" + sn);
          }
          snList.push(sn);
        }

        addgroupdevice(snList);
      };
      fileReader.readAsBinaryString(file);
    };
    const addgroupdevice = async (sn) => {
      await Que.snPost(proxy, {
        installid: props.installID,
        sn: sn,
      });
      close();
    };
    return {
      ...refData,
      close,
      handleChange,
      beforeUpload,
      parsingExcel,
      addgroupdevice,
    };
  },
  props: {
    installID: Number,
  },
};
</script>
<style>
.downdevicemobo {
  border: rgb(151, 151, 148) dashed 1px;
  margin-top: 10px;
}
</style>
