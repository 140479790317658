<template>
  <div>
    <a-modal :width="800" v-model:visible="visible" title="创建新的预警" @ok="handleOk" @cancel="handleCancel">
      <a-form ref="ruleForm" :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-row>
          <a-col :span="12">
            <a-form-item label="所属商户组" name="groupid" :rules="[
                              {
                                required: true,
                                type: 'number',
                                message: '所属商户组不能为空',
                                whitespace: true,
                              },
                            ]">
              <a-select v-model:value="formState.groupid" placeholder="请选择所属商户组" show-search>
                <a-select-option :key="-1">全部设备</a-select-option>
                <!-- groupList -->
                <a-select-option v-for="v in groupList" :key="v.id">
                  {{ v.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="预警项" :rules="[
                              { required: true, message: '预警项不能为空', whitespace: true },
                            ]" name="items">
              <a-select v-model:value="formState.items" placeholder="请选择预警项" show-search :options="warnItems">
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="预警类型" :rules="[
                              {
                                required: true,
                                type: 'number',
                                message: '预警类型不能为空',
                                whitespace: true,
                              },
                            ]" name="types">
              <a-select v-model:value="formState.types" placeholder="请选择预警类型" show-search :options="warnTypes">
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="比较值" :rules="[
                              {
                                required: true,
                                type: 'number',
                                message: '比较值不能为空、必须为数字类型',
                              },
                            ]" name="values">
              <a-input-number style="width: 200px" v-model:value="formState.values" placeholder="请输入比较值(数字类型)"
                :precision="2" :min="0.01" :max="99999" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col>
            <a-form-item name="emailList" :rules="[
                              {
                                required: true,
                                message: '邮箱不能为空',
                                type: 'array',
                                whitespace: true,
                              },
                            ]" :wrapperCol="{ span: 8, offset: 0 }">
              <a-input v-model:value="formState.emails" hidden />
              <a-card size="small" style="width: 700px; min-height: 200px">
                <template #title><a style="color: red">*</a>发送预警至邮箱
                </template>
                <template #extra>
                  <!-- <a @click="$refs.emailFormVue.showModal()">添加邮箱</a> -->
                  <Button @click="$refs.emailFormVue.showModal()" type="dashed"
                    :disabled="formState.emailList.length > 4">
                    添加邮箱
                  </Button>
                </template>
                <a-list size="small" :data-source="formState.emailList">
                  <template #renderItem="{ item }">
                    <a-list-item>
                      <template #actions>
                        <a @click="removeEmail(item)">移除</a>
                      </template>
                      {{ item }}
                    </a-list-item>
                  </template>
                </a-list>
              </a-card>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
    <emailFormVue ref="emailFormVue" @saveEmails="saveEmails" />
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance, ref } from "vue";
import {
  Modal as AModal,
  Form as AForm,
  Select as ASelect,
  Row as ARow,
  Col as ACol,
  InputNumber as AInputNumber,
  Input as AInput,
  Card as ACard,
  List as AList,
  notification,
  Button,
} from "ant-design-vue";
import { warnTypes, warnItems } from "../../data";

import emailFormVue from "./emailForm.vue";
export default {
  components: {
    AList,
    AListItem: AList.Item,
    AModal,
    AForm,
    AFormItem: AForm.Item,
    ASelect,
    ASelectOption: ASelect.Option,
    ARow,
    ACol,
    AInput,
    AInputNumber,
    ACard,
    emailFormVue,
    Button,
  },
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    const data = reactive({
      formState: { emailList: [] },
      groupList: [],
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      visible: false,
    });
    const ruleForm = ref(null);
    const showModal = () => {
      data.visible = true;
      getGroupList();
    };
    const handleOk = () => {
      if (!ruleForm.value) {
        return;
      }
      ruleForm.value.validate().then(() => {
        data.formState.emails = data.formState.emailList.join(",");
        proxy.$req.post("/v6/warning", data.formState).then((response) => {
          console.log("response", response);
          if (response.code) {
            notification["error"]({
              message: "添加预警失败",
              description: response.message,
            });
            return;
          }
          data.visible = false;
          data.formState = { emailList: [] };
          notification["success"]({
            message: "添加预警成功",
            description: response.message,
          });
          emit("handleQuery");
        });
      });
    };
    const getGroupList = () => {
      proxy.$req
        .get("/v6/deice/group", {
          limit: data.limit,
          offset: (data.offset - 1) * data.limit,
          sn: data.sn,
          group: data.group,
        })
        .then((response) => {
          if (response.code) {
            notification["error"]({
              message: "获取商户组信息失败",
              description: response.message,
            });
            return;
          }
          data.groupList = response.data;
        });
    };
    const saveEmails = (email) => {
      // 先检测email是否重复
      const index = data.formState.emailList.findIndex((v) => {
        return v == email;
      });
      if (index > -1) {
        return;
      }
      data.formState.emailList.push(email);
      // data.formState.emailList = [...new Set(data.formState.emailList)];
    };
    const removeEmail = (emial) => {
      data.formState.emailList.splice(
        data.formState.emailList.findIndex((item) => item === emial),
        1
      );
    };
    const handleCancel = () => {
      data.visible = false;
      data.formState = { emailList: [] };
    };
    return {
      ruleForm,
      removeEmail,
      saveEmails,
      warnTypes,
      warnItems,
      ...toRefs(data),
      showModal,
      handleOk,
      handleCancel,
    };
  },
};
</script>

<style>
.divserach {
  padding: 10px;
  float: left;
  position: relative;
}
</style>
