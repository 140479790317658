<template>
  <indexVue>
    <infoVue />
  </indexVue>
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive, toRefs } from "vue";
import infoVue from "./info.vue";
import indexVue from "@/components/menu/index.vue";

export default {
  name: "home",
  components: {
    infoVue,
    indexVue,
  },
  setup() {
    // const { proxy } = getCurrentInstance();
    let data = reactive({
      collapsed: false,
      breadcrumbList: [],
    });

    onMounted(() => {});
    if (document.body.clientWidth <= 1200) {
      data.collapsed = true;
    }
    const refData = toRefs(data);
    const cliidclose = (value) => {
      data.breadcrumbList = value;
    };
    return {
      ...refData,
      cliidclose,
    };
  },
};
</script>

<style>
.homeLayout {
  height: 100%;
  width: 100%;
  background: whitesmoke;
}
.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 5px;
  cursor: pointer;
  transition: color 0.3s;
}
.trigger:hover {
  color: #1890ff;
}
.triggerdiv {
  position: absolute;
  left: 100%;
  width: 25px;
}
</style>
