<template>
  <indexVue>
    <Tabs v-model:activeKey="activeKey">
      <TabPane :key="1" tab="指令列表">
      </TabPane>
      <TabPane :key="2" tab="下行列表" force-render>
      </TabPane>
    </Tabs>
    <div v-if="activeKey == 1">
      <Table :columns="columns" :rowKey="(record) => record.ID" :pagination="false" :data-source="dataSource"
        :loading="loading" style="float: left; width: 100%" size="small">
        <template #index="{ index }">
          {{ index + (offset - 1) * limit + 1 }}
        </template>
        <template #operation="{ record }">
          <Space>
            <Button type="primary" @click="setInstruction(record)">
              下发指令
            </Button>
          </Space>
        </template>
      </Table>
      <Pagination :total="total" :showTotal="(total) => `总共 ${total} `" :pageSize="limit" show-quick-jumper
        :showSizeChanger="true" @change="paginationChange" @showSizeChange="showSizeChange" v-model:current="offset"
        :pageSizeOptions="['10', '20']" style="float: right">
        <template #buildOptionText="props">
          <span>{{ props.value }}/页</span>
        </template>
      </Pagination>
    </div>
    <Downinfo v-else />
    <downVue v-if="downShow" :instruction="instruction" @close="chiildClose" />
  </indexVue>
</template>

<script>
import { reactive, toRefs, getCurrentInstance, onMounted } from "vue";
import indexVue from "../../components/menu/index.vue";
import { Table, Pagination, Space, Button, Tabs } from "ant-design-vue";
import Que from "./que";
import downVue from "./down.vue";
import Downinfo from "./downinfo.vue";

export default {
  components: { indexVue, Table, Pagination, Space, Button, downVue, Tabs, TabPane: Tabs.TabPane, Downinfo },
  setup() {
    const { proxy } = getCurrentInstance();
    const state = reactive({
      columns: [
        {
          title: "序号",
          key: "index",
          width: 70,
          slots: { customRender: "index" },
          fixed: "left",
        },
        {
          title: "指令名称",
          dataIndex: "name",
        },
        {
          title: "内容是否可填写",
          dataIndex: "buttontype",
          slots: {
            customRender: "buttontype",
          },
          customRender: ({ text }) => {
            if (text == 1) {
              return "可填写";
            }
            return "不可填写";
          },
        },
        {
          title: "操作",
          dataIndex: "operation",
          slots: {
            customRender: "operation",
          },
        },
      ],
      dataSource: [],
      total: 0,
      loading: false,
      limit: 10,
      offset: 1,
      fuzzy: "",
      downShow: false,
      instruction: {},
      activeKey: 1,
    });
    onMounted(() => {
      getData();
    });
    const getData = async () => {
      state.loading = true;
      const response = await Que.get(proxy, {
        limit: state.limit,
        offset: (state.offset - 1) * state.limit,
        fuzzy: state.fuzzy,
      });
      state.loading = false;

      if (response) {
        state.dataSource = response.data;
        state.total = parseInt(response.total);
      }
    };
    const paginationChange = () => {
      getData();
    };
    const showSizeChange = (current, size) => {
      state.offset = 1;
      state.limit = size;
      getData();
    };
    const chiildClose = () => {
      state.downShow = false;
    };
    const setInstruction = (info) => {
      state.instruction = info;
      state.downShow = true;
    };
    return {
      ...toRefs(state),
      paginationChange,
      showSizeChange,
      chiildClose,
      setInstruction,
    };
  },
};
</script>

<style lang="scss" scoped></style>