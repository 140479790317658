<template>
  <Modal :visible="true" @cancel="cancel" @ok="ok" title="指令下发" :centered="true" :maskClosable="!disabled">
    <Row>
      <Col :span="4">指令名称:</Col>
      <Col :span="20">{{ instruction.name }}</Col>
    </Row>
    <Row style="margin-top: 20px" v-show="instruction.buttontype == 1">
      <Col :span="4">指令内容:</Col>
      <Col :span="20">
      <TextArea :autoSize="{ minRows: 4, maxRows: 8 }" v-model:value="msg" />
      </Col>
    </Row>
    <Row style="margin-top: 20px">
      <Col :span="4">
      <div class="downtext">下发设备:</div>
      </Col>
      <Col :span="20">
      <Button style="width: 100%" @click="deviceShow = true">
        选择设备 (<span style="color: green"> 已选择 {{ sn.length }} </span>)
      </Button>
      </Col>
    </Row>
    <Modal v-if="deviceShow" :visible="true" :centered="true" title="选择设备" @cancel="cancelChild" @ok="okChild"
      :maskClosable="!disabled">
      <Tabs v-model:activeKey="activeKey" @change="change">
        <TabPane :key="1" tab="输入SN">
          <div class="downTabPane">
            <TextArea :autoSize="{ minRows: 17, maxRows: 17 }" placeholder="输入要下行的设备SN号,多个设备SN用换行输入
  例子:
  MLAT1ZXXXXXXXXXXXXXXX1
  MLAT1ZXXXXXXXXXXXXXXX2" v-model:value="snOLd" />
          </div>
        </TabPane>
        <TabPane :key="4" tab="导入SN">
          <div class="downTabPane">
            <div style="height: 370px">
              <UploadDragger
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                v-model:fileList="fileList" name="file" :multiple="false" :beforeUpload="beforeUpload"
                @change="handleChange" :disabled="disabled" :showUploadList="false">
                <p>
                  <inbox-outlined class="downTabPaneIcon"></inbox-outlined>
                </p>
                <p class="ant-upload-text">单击或拖动文件到此区域进行上传</p>
              </UploadDragger>
            </div>
            <p>
              <a href="/添加设备模板.xlsx" style="text-decoration: underline">
                <ArrowDownOutlined />
                <span> 商户组添加设备模板.xlsx </span>
              </a>
            </p>
          </div>
        </TabPane>
        <TabPane :key="2" tab="按设备型号" v-if="false">
          <div class="downTabPane">
            <checkAllVue :dataList="typesList" @close="childClose" v-if="activeKey == 2" />
          </div>
        </TabPane>
        <TabPane :key="3" tab="按设备分组" v-if="false">
          <div class="downTabPane">
            <checkAllVue :dataList="groupList" @close="childClose" v-if="activeKey == 3" />
          </div>
        </TabPane>
      </Tabs>
      <template #footer>
        <Button key="back" @click="cancelChild" :disabled="disabled">取消</Button>
        <Button key="submit" type="primary" :loading="disabled" @click="okChild">
          确认
        </Button>
      </template>
    </Modal>

    <template #footer>
      <Button key="back" @click="cancel" :disabled="disabled">取消</Button>
      <Button key="submit" type="primary" :loading="disabled" @click="ok">
        确认
      </Button>
    </template>
  </Modal>
</template>

<script>
import { reactive, toRefs, getCurrentInstance, onMounted } from "vue";
import {
  Modal,
  Row,
  Col,
  Input,
  Button,
  Tabs,
  Upload,
  notification,
} from "ant-design-vue";
import { InboxOutlined, ArrowDownOutlined } from "@ant-design/icons-vue";
import Que from "./que";
import checkAllVue from "./checkAll.vue";
import xlsx from "xlsx";

export default {
  components: {
    Modal,
    Row,
    Col,
    TextArea: Input.TextArea,
    Button,
    Tabs,
    TabPane: Tabs.TabPane,
    UploadDragger: Upload.Dragger,
    InboxOutlined,
    ArrowDownOutlined,
    checkAllVue,
  },
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    const state = reactive({
      deviceShow: false,
      activeKey: 1,
      snOLd: "",
      sn: [],
      msg: "",
      groupList: [],
      typesList: [],
      checkList: [],
      exportSN: [],
      fileList: [],
      disabled: false,
    });
    const cancel = () => {
      emit("close");
    };
    const ok = async () => {
      state.disabled = true;
      if (state.sn.length == 0) {
        notification["error"]({
          message: "下行指令错误",
          description: "SN不能为空",
        });
        state.disabled = false;
        return;
      }
      if (props.instruction.buttontype == 1 && state.msg == "") {
        notification["error"]({
          message: "下行指令错误",
          description: "下行内容不能为空",
        });
        state.disabled = false;
        return;
      }
      var resp = await Que.downPost(proxy, {
        instructionID: props.instruction.ID,
        msg: state.msg,
        sn: state.sn,
      });
      if (resp) {
        state.disabled = false;
        cancel()
        return;
      }
      state.disabled = false;
    };
    const change = () => {
      state.snOLd = "";
      state.checkList = [];
    };
    const cancelChild = () => {
      state.deviceShow = false;
    };
    const okChild = async () => {
      switch (state.activeKey) {
        case 1:
          // 手动填写
          if (!checkActiveKey1()) {
            return;
          }
          break;
        case 2:
          // 设备型号导入
          state.disabled = true;
          var result = await checkActiveKey2();
          state.disabled = false;
          if (!result) {
            return;
          }
          break;
        case 3:
          // 商户组导入
          state.disabled = true;
          result = await checkActiveKey3();
          state.disabled = false;
          if (!result) {
            return;
          }
          break;
        case 4:
          // 表格导入，导入的时候就已经校验
          state.sn = state.exportSN;
          break;
      }

      // state.sn = state.snOLd;
      cancelChild();
      state.deviceShow = false;
    };
    // 手动填写
    const checkActiveKey1 = () => {
      if (state.snOLd == "") {
        notificationError("SN不能为空");
        return false;
      }
      const snOld = state.snOLd;
      const snOldList = snOld.split("\n");
      var newList = [];
      for (const v of snOldList) {
        if (v == "") {
          continue;
        }
        if (v.length != 19) {
          notificationError("SN长度错误");
          return false;
        }
        newList.push(v);
      }
      state.sn = newList;
      return true;
    };
    // 设备型号导入
    const checkActiveKey2 = async () => {
      if (state.checkList.length == 0) {
        notificationError("设备不能为空");
        return false;
      }
      var deviceList = [];
      for (const v of state.checkList) {
        const resp = await Que.getDeviceNum(proxy, {
          limit: 99999,
          offset: 0,
          types: v,
          // dispense:1,
        });
        if (!resp) {
          return false;
        }
        deviceList.push(...resp.data);
      }
      state.sn = deviceList;
      return true;
    };
    // 商户组导入
    const checkActiveKey3 = async () => {
      if (state.checkList.length == 0) {
        notificationError("设备不能为空");
        return false;
      }
      var deviceList = [];
      for (const v of state.checkList) {
        const resp = await Que.getDeviceNum(proxy, {
          limit: 99999,
          offset: 0,
          // types: v,
          dispense: v,
        });
        if (!resp) {
          return false;
        }
        deviceList.push(...resp.data);
      }
      state.sn = deviceList;
      return true;
    };
    // 表格导入
    const checkActiveKey4 = () => { };
    const notificationError = (msg) => {
      notification["error"]({
        message: "选择设备失败",
        description: msg,
      });
    };
    const getList = async () => {
      state.groupList = await Que.getGroupList(proxy);
      state.typesList = await Que.getTypesList(proxy);
    };
    const childClose = (data) => {
      state.checkList = data;
    };
    const beforeUpload = () => {
      return false;
    };
    const handleChange = (info) => {
      if (
        info.file.type !=
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        if (info.fileList.length > 1) {
          state.fileList = [info.fileList[0]];
        } else {
          state.fileList = [];
        }
        notification["error"]({
          message: "选择文件失败",
          description: "仅支持上传单个xlsx表格文件",
        });
        return;
      }

      if (info.fileList.length) {
        state.fileList = [info.file];
        parsingExcel(info.file);
      } else {
        state.fileList = [];
      }
    };
    const parsingExcel = async (file) => {
      state.disabled = true;
      const fileReader = new FileReader();
      fileReader.onload = async (ev) => {
        const result = ev.target.result;
        const workbook = xlsx.read(result, {
          type: "binary",
        }); // 读取数据
        const wsname = workbook.SheetNames[0]; // 取第一张表
        const ws = xlsx.utils.sheet_to_json(workbook.Sheets[wsname]);
        if (ws.length > 3000) {
          notification["error"]({
            message: "添加失败",
            description: "单次只能导入3000台设备",
          });
          return retParsingExcel();
        }
        var exportSN = [];
        for (const v of ws) {
          let sn = v["SN"];
          if (!sn) {
            notification["error"]({
              message: "导入SN失败",
              description: "表格错误",
            });
            return retParsingExcel();
          }
          if (sn.length != 19) {
            notification["error"]({
              message: "导入SN失败",
              description: "SN错误",
            });
          }
          exportSN.push(sn);
        }

        notification["success"]({
          message: "导入SN成功",
          description: "",
        });
        state.exportSN = exportSN;
        retParsingExcel();
        okChild();
        // return true;
      };
      fileReader.readAsBinaryString(file);
    };
    const retParsingExcel = () => {
      state.disabled = false;
      return false;
    };
    onMounted(() => {
      getList();
    });
    return {
      ...toRefs(state),
      cancel,
      ok,
      change,
      cancelChild,
      okChild,
      getList,
      childClose,
      checkActiveKey4,
      beforeUpload,
      handleChange,
    };
  },
  props: {
    instruction: Object,
  },
};
</script>

<style lang="scss" scoped>
.downtext {
  line-height: 33px;
  height: 33px;
}

.downTabPane {
  height: 400px;
}

.downTabPaneIcon {
  font-size: 70px;
  color: #1890ff;
}
</style>