<template>
  <div class="rankTable">
    <a-card :bordered="true">
      <Table
        size="small"
        :columns="columns"
        :rowKey="(record) => record.devicename"
        :pagination="false"
        :data-source="dataSource"
        :loading="loading"
        :scroll="{ y: 280 }"
      >
        <template #operation="{ text, record }">
          <a @click="$refs.detailsVue.showDrawer(record.devicename)">
            {{ text }}
          </a>
        </template>
      </Table>
      <div
        :style="{
          textAlign: 'center',
          marginTop: '12px',
          height: '32px',
          lineHeight: '32px',
        }"
      >
        <a v-if="loadingMore" @click="loadMore(offset + 1, 10)">加载更多</a>
        <a v-else>已经到底啦~</a>
      </div>
      <rankDetailsVue ref="detailsVue" />
    </a-card>
  </div>
</template>

<script>
import { Table, Card as ACard, notification } from "ant-design-vue";
import {
  reactive,
  toRefs,
  getCurrentInstance,
  ref,
  watch,
  onMounted,
} from "vue";
import moment from "moment";
import rankDetailsVue from "./rankDetails.vue";
export default {
  components: {
    Table,
    ACard,
    rankDetailsVue,
  },
  setup(props) {
    const { proxy } = getCurrentInstance();
    const detailsVue = ref(null);
    let data = reactive({
      moment,
      columns: [
        {
          title: "设备SN",
          dataIndex: "devicename",
          width: 180,
          slots: {
            customRender: "operation",
          },
        },
        {
          title: "流量",
          dataIndex: "_value",
          width: 80,
          customRender: function ({ text }) {
            return (text / 1024).toFixed(3) + "GB";
          },
        },
      ],
      dataSource: [],
      total: 0,
      loading: false,
      limit: 10,
      offset: 1,
      selectTimeout: null,
      group: 0,
      sn: "",
      types: 0,
      typesList: [],
      groupList: [],
      startTime: props.startTime,
      endTime: props.endTime,
      loadingMore: true,
    });
    const refData = toRefs(data);

    const getData = () => {
      data.loadingMore = true;
      data.loading = true;
      proxy.$req
        .get(props.url, {
          group: props.group,
          types: props.types,
          limit: data.limit,
          offset: (data.offset - 1) * data.limit,
          startTime: props.startTime,
          endTime: props.endTime,
        })
        .then((response) => {
          if (response.code != 0) {
            notification["error"]({
              message: "获取数据失败",
              description: response.message,
            });
            return;
          }
          let result = JSON.parse(response.data);
          if (!result.length) {
            data.loadingMore = false;
          }
          result.forEach((element) => {
            data.dataSource.push(element);
          });
        })
        .finally(() => {
          data.loading = false;
        });
    };
    watch(
      () => props,
      () => {
        data.dataSource = [];
        getData();
      },
      { deep: true }
    );
    onMounted(() => {
      getData();
    });
    const paginationChange = () => {
      data.dataSource = [];
      getData();
    };
    const loadMore = (current, size) => {
      data.offset = current;
      data.limit = size;
      getData();
    };

    return {
      detailsVue,
      ...refData,
      paginationChange,
      loadMore,
    };
  },
  props: {
    url: String,
    title: String,
    group: Number,
    types: Number,
    color: String,
    startTime: Number,
    endTime: Number,
    refresh: Boolean,
  },
};
</script>

<style scoped>
:deep(.ant-card-body) {
  padding: 5px;
}
.rankTable {
  border: 1px solid rgb(224, 224, 224);
  height: 330px;
}
</style>
