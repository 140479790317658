<template>
  <indexVue>
    <div>
      <Tooltip>
        <template #title>返回商户组列表</template>
        <ArrowLeftOutlined class="trigger returnRight" :style="{
          fontSize: '25px',
        }" @click="returnGroup" />
      </Tooltip>
      <span style="font-size: 25px">商户组信息</span>
    </div>
    <div style="background: white; padding: 10px">
      <Descriptions :bordered="true" :column="{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }" size="small">
        <template #extra>
          <Button @click="updateClick(true)">
            <SettingOutlined />
            修改
          </Button>
        </template>
        <DescriptionsItem label="商户组名称">
          {{ info.name }}
        </DescriptionsItem>
        <DescriptionsItem label="商户组备注">
          {{ info.details }}
        </DescriptionsItem>
        <DescriptionsItem label="商户组创建时间">
          {{ new Date(parseInt(info.createtime)).toLocaleString() }}
        </DescriptionsItem>
        <DescriptionsItem label="商户组更新时间">
          {{ new Date(parseInt(info.updatetime)).toLocaleString() }}
        </DescriptionsItem>
        <!-- info.data -->
        <DescriptionsItem v-for="(v, k) in info.data" :key="k" :label="k" :span="4">
          <Tag v-for="(v1, k1) in v" :key="k1"> {{ v1 }} </Tag>
        </DescriptionsItem>
      </Descriptions>
    </div>
    <div class="divserach">
      <span>设备SN：</span>
      <Input style="width: 240px" @change="changeSelect" allowClear v-model:value="sn" />
    </div>
    <div class="divAdd">
      <Button @click="addShow = true">
        <PlusOutlined />
        添加设备
      </Button>
    </div>
    <Table :columns="columns" :rowKey="(record) => record.ID" :pagination="false" :data-source="dataSource"
      :loading="loading" style="float: left; width: 100%" size="small">
      <template #index="{ index }">
        {{ index + 1 }}
      </template>
      <template #status="{ text }">
        <Tag color="green" v-if="text == 1"> 在线 </Tag>
        <Tag color="darkgray" v-else> 离线 </Tag>
      </template>
      <template #updatetime="{ text }">
        {{
          moment(parseInt(text)).utcOffset(480).format("YYYY-MM-DD HH:mm:ss")
        }}
      </template>
      <template #operation="{ record }">
        <Space>
          <Popconfirm title="确定删除该设备吗" ok-text="确定" cancel-text="取消" @confirm="delGroupDevice(record.sn)">
            <Button type="link" :style="{
              color: 'red',
            }">
              <DeleteOutlined />
              删除
            </Button>
          </Popconfirm>
        </Space>
      </template>
    </Table>
    <Pagination :total="total" :showTotal="(total) => `一共 ${total} 台设备`" :pageSize="limit" show-quick-jumper
      :showSizeChanger="true" @change="paginationChange" @showSizeChange="showSizeChange" v-model:current="offset"
      style="float: right" :pageSizeOptions="['10', '20', '30', '50', '100']">
      <template #buildOptionText="props">
        <span>{{ props.value }}/页</span>
      </template>
    </Pagination>
    <detailsaddVue v-if="addShow" @my-emit="childClose" :groupID="group" />
    <infoupdateVue @my-emit="updateClick(false)" v-if="updateShow" :nameDis="info" />
  </indexVue>
</template>

<script>
import {
  Table,
  notification,
  Pagination,
  Input,
  Descriptions,
  Tooltip,
  Button,
  Space,
  Popconfirm,
  Tag,
} from "ant-design-vue";
import { reactive, toRefs, onMounted, getCurrentInstance } from "vue";
import router from "@/router";
import {
  ArrowLeftOutlined,
  PlusOutlined,
  SettingOutlined,
  DeleteOutlined,
} from "@ant-design/icons-vue";
import detailsaddVue from "./detailsadd.vue";
import moment from "moment";
import infoupdateVue from "./infoupdate.vue";
import indexVue from "@/components/menu/index.vue";

export default {
  components: {
    Table,
    Pagination,
    Input,
    Descriptions,
    DescriptionsItem: Descriptions.Item,
    ArrowLeftOutlined,
    Tooltip,
    PlusOutlined,
    Button,
    detailsaddVue,
    SettingOutlined,
    DeleteOutlined,
    Space,
    Popconfirm,
    Tag,
    infoupdateVue,
    indexVue,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    let data = reactive({
      moment,
      columns: [
        {
          title: "序号",
          key: "index",
          width: 60,
          slots: { customRender: "index" },
          fixed: "left",
        },
        {
          title: "设备SN",
          dataIndex: "sn",
        },
        {
          title: "设备型号",
          dataIndex: "devicetypesid",
          customRender: function ({ text }) {
            let info = data.typesList.find((v) => {
              return v.id == text;
            });
            return info ? info.name : "-";
          },
        },
        {
          title: "在线状态",
          dataIndex: "status",
          slots: {
            customRender: "status",
          },
        },
        {
          title: "设备出厂日期",
          dataIndex: "updatetime",
          slots: {
            customRender: "updatetime",
          },
        },
        {
          title: "操作",
          dataIndex: "operation",
          slots: {
            customRender: "operation",
          },
        },
      ],
      dataSource: [],
      total: 0,
      loading: false,
      limit: 10,
      offset: 1,
      selectTimeout: null,
      group: 0,
      sn: "",
      info: {},
      addShow: false,
      updateShow: false,
      typesList: [],
    });
    const refData = toRefs(data);
    const getData = () => {
      data.loading = true;
      proxy.$req
        .get("/v6/deice/list", {
          limit: data.limit,
          offset: (data.offset - 1) * data.limit,
          sn: data.sn,
          dispense: data.group,
        })
        .then((response) => {
          data.loading = false;
          if (response.code) {
            notification["error"]({
              message: "获取设备列表失败",
              description: response.message,
            });
            return;
          }
          data.dataSource = response.data;
          data.total = parseInt(response.total);
        });
    };
    onMounted(() => {
      getTypesList();
      data.group = router.currentRoute._rawValue.query.id;
      data.group = parseInt(data.group);
      if (!data.group) {
        notification["error"]({
          message: "获取商户组信息失败",
          description: "商户组有误",
        });
        returnGroup();
        return;
      }
      getGroupInfo();
      getData();
    });
    const paginationChange = () => {
      getData();
    };
    const showSizeChange = (current, size) => {
      data.offset = 1;
      data.limit = size;
      getData();
    };
    const changeSelect = () => {
      if (data.selectTimeout) {
        clearTimeout(data.selectTimeout);
      }
      data.selectTimeout = setTimeout(() => {
        getData();
      }, 500);
    };
    const getGroupInfo = () => {
      proxy.$req
        .get("/v6/group/info", {
          ID: data.group,
        })
        .then((response) => {
          if (response.code) {
            notification["error"]({
              message: "获取商户组信息失败",
              description: response.message,
            });
            return;
          }
          var info = response.data;
          info.data = JSON.parse(info.data);
          data.info = response.data;
        });
    };
    const returnGroup = () => {
      router.push("/group");
    };
    const updateClick = (result) => {
      // router.push("/group/update?id=" + data.group);
      data.updateShow = result;
      getGroupInfo();
      getData();
    };
    const delGroupDevice = (sn) => {
      proxy.$req
        .put("/v6/group/device", {
          ID: data.group,
          sn: sn,
        })
        .then((response) => {
          if (response.code) {
            notification["error"]({
              message: "删除失败",
              description: response.message,
            });
          } else {
            notification["success"]({
              message: "删除成功",
              description: "删除成功",
            });
          }
          getData();
        });
    };

    const getTypesList = () => {
      proxy.$req.get("/v6/deice/types").then((response) => {
        if (response.code) {
          notification["error"]({
            message: "获取设备型号信息失败",
            description: response.message,
          });
          return;
        }
        data.typesList = response.data;
      });
    };
    const childClose = () => {
      data.addShow = false;
      getData();
    };
    return {
      ...refData,
      paginationChange,
      showSizeChange,
      changeSelect,
      returnGroup,
      updateClick,
      delGroupDevice,
      childClose,
      getTypesList,
    };
  },
};
</script>

<style>
.divserach {
  padding: 10px;
  float: left;
  position: relative;
}

.divAdd {
  float: right;
  position: relative;
  padding: 10px;
}
</style>
