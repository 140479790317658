<template>
  <div>
    <a-form :model="emailForm" ref="ruleForm" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-row>
        <a-col :xs="24" :sm="24" :md="24" :lg="12"><a-form-item label="时间范围">
            <myRangePickerVue @searchTime="searchTime" :defaultTime="defaultTime" :type="'second'" :disabledDateNum="3" />
          </a-form-item></a-col>
        <a-col :xs="24" :sm="24" :md="24" :lg="12"><a-form-item label="预警项">
            <a-select style="width: 200px" allowClear v-model:value="items" placeholder="请选择预警项" show-search
              @change="searchData" :options="warnItems" /> </a-form-item></a-col>
      </a-row>
    </a-form>

    <Table :columns="columns" :rowKey="(record) => record.merchantsid" :pagination="false" :data-source="dataSource"
      :loading="loading" :scroll="{ x: 800, y: 'calc(100vh - 310px)' }">
      <template #index="{ index }">
        {{ index + 1 }}
      </template>
      <template #createtime="{ text }">
        {{
                moment(parseInt(text)).utcOffset(480).format("YYYY-MM-DD HH:mm:ss")
                }}
      </template>
      <template #items="{ text }">
        <a-tag :color="warnItemsColorsDict.get(text)">{{ `${text} ` }}</a-tag>
      </template>
      <template #types="{ record }">
        <a :style="{
                      color: warnItemsColorsDict.get(record.items),
                      fontWeight: 600,
                    }">{{ `${record.types} ${record.values}` }}</a>
      </template>
      <template #actual="{ record }">
        <a :style="{
                      color: 'black',
                      fontWeight: 600,
                    }">{{ ` ${record.actual}` }}</a>
      </template>
    </Table>
    <div :style="{
              textAlign: 'center',
              height: '20px',
              lineHeight: '20px',
            }">
      <a v-if="loadingMore" @click="loadMore(offset + 1, 30)">加载更多</a>
      <a v-else>已经到底啦~</a>
    </div>
    <operateModalVue ref="operateModalVue" @handleQuery="getData" />
  </div>
</template>

<script>
import {
  Table,
  notification,
  Row as ARow,
  Col as ACol,
  Select as ASelect,
  Form as AForm,
  Tag as ATag,
} from "ant-design-vue";
import { reactive, toRefs, onMounted, getCurrentInstance, ref } from "vue";
import moment from "moment";
import { warnItems, warnTypesDict, warnItemsColorsDict } from "../../data";
import myRangePickerVue from "@/global/pubilc/myRangePicker.vue";
export default {
  components: {
    ACol,
    ARow,
    Table,
    ASelect,
    myRangePickerVue,
    AForm,
    AFormItem: AForm.Item,
    ATag,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const detailsVue = ref(null);
    let data = reactive({
      moment,
      columns: [
        {
          title: "序号",
          key: "index",
          width: 60,
          slots: { customRender: "index" },
          fixed: "left",
        },
        {
          title: "设备SN",
          dataIndex: "sn",
          width: 200,
        },
        {
          title: "所属商户组",
          dataIndex: "groupid",
          customRender: function ({ text }) {
            if (text == -1) {
              return "全部设备";
            }
            let showLabel = undefined;
            if (text) {
              showLabel = new Map(
                data.groupList.map((dict) => [dict.id, dict.name])
              ).get(Number(text));
            }
            return showLabel ? showLabel : "-";
          },
        },
        {
          title: "预警项",
          dataIndex: "items",
          slots: {
            customRender: "items",
          },
        },
        {
          title: "预警条件",
          dataIndex: "types",
          slots: {
            customRender: "types",
          },
        },
        {
          title: "实际值",
          dataIndex: "actual",
          slots: {
            customRender: "actual",
          },
        },
        {
          title: "接收预警邮箱",
          dataIndex: "emails",
        },
        {
          title: "创建时间",
          dataIndex: "Time",
          slots: {
            customRender: "createtime",
          },
        },
      ],
      dataSource: [],
      total: 0,
      loading: false,
      limit: 50,
      offset: 1,
      selectTimeout: null,
      group: 0,
      groupList: [],
      defaultTime: [moment().add(-7, "d"), moment()],
      items: "",
      labelCol: { span: 4 },
      wrapperCol: { span: 12 },
      loadingMore: true,
    });
    const refData = toRefs(data);

    const getData = () => {
      data.loadingMore = true;
      data.loading = true;
      proxy.$req
        .get("/v6/warning/log", {
          items: data.items ? data.items : "",
          startTime: data.startTime,
          endTime: data.endTime,
          limit: data.limit,
          offset: (data.offset - 1) * data.limit,
        })
        .then((response) => {
          data.loading = false;
          if (response.code) {
            notification["error"]({
              message: "获取设备列表失败",
              description: response.message,
            });
            return;
          }
          const result = JSON.parse(response.data);
          if (!result.length) {
            data.loadingMore = false;
          }
          result.forEach((element) => {
            data.dataSource.push(element);
          });
        });
    };
    onMounted(() => {
      // getData();
      getGroupList();
    });
    const getGroupList = () => {
      proxy.$req
        .get("/v6/deice/group", {
          limit: data.limit,
          offset: (data.offset - 1) * data.limit,
          sn: data.sn,
          group: data.group,
        })
        .then((response) => {
          if (response.code) {
            notification["error"]({
              message: "获取商户组信息失败",
              description: response.message,
            });
            return;
          }
          data.groupList = response.data;
        });
    };
    const searchData = () => {
      data.dataSource = [];
      data.offset = 1;
      data.limit = 50;
      getData();
    };
    const searchTime = (startTime, endTime) => {
      data.startTime = startTime;
      data.endTime = endTime;
      searchData();
    };
    const loadMore = (current, size) => {
      data.offset = current;
      data.limit = size;
      getData();
    };
    return {
      searchData,
      loadMore,
      warnItems,
      searchTime,
      getData,
      warnTypesDict,
      detailsVue,
      ...refData,
      warnItemsColorsDict,
    };
  },
};
</script>

<style>
.divserach {
  padding: 10px;
  float: left;
  position: relative;
}
</style>
