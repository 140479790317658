<template>
  <div>
    <Row>
      <div class="divserach">
        <span>商户组：</span>
        <Select style="width: 240px" v-model:value="group" show-search>
          <SelectOption :key="0">全部分组</SelectOption>
          <!-- groupList -->
          <SelectOption v-for="v in groupList" :key="v.id">
            {{ v.name }}
          </SelectOption>
        </Select>
      </div>
      <div class="divserach">
        <span>设备型号：</span>
        <Select style="width: 240px" v-model:value="types" show-search>
          <SelectOption :key="0">所有型号</SelectOption>
          <SelectOption v-for="v in typesList" :key="v.id">
            {{ v.name }}
          </SelectOption>
        </Select>
      </div>
      <!-- myRangePickerVue -->
      <div class="divserach">
        <myRangePickerVue @searchTime="searchTime" :defaultTime="defaultTime" :type="'second'" :disabledDateNum="3" />
      </div>
    </Row>
    <Row>
      <Col :span="24">
      <List :dataSource="dataSource" item-layout="vertical" :loading="loading">
        <template #renderItem="{ item }">
          <ListItem>
            <template #actions>
              <Space>
                <span> 上传时间</span>
                <span style="color: green">
                  {{
                    moment(parseInt(item.Time))
                      .utcOffset(480)
                      .format("YYYY-MM-DD HH:mm:ss")
                  }}
                </span>
              </Space>
            </template>
            <div style="background-color: white; padding: 10px;">
              {{ item }}
            </div>
          </ListItem>
        </template>
        <template #loadMore>
          <div :style="{
            textAlign: 'center',
            marginTop: '12px',
            height: '32px',
            lineHeight: '32px',
          }">
            <Spin v-if="loadingMore" />
            <Button v-else @click="loadMore" :disabled="loadingMoreOf">
              <span v-if="loadingMoreOf">没有更多数据</span>
              <span v-else>加载更多</span>
            </Button>
          </div>
        </template>
      </List>
      </Col>
    </Row>
  </div>
</template>

<script>
import moment from "moment";
import {
  Select,
  Row,
  Col,
  List,
  notification,
  Space,
  Spin,
  Button,
} from "ant-design-vue";
import { reactive, toRefs, onMounted, getCurrentInstance } from "vue";
import myRangePickerVue from "@/global/pubilc/myRangePicker.vue";

export default {
  components: {
    Select,
    Row,
    Col,
    SelectOption: Select.Option,
    myRangePickerVue,
    List,
    ListItem: List.Item,
    Space,
    Spin,
    Button,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const data = reactive({
      moment,
      typesList: [],
      groupList: [],
      group: 0,
      types: 0,
      defaultTime: [
        moment("00:00:00", "HH:mm:ss"),
        moment("23:59:59", "HH:mm:ss"),
      ],
      startTime: 0,
      endTime: 0,
      sn: "",
      limit: 99,
      offset: 0,
      dataSource: [],
      loading: true,
      loadingMore: false,
      loadingMoreOf: false,
    });
    onMounted(() => { });
    const searchTime = (startTime, endTime) => {
      data.startTime = startTime;
      data.endTime = endTime;
      data.limit = 99;
      data.offset = 0;
      getData();
    };
    const getData = () => {
      proxy.$req
        .get("/v6/distributed/abnormallist", {
          group: data.group,
          types: data.types,
          startTime: data.startTime,
          endTime: data.endTime,
          sn: data.sn,
          limit: data.limit,
          offset: data.offset,
        })
        .then((response) => {
          data.loadingMore = false;
          data.loading = false;
          if (response.code != 0) {
            notification["error"]({
              message: "获取设备列表失败",
              description: response.message,
            });
            return;
          }
          var info = JSON.parse(response.data);
          if (info.length == 0) {
            data.loadingMoreOf = true;
          }
          data.dataSource.push(...info);
        });
    };
    const loadMore = () => {
      data.loadingMore = true;
      data.loadingMoreOf = false;
      data.offset += data.limit;
      getData();
    };
    return { ...toRefs(data), searchTime, getData, loadMore };
  },
  props: {
    typesList: Array,
    groupList: Array,
  },
};
</script>

<style>
.asdasd {
  float: right;
  margin-right: 0px;
}
</style>