<template>
  <div class="infochild">
    <myRangePickerVue
      @searchTime="searchTime"
      :defaultTime="defaultTime"
      :type="'second'"
    />
    <a-spin :spinning="spinning">
      <div v-if="!show || spinning">
        <Empty
          :style="{ height: spinning && init ? 0 : '230px' }"
          description="暂无数据"
        />
      </div>
      <div v-show="show" :id="title"></div>
    </a-spin>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  getCurrentInstance,
  onMounted,
  onUnmounted,
} from "vue";
import myRangePickerVue from "./myRangePicker.vue";
import moment from "moment";
import { Chart } from "@antv/g2";
import { Empty, Spin as ASpin } from "ant-design-vue";

export default {
  components: {
    Empty,
    myRangePickerVue,
    ASpin,
  },
  setup(props) {
    const { proxy } = getCurrentInstance();
    let data = reactive({
      startTime: 0,
      endTime: 0,
      defaultTime: [props.startTime, moment()],
      info: [],
      chart: null,
      title: props.title,
      show: true,
      spinning: false,
      init: false,
    });
    const getData = () => {
      data.show = true;
      data.spinning = true;
      proxy.$req
        .get(props.url, {
          startTime: data.startTime,
          endTime: data.endTime,
          id: props.id,
        })
        .then((response) => {
          if (response.code != 0) {
            return;
          }
          var info = JSON.parse(response.data);
          data.info = info;
          if (!data.info.length) {
            data.show = false;
          }
          if (!data.chart) {
            data.init = true;
            newChart();
          } else {
            data.chart.changeData(data.info);
          }
        })
        .finally(() => {
          data.spinning = false;
        });
    };
    const newChart = () => {
      if (data.chart) {
        data.chart.clear(true);
      }
      const chart = new Chart({
        container: data.title,
        autoFit: true,
        height: 230,
        padding: [50, 0, 20, 40],
      });
      data.chart = chart;
      chart.data(data.info);
      chart.scale({
        _time: {
          alias: "日期",
          type: "time",
          mask: "YYYY-MM-DD HH:mm:ss",
        },
        _value: {
          max: props.maxValue ? props.maxValue : null,
          min: props.minValue ? props.minValue : null,
        },
      });

      chart.tooltip({
        showCrosshairs: true,
        // 鼠标上是否显示全部的数据
        shared: true,
      });

      chart
        .line()
        .shape("smooth")
        .position("_time*_value")
        .tooltip("_value", (Value) => {
          return {
            name: props.name,
            value: Value,
          };
        });
      chart.render();
    };
    const searchTime = (startTime, endTime) => {
      data.startTime = startTime;
      data.endTime = endTime;
      getData();
    };
    onMounted(() => {});
    onUnmounted(() => {
      if (data.chart) {
        data.chart.clear(true);
      }
    });
    return { ...toRefs(data), searchTime };
  },
  props: {
    id: Number,
    url: String,
    title: String,
    maxValue: Number,
    minValue: Number,
    startTime: Object,
    name: String,
  },
};
</script>

<style></style>
