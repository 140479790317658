<template>
  <a-card :title="title" :bordered="true" style="min-height: 350px">
    <a-spin :spinning="spinning">
      <div v-if="info.length == 0" style="height: 267px">
        <Empty description="暂无数据2" />
      </div>
      <div :id="title"></div>
    </a-spin>
  </a-card>
</template>

<script>
import {
  reactive,
  toRefs,
  getCurrentInstance,
  watch,
  onMounted,
  onUnmounted,
} from "vue";
import { Chart } from "@antv/g2";
import {
  Card as ACard,
  Empty,
  Spin as ASpin,
  notification,
} from "ant-design-vue";
export default {
  components: { ACard, Empty, ASpin },
  setup(props) {
    const { proxy } = getCurrentInstance();
    let data = reactive({
      info: [],
      chart: null,
      title: props.title,
      spinning: false,
      init: false,
    });
    const getData = () => {
      data.spinning = true;
      proxy.$req
        .get(props.url, {
          group: props.group,
          types: props.types,
        })
        .then((response) => {
          if (response.code != 0) {
            notification["error"]({
              message: "获取数据失败",
              description: response.message,
            });
            return;
          }
          response.data.forEach((element) => {
            element.value = Number(element.value);
          });
          data.info = response.data;

          if (data.chart) {
            data.chart.destroy();
            data.chart = null;
          }
          if (data.info == 0) {
            return;
          }

          data.info.sort(function (a, b) {
            if (a.label.includes(">")) {
              return 1;
            }
            if (b.label.includes(">")) {
              return -1;
            }
            if (a.label && b.label) {
              const sort1 = a.label.substring(0, a.label.indexOf("-"));
              const sort2 = b.label.substring(0, b.label.indexOf("-"));
              return sort1 - sort2;
            } else {
              return -1;
            }
          });

          newChart();
        })
        .finally(() => {
          data.spinning = false;
        });
    };
    const newChart = () => {
      if (data.init) {
        return;
      }
      const chart = new Chart({
        container: data.title,
        autoFit: true,
        height: 250,
      });
      data.chart = chart;
      chart.data(data.info);
      chart.scale({
        value: {
          min: 0,
          nice: true,
        },
      });

      chart.tooltip({
        showCrosshairs: true, // 展示 Tooltip 辅助线
        shared: true,
      });

      chart.line().position("label*value").label("value");
      chart.point().position("label*value");
      chart.render();
    };

    watch(
      () => props,
      () => {
        getData();
      },
      { deep: true }
    );
    onMounted(() => {
      getData();
    });
    onUnmounted(() => {
      if (data.chart) {
        data.chart.destroy();
      }
      data.init = true;
    });
    return { ...toRefs(data) };
  },
  props: {
    url: String,
    title: String,
    maxValue: Number,
    minValue: Number,
    group: Number,
    types: Number,
    color: String,
    refresh: Boolean,
  },
};
</script>
