<template>
  <Modal v-model:visible="visible" :width="600" okTtext="确认" cancelText="取消" title="商户组添加设备" @cancel="close"
    :maskClosable="false" :style="{
            top: '30%',
          }">
    <div class="addgroupdevice">
      <UploadDragger v-model:fileList="fileList" name="file" :multiple="false" :beforeUpload="beforeUpload"
        @change="handleChange" :disabled="disabled" :showUploadList="false" accept=".xlsx">
        <Spin :spinning="disabled">
          <p class="ant-upload-drag-icon">
            <InboxOutlined></InboxOutlined>
          </p>
          <p class="ant-upload-text">单击或拖动文件到该区域进行上传</p>
          <p class="ant-upload-hint" style="width: 550px">
            仅支持上传单个xlsx表格文件
          </p>
        </Spin>
      </UploadDragger>
      <a href="/添加设备模板.xlsx" style="text-decoration: underline">
        <div class="downdevicemobo">
          <ArrowDownOutlined />
          <span> 商户组添加设备模板.xlsx </span>
        </div>
      </a>
    </div>
    <Drawer title="设备添加结果" placement="right" v-model:visible="drawerShow" :width="350" @close="close">
      <RadioGroup v-model:value="radioKey" button-style="solid">
        <RadioButton style="width: 95px" :value="0">全部</RadioButton>
        <RadioButton style="width: 95px" :value="1">成功</RadioButton>
        <RadioButton style="width: 95px" :value="2">失败</RadioButton>
      </RadioGroup>

      <span v-for="(v, k) in drawerList" :key="k">
        <span v-show="radioKey == 0">
          <Alert :message="v.sn" :description="v.error" :type="v.error != '添加成功' ? 'error' : 'success'" show-icon />
        </span>
        <span v-show="radioKey == 1 && v.error == '添加成功'">
          <Alert :message="v.sn" :description="v.error" :type="v.error != '添加成功' ? 'error' : 'success'" show-icon />
        </span>
        <span v-show="radioKey == 2 && v.error != '添加成功'">
          <Alert :message="v.sn" :description="v.error" :type="v.error != '添加成功' ? 'error' : 'success'" show-icon />
        </span>
      </span>
    </Drawer>
  </Modal>
</template>

<script>
import {
  Modal,
  Upload,
  notification,
  Spin,
  Drawer,
  Alert,
  Radio,
} from "ant-design-vue";
import { reactive, toRefs, getCurrentInstance } from "vue";
import { InboxOutlined, ArrowDownOutlined } from "@ant-design/icons-vue";
import xlsx from "xlsx";
export default {
  components: {
    Modal,
    UploadDragger: Upload.Dragger,
    InboxOutlined,
    Spin,
    Drawer,
    Alert,
    RadioGroup: Radio.Group,
    RadioButton: Radio.Button,
    ArrowDownOutlined,
  },
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    let data = reactive({
      visible: true,
      fileList: [],
      disabled: false,
      drawerShow: false,
      drawerList: [],
      radioKey: 2,
    });
    const refData = toRefs(data);
    const close = () => {
      emit("my-emit");
    };
    const handleChange = (info) => {
      if (
        info.file.type !=
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        if (info.fileList.length > 1) {
          data.fileList = [info.fileList[0]];
        } else {
          data.fileList = [];
        }
        notification["error"]({
          message: "选择文件失败",
          description: "仅支持上传单个xlsx表格文件",
        });
        return;
      }

      if (info.fileList.length) {
        data.fileList = [info.file];
        parsingExcel(info.file);
      } else {
        data.fileList = [];
      }
    };
    const beforeUpload = () => {
      return false;
    };
    const retParsingExcel = () => {
      data.disabled = false;
    };
    const parsingExcel = async (file) => {
      data.disabled = true;
      const fileReader = new FileReader();
      fileReader.onload = async (ev) => {
        const result = ev.target.result;
        const workbook = xlsx.read(result, {
          type: "binary",
        }); // 读取数据
        const wsname = workbook.SheetNames[0]; // 取第一张表
        const ws = xlsx.utils.sheet_to_json(workbook.Sheets[wsname]);
        if (ws.length > 3000) {
          data.radioKey = 2;
          notification["error"]({
            message: "添加失败",
            description: "单次只能添加3000台设备",
          });
          return retParsingExcel();
        }
        data.drawerShow = true;
        data.drawerList = [];
        for (const v of ws) {
          let sn = v["SN"];
          var drawer = {
            sn: sn,
            error: "",
          };
          if (!sn) {
            data.radioKey = 2;
            notification["error"]({
              message: "添加失败",
              description: "表格错误",
            });
            return retParsingExcel();
          }
          if (sn.length <= 6) {
            drawer.error = "SN错误";
          } else {
            const postResult = await addgroupdevice(sn);
            drawer.error = postResult;
          }
          data.drawerList.unshift(drawer);
        }

        data.radioKey = 1;
        notification["success"]({
          message: "添加完成",
          description: "",
        });
        return retParsingExcel();
      };
      fileReader.readAsBinaryString(file);
    };
    const addgroupdevice = async (sn) => {
      const response = await proxy.$req.post("/v6/group/device", {
        ID: props.groupID,
        sn: sn,
      });
      if (response.code) {
        return response.message;
      }
      return "添加成功";
    };
    return {
      ...refData,
      close,
      handleChange,
      beforeUpload,
      parsingExcel,
    };
  },
  props: {
    groupID: Number,
  },
};
</script>
<style>
.downdevicemobo {
  border: rgb(151, 151, 148) dashed 1px;
  margin-top: 10px;
}
</style>
