
<template>
  <div>
    <div class="divserach">
      <span>商户组：</span>
      <Select style="width: 240px" v-model:value="group" show-search @change="changeSelect">
        <SelectOption :key="0">全部分组</SelectOption>
        <!-- groupList -->
        <SelectOption v-for="v in groupList" :key="v.id">
          {{ v.name }}
        </SelectOption>
      </Select>
    </div>
    <div class="divserach">
      <span>设备型号：</span>
      <Select style="width: 240px" v-model:value="types" show-search @change="changeSelect">
        <SelectOption :key="0">所有型号</SelectOption>
        <SelectOption v-for="v in typesList" :key="v.id">
          {{ v.name }}
        </SelectOption>
      </Select>
    </div>
    <div class="divserach">
      <span>设备SN：</span>
      <Input style="width: 240px" @change="changeSelect" allowClear v-model:value="sn" />
    </div>

    <div class="divserach" style="float: right">
      <Button type="primary" @click="exportSN">导出设备</Button>
    </div>
    <!-- 导出框 -->
    <div v-if="spinning"
      style="position: fixed; width: 100%; height: 100%; top: 0; left: 0; background: #000; z-index: 9999; background: rgb(214, 212, 212, 0.4);">
      <div style="margin-left: 50%; margin-top: 20%;">
        <Spin :spinning="spinning" tip="导出中...">
        </Spin>
      </div>
    </div>

    <Table :columns="columns" :rowKey="(record) => record.id" :pagination="false" :data-source="dataSource"
      :loading="loading" style="float: left; width: 100%" :scroll="{ x: 800 }">
      <template #index="{ index }">
        {{ index + 1 }}
      </template>
      <template #status="{ record, text }">
        <Space>
          <span>
            <Tag color="green" v-if="text == 1"> 在线 </Tag>
            <Tag color="darkgray" v-else> 离线 </Tag>
          </span>
          <Button @click="getDeviceStatus(record)" size="small" type="link" :loading="record.statusSearch">
            刷新
          </Button>
        </Space>
      </template>
      <template #operation="{ record }">
        <a @click="showDetailsDrawer(record)"> 详情 </a>
      </template>
    </Table>
    <Pagination :total="total" :showTotal="(total) => `一共 ${total} 台设备`" :pageSize="limit" show-quick-jumper
      :showSizeChanger="true" @change="paginationChange" @showSizeChange="showSizeChange" v-model:current="offset"
      :pageSizeOptions="['10', '20', '30', '50', '100']" style="float: right">
      <template #buildOptionText="props">
        <span>{{ props.value }}/页</span>
      </template>
    </Pagination>
    <detailsinfoVue :info="showInfo" v-if="showInfo != null" @my-emit="showInfo = null" />
  </div>
</template>

<script>
import moment from "moment";
import { reactive, toRefs, getCurrentInstance, onMounted } from "vue";
import {
  notification,
  Table,
  Pagination,
  Select,
  Input,
  Button,
  Tag,
  Space,
  Spin
} from "ant-design-vue";
import detailsinfoVue from "./detailsinfo.vue";

export default {
  components: {
    Table,
    Pagination,
    Select,
    SelectOption: Select.Option,
    Input,
    Button,
    Tag,
    detailsinfoVue, Space, Spin
  },
  setup(props) {
    const { proxy } = getCurrentInstance();
    let data = reactive({
      spinning: false,
      moment,
      dataSource: [],
      total: 0,
      loading: false,
      limit: 10,
      offset: 1,
      selectTimeout: null,
      sn: "",
      types: 0,
      typesList: [],
      group: 0,
      groupList: [],
      showInfo: null,
      columns: [
        {
          title: "序号",
          key: "index",
          width: 60,
          slots: { customRender: "index" },
          fixed: "left",
        },
        {
          title: "设备SN",
          dataIndex: "sn",
          width: 200,
        },
        {
          title: "设备型号",
          dataIndex: "devicetypesid",
          customRender: function ({ text }) {
            let info = props.typesList.find((v) => {
              return v.id == text;
            });
            return info ? info.name : "-";
          },
        },
        {
          title: "在线状态",
          dataIndex: "status",
          slots: {
            customRender: "status",
          },
        },
        {
          title: "所属商户组",
          dataIndex: "dispense",
          customRender: function ({ text }) {
            let info = props.groupList.find((v) => {
              return v.id == text;
            });
            return info ? info.name : "-";
          },
        },
        {
          title: "设备出厂日期",
          dataIndex: "updatetime",
          slots: {
            customRender: "updatetime",
          },
          customRender: ({ text }) => {
            return moment(parseInt(text))
              .utcOffset(480)
              .format("YYYY-MM-DD HH:mm:ss");
          },
        },
        {
          title: "操作",
          dataIndex: "operation",
          slots: {
            customRender: "operation",
          },
        },
      ],
    });
    onMounted(() => {
      getData();
    });
    const getData = () => {
      data.loading = true;
      proxy.$req
        .get("/v6/deice/list", {
          limit: data.limit,
          offset: (data.offset - 1) * data.limit,
          sn: data.sn,
          types: data.types,
          dispense: data.group,
        })
        .then((response) => {
          data.loading = false;
          if (response.code) {
            notification["error"]({
              message: "获取设备列表失败",
              description: response.message,
            });
            return;
          }
          data.dataSource = response.data;
          data.total = parseInt(response.total);
        });
    };
    const paginationChange = () => {
      getData();
    };
    const showSizeChange = (current, size) => {
      data.offset = 1;
      data.limit = size;
      getData();
    };
    const changeSelect = () => {
      if (data.selectTimeout) {
        clearTimeout(data.selectTimeout);
      }
      data.selectTimeout = setTimeout(() => {
        getData();
      }, 500);
    };
    const exportSN = async () => {
      data.spinning = true;
      var dataList = [];
      var count = 99;
      var limit = 999;
      var offset = 0;
      while (count > 0) {
        const response = await proxy.$req.get("/v6/deice/list", {
          limit: limit,
          offset: offset,
          sn: data.sn,
          types: data.types,
          dispense: data.group,
        });

        if (response.code) {
          notification["error"]({
            message: "获取设备列表失败",
            description: response.message,
          });
          data.spinning = false;
          return;
        }
        if (response.data.length == 0) {
          break;
        }
        dataList.push(...response.data);
        count--;
        offset += limit;
      }

      var snList = [];
      var groupMap = {};
      var typeMap = {};

      for (const v of dataList) {
        if (!typeMap[v.devicetypesid]) {
          let info = props.typesList.find((value) => {
            return value.id == v.devicetypesid;
          });
          typeMap[v.devicetypesid] = info ? info.name : "-";
        }

        if (!groupMap[v.dispense]) {
          let info = props.groupList.find((value) => {
            return value.id == v.dispense;
          });
          groupMap[v.dispense] = info ? info.name : "-";
        }
        snList.push({
          SN: v.sn,
          设备型号: typeMap[v.devicetypesid],
          商户组: groupMap[v.dispense],
          设备状态: v.status == 1 ? "在线" : "离线",
          出厂时间: moment(parseInt(v.updatetime))
            .utcOffset(480)
            .format("YYYY-MM-DD HH:mm:ss"),
        });
      }
      proxy.$req.exportExcel("设备列表", snList);
      data.spinning = false;
    };
    const showDetailsDrawer = (info) => {
      data.showInfo = info;
    };

    const getDeviceStatus = (info) => {
      info.statusSearch = true;
      proxy.$req.get("/v6/deice/status", {
        sn: info.sn
      }).then((response) => {
        info.statusSearch = false;
        if (response.code != 0) {
          notification["error"]({
            message: "获取设备列表失败",
            description: response.message,
          });
          return;
        }

        info.status = response.data;
      });
    };
    return {
      ...toRefs(data),
      paginationChange,
      showSizeChange,
      changeSelect,
      exportSN,
      showDetailsDrawer, getDeviceStatus
    };
  },
  props: {
    typesList: Array,
    groupList: Array,
  },
};
</script>

<style></style>