<template>
  <indexVue>
    <div class="divserach">
      <Button @click="addShow = true" type="primary">新增</Button>
    </div>
    <div class="divserach" v-show="false">
      <span>状态：</span>
      <Select
        style="width: 240px"
        v-model:value="status"
        show-search
        @change="changeSelect"
      >
        <SelectOption :key="0">全部</SelectOption>
        <SelectOption :key="1"
          ><span style="color: blue">未审核</span>
        </SelectOption>
        <SelectOption :key="2">
          <span style="color: green">审核成功</span>
        </SelectOption>
        <SelectOption :key="3">
          <span style="color: red">审核失败</span>
        </SelectOption>
      </Select>
    </div>
    <div class="divserach">
      <span>检索：</span>
      <Input
        style="width: 240px"
        @change="changeSelect"
        allowClear
        v-model:value="fuzzy"
      />
    </div>
    <Table
      :columns="columns"
      :rowKey="(record) => record.ID"
      :pagination="false"
      :data-source="dataSource"
      :loading="loading"
      style="float: left; width: 100%"
      size="small"
    >
      <template #index="{ index }">
        {{ index + (offset - 1) * limit + 1 }}
      </template>
      <template #createtime="{ text }">
        {{
          moment(parseInt(text)).utcOffset(480).format("YYYY-MM-DD HH:mm:ss")
        }}
      </template>
      <template #operation="{ record }">
        <Space>
          <Button type="link" @click="down(record.url)"> 下载 </Button>
          <Popconfirm title="是否确认删除?" @confirm="del(record.ID)">
            <Button type="danger"> 删除 </Button>
          </Popconfirm>
        </Space>
      </template>
    </Table>
    <Pagination
      :total="total"
      :showTotal="(total) => `总数 ${total} `"
      :pageSize="limit"
      show-quick-jumper
      :showSizeChanger="true"
      @change="paginationChange"
      @showSizeChange="showSizeChange"
      v-model:current="offset"
      :pageSizeOptions="['10', '20', '30', '50', '100']"
      style="float: right"
    >
      <template #buildOptionText="props">
        <span>{{ props.value }}/页</span>
      </template>
    </Pagination>
    <addVue v-if="addShow" @my-emit="cliidClose"
  /></indexVue>
</template>

<script>
import {
  Table,
  Pagination,
  Select,
  Input,
  Space,
  Button,
  Popconfirm,
} from "ant-design-vue";
import { reactive, toRefs, onMounted, getCurrentInstance } from "vue";
import moment from "moment";
import indexVue from "../../components/menu/index.vue";
import addVue from "./add.vue";
import que from "./que";

export default {
  components: {
    Table,
    Pagination,
    Select,
    SelectOption: Select.Option,
    Input,
    Space,
    Button,
    indexVue,
    addVue,
    Popconfirm,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    let data = reactive({
      moment,
      columns: [
        {
          title: "序号",
          key: "index",
          width: 70,
          slots: { customRender: "index" },
          fixed: "left",
        },
        {
          title: "名称",
          dataIndex: "name",
        },
        {
          title: "版本",
          dataIndex: "versionname",
        },
        {
          title: "备注",
          dataIndex: "details",
        },
        {
          title: "创建时间",
          dataIndex: "createtime",
          slots: {
            customRender: "createtime",
          },
        },
        {
          title: "操作",
          dataIndex: "operation",
          slots: {
            customRender: "operation",
          },
        },
      ],
      dataSource: [],
      total: 0,
      loading: false,
      limit: 10,
      offset: 1,
      selectTimeout: null,
      status: 0,
      fuzzy: "",
      addShow: false,
      detailsShow: false,
      info: {},
      userid: 0,
    });
    const refData = toRefs(data);
    const getData = async () => {
      data.loading = true;
      const response = await que.get(proxy, {
        limit: data.limit,
        offset: (data.offset - 1) * data.limit,
        fuzzy: data.fuzzy,
      });
      data.loading = false;
      if (response) {
        data.dataSource = response.data;
        data.total = parseInt(response.total);
      }
    };
    onMounted(async () => {
      data.loading = true;
      getData();
    });
    const paginationChange = () => {
      getData();
    };
    const showSizeChange = (current, size) => {
      data.offset = 1;
      data.limit = size;
      getData();
    };
    const changeSelect = () => {
      if (data.selectTimeout) {
        clearTimeout(data.selectTimeout);
      }
      data.selectTimeout = setTimeout(() => {
        getData();
      }, 500);
    };
    const del = async (ID) => {
      await que.del(proxy, ID);
      getData();
    };
    const cliidClose = () => {
      data.addShow = false;
      getData();
    };
    const down = (url) => {
      location.href = url;
    };
    return {
      ...refData,
      paginationChange,
      showSizeChange,
      changeSelect,
      del,
      cliidClose,
      down,
    };
  },
};
</script>

<style>
</style>