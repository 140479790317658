<template>
  <div class="home">
    <div class="logindiv">
      <div class="logintitle">智能设备管理平台</div>
      <Form :rules="rules" ref="formRef" :model="form" class="loginform" @finish="finish" autocomplete="off">
        <formItem required has-feedback name="name">
          <Input class="logininput" size="large" :maxlength="30" allowClear placeholder="请输入登录账号"
            v-model:value="form.name" />
        </formItem>
        <formItem required has-feedback name="password">
          <Password class="logininput" size="large" :maxlength="32" allowClear placeholder="请输入登录密码"
            v-model:value="form.password" />
        </formItem>
        <formItem>
          <Button class="logininput" size="large" type="primary" html-type="submit" :disabled="!result">
            登录
          </Button>
          <a style="float: right" href="#/update">修改密码</a>
        </formItem>
      </Form>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, ref, reactive, toRefs, getCurrentInstance } from "vue";
import router from "@/router";
import { Input, Form, notification, Button } from "ant-design-vue";
import md5 from "js-md5";

export default {
  name: "index",
  components: {
    Input,
    Form,
    Password: Input.Password,
    Button,
    formItem: Form.Item,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    let data = reactive({
      result: true,
      spinning: true,
    });
    const form = ref({
      name: "",
      password: "",
    });
    const formRef = ref();
    const validator = (rule, value) => {
      switch (rule.field) {
        case "name":
          if (!value) {
            return Promise.reject("请输入正确的登录账号");
          }
          if (value.length < 4) {
            return Promise.reject("请输入正确的登录账号");
          }
          if (!proxy.$req.checkEmail(value)) {
            return Promise.reject("请输入正确的登录账号");
          }
          break;
        case "password":
          if (!value) {
            return Promise.reject("请输入正确的登录密码");
          }
          if (value.length < 4) {
            return Promise.reject("请输入正确的登录密码");
          }
          break;
      }
      return Promise.reject();
    };
    const rules = {
      name: [{ validator: validator, trigger: "change" }],
      password: [{ validator: validator, trigger: "change" }],
    };
    const finish = (Event) => {
      let now = new Date().getTime();
      proxy.$req
        .post("/v6/login", {
          name: Event.name,
          password: md5(Event.password + now),
          time: now,
        })
        .then((response) => {
          if (response.code) {
            notification["error"]({
              message: "登录失败",
              description: response.message,
            });
            return;
          }
          proxy.$req.setCookie(response.data);
          router.push("/deviceInfo/deviceMonitor");
        });
    };
    const resetForm = () => {
      // 调用此方法会清空全部数据
      formRef.value.resetFields();
    };
    onMounted(() => { });
    const refData = toRefs(data);
    return {
      finish,
      resetForm,
      validator,
      form,
      rules,
      formRef,
      ...refData,
    };
  },
};
</script>

<style scoped>
.home {
  height: 100%;
  width: 100%;
  text-align: center;
}

.logindiv {
  height: 300px;
  width: 360px;
  margin: 0 auto;
  position: relative;
  top: 20%;
}

.logintitle {
  width: 100%;
  height: 100px;
  line-height: 100px;
  font-size: 25px;
}

.logininput {
  width: 360px;
}
</style>
