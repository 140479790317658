<template>
    <Card style="background-color: whitesmoke;">
        <Card title="指令">
            <div v-for=" v, k in InstructionList" :key="k" style="margin-left: 10px; float: left;">
                <Popconfirm @confirm="confirm(v)">
                    <template #title>
                        <span>是否确认发送该</span>
                        <span style="color: red;">
                            ({{ v.name }})
                        </span>
                        <span>指令?</span>
                    </template>
                    <Button type="primary">
                        {{ v.name }}
                    </Button>
                </Popconfirm>
            </div>
        </Card>
        <Table :columns="columns" :rowKey="(record) => record.ID" :pagination="false" :data-source="dataSource"
            :loading="loading" style="float: left; width: 100%; margin-top: 10px;" size="small">
            <template #index="{ index }">
                {{ index + (offset - 1) * limit + 1 }}
            </template>
            <template #status="{ text }">
                <span v-if="text == 1" style="color: green">下行成功</span>
                <span v-else-if="text == 2" style="color: red">下行失败</span>
                <span v-else-if="text == 3" style="color: green">执行成功</span>
                <span v-else style="color: red">执行失败</span>
            </template>
            <template #createtime="{ text }">
                {{
                    moment(parseInt(text)).utcOffset(480).format("YYYY-MM-DD HH:mm:ss")
                }}
            </template>
            <template #operation="{ record }">
                <Space>
                    <Button type="primary" @click="setInstruction(record)">
                        详情
                    </Button>
                </Space>
            </template>
        </Table>
        <Pagination :total="total" :showTotal="(total) => `总共 ${total} `" :pageSize="limit" show-quick-jumper
            :showSizeChanger="true" @change="paginationChange" @showSizeChange="showSizeChange" v-model:current="offset"
            :pageSizeOptions="['10', '20']" style="float: right;">
            <template #buildOptionText="props">
                <span>{{ props.value }}/页</span>
            </template>
        </Pagination>
        <Modal title="下行详情" placement="right" v-model:visible="downShow" :width="450" @close="chiildClose">
            <Descriptions :column="1" :bordered="true">
                <DescriptionsItem label="设备SN">
                    {{ instruction.devicename }}
                </DescriptionsItem>
                <DescriptionsItem label="指令名称">
                    {{ instruction.name }}
                </DescriptionsItem>
                <DescriptionsItem label="设备SN">
                    {{ instruction.devicename }}
                </DescriptionsItem>
                <DescriptionsItem label="发送状态">
                    <span v-if="instruction.status == 1" style="color: green">下行成功</span>
                    <span v-else-if="instruction.status == 2" style="color: red">下行失败</span>
                    <span v-else-if="instruction.status == 3" style="color: green">执行成功</span>
                    <span v-else style="color: red">执行失败</span>
                </DescriptionsItem>
                <DescriptionsItem label="发送时间">
                    {{
                        moment(parseInt(instruction.createtime)).utcOffset(480).format("YYYY-MM-DD HH:mm:ss")
                    }}
                </DescriptionsItem>
                <DescriptionsItem label="结果">
                    <span v-if="instruction.result.substring(0, 4) == 'http'">
                        <a :href="instruction.result" target="_blank">{{ instruction.result }}</a>
                    </span>
                    <span v-else>{{ instruction.result }}</span>
                </DescriptionsItem>
            </Descriptions>
        </Modal>
    </Card>
</template>

<script>
import { reactive, toRefs, getCurrentInstance, onMounted, onActivated, onUnmounted, } from "vue";
import { Table, Pagination, Space, Button, Card, Popconfirm, Modal, Descriptions } from "ant-design-vue";
import Que from "./que";
import moment from "moment";

export default {
    components: { Table, Pagination, Space, Button, Card, Popconfirm, Modal, Descriptions, DescriptionsItem: Descriptions.Item },
    setup(props) {
        const { proxy } = getCurrentInstance();
        const state = reactive({
            moment,
            columns: [
                {
                    title: "序号",
                    key: "index",
                    width: 70,
                    slots: { customRender: "index" },
                },
                {
                    title: "SN",
                    dataIndex: "devicename",
                },
                {
                    title: "指令名称",
                    dataIndex: "name",
                },
                {
                    title: "发送状态",
                    dataIndex: "status",
                    slots: { customRender: "status" },
                },
                {
                    title: "结果",
                    dataIndex: "result",
                    slots: { customRender: "result" },
                },
                {
                    title: "发送时间",
                    dataIndex: "createtime",
                    slots: { customRender: "createtime" },
                },
                {
                    title: "操作",
                    dataIndex: "operation",
                    slots: {
                        customRender: "operation",
                    },
                },
            ],
            dataSource: [],
            total: 0,
            loading: false,
            limit: 10,
            offset: 1,
            fuzzy: "",
            downShow: false,
            instruction: {},
            activeKey: 1,
            selectTimeout: null,
            InstructionList: [],
            timer: null,
        });
        onActivated(() => {

        })
        onUnmounted(() => {
            if (state.timer == null) {
                return;
            }
            clearInterval(state.timer)
        })
        onMounted(() => {
            getData();
            getInstruction();
            setTime();
        });
        const setTime = () => {
            if (state.timer != null) {
                return;
            }
            state.timer = setInterval(() => { getData() }, 3000)
        }
        const getData = async () => {
            state.loading = true;
            const response = await Que.getDown(proxy, {
                limit: state.limit,
                offset: (state.offset - 1) * state.limit,
                fuzzy: props.info.sn,
            });
            state.loading = false;

            if (response) {
                state.dataSource = response.data;
                state.total = parseInt(response.total);
            }
        };
        const paginationChange = () => {
            getData();
        };
        const showSizeChange = (current, size) => {
            state.offset = 1;
            state.limit = size;
            getData();
        };
        const chiildClose = () => {
            state.downShow = false;
        };
        const setInstruction = (info) => {
            state.instruction = info;
            state.downShow = true;
        };
        const changeSelect = () => {
            if (state.selectTimeout) {
                clearTimeout(state.selectTimeout);
            }
            state.selectTimeout = setTimeout(() => {
                getData();
            }, 500);
        };

        const getInstruction = async () => {
            state.loading = true;
            const response = await Que.get(proxy, {
                limit: 999,
                offset: 0,
            });
            state.loading = false;
            if (response) {
                state.InstructionList = response.data;
            }
        };
        const confirm = async (instruction) => {
            state.loading = true;
            var resp = await Que.downPost(proxy, {
                instructionID: instruction.ID,
                msg: "",
                sn: [props.info.sn],
            });
            state.loading = false;
            if (resp) {
                getData();
                return;
            }
            return;
        }
        return {
            ...toRefs(state),
            paginationChange,
            showSizeChange,
            chiildClose,
            setInstruction,
            changeSelect,
            getInstruction,
            getData,
            confirm,
        };
    },
    props: {
        info: Object,
    },
};
</script>

<style lang="scss" scoped></style>