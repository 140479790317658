<template>
  <a-card :bordered="true">
    <a-spin :spinning="spinning">
      <div v-if="info.length == 0" style="height: 280px">
        <Empty description="暂无数据2" />
      </div>
      <div :id="title"></div>
    </a-spin>
  </a-card>
</template>

<script>
import {
  reactive,
  toRefs,
  getCurrentInstance,
  watch,
  onMounted,
  onUnmounted,
} from "vue";
import { Chart } from "@antv/g2";
import {
  Card as ACard,
  Empty,
  Spin as ASpin,
  notification,
} from "ant-design-vue";
export default {
  components: { ACard, Empty, ASpin },
  setup(props) {
    const { proxy } = getCurrentInstance();
    let data = reactive({
      info: [],
      chart: null,
      title: props.title,
      startTime: props.startTime,
      endTime: props.endTime,
      spinning: false,
      init: false,
    });
    const getData = () => {
      data.spinning = true;
      proxy.$req
        .get(props.url, {
          group: props.group,
          types: props.types,
          startTime: props.startTime,
          endTime: props.endTime,
        })
        .then((response) => {
          if (response.code != 0) {
            notification["error"]({
              message: "获取数据失败",
              description: response.message,
            });
            return;
          }

          if (data.chart) {
            data.chart.destroy();
            data.chart = null;
          }
          data.info = JSON.parse(response.data);
          data.info.sort(function (a, b) {
            if (a.label.includes(">")) {
              return 1;
            }
            if (b.label.includes(">")) {
              return -1;
            }
            if (a.label && b.label) {
              const sort1 = a.label.substring(0, a.label.indexOf("-"));
              const sort2 = b.label.substring(0, b.label.indexOf("-"));
              return sort1 - sort2;
            } else {
              return -1;
            }
          });
          if (!data.info.length) {
            return;
          }
          newChart();
        })
        .finally(() => {
          data.spinning = false;
        });
    };
    onUnmounted(() => {
      data.title = null;
    });
    const newChart = () => {
      if (data.init) {
        return;
      }
      const chart = new Chart({
        container: props.title,
        autoFit: true,
        height: 280,
        padding: [50, 0, 50, 30],
      });
      data.chart = chart;
      chart.data(data.info);
      chart.scale("value", {
        nice: true,
      });
      chart.scale("label", {
        nice: true,
      });
      chart.axis("value", {
        label: {
          formatter: (val) => {
            return val;
          },
        },
      });
      chart.axis("label", {
        tickLine: null,
      });

      chart.tooltip({
        showTitle: true,
        showMarkers: false,
      });
      chart.interaction("active-region");
      chart
        .interval({
          background: {
            style: {
              radius: 8,
            },
          },
        })
        .position("label*value")
        .color("label", () => {
          return props.color;
        })
        .label("value", {
          content: (originData) => {
            return originData.value + "台";
          },
        })
        .size("label", () => {
          let width = data.chart.width;
          const maxWidth = Math.min(50, 0.1 * width);
          return maxWidth;
        })
        .tooltip("label*value", (label, value) => {
          return {
            name: label,
            value: value + " 台",
          };
        });

      chart.render();
    };

    watch(
      () => props,
      () => {
        getData();
      },
      { deep: true }
    );

    onMounted(() => {
      getData();
    });
    onUnmounted(() => {
      if (data.chart) {
        data.chart.destroy();
      }
      data.init = true;
    });
    return { ...toRefs(data) };
  },
  props: {
    url: String,
    title: String,
    group: Number,
    types: Number,
    color: String,
    startTime: Number,
    endTime: Number,
    refresh: Boolean,
  },
};
</script>
