<template>
	<List
		itemLayout="horizontal"
		:dataSource="data"
		size="large"
		style="background: #fff; padding: 10px"
	>
		<template #renderItem="{ item }">
			<ListItem>
				<template #actions v-if="item.title == '账户密码'">
					<Button @click="click" type="dashed">修改</Button>
				</template>
				<ListItemMeta :description="item.description">
					<template #title>
						<a>{{ item.title }}</a>
					</template>
				</ListItemMeta>
			</ListItem>
		</template>
	</List>
</template>

<script>
	import { ref, onMounted } from "vue";
	import { List, Button } from "ant-design-vue";
	import { ListItemMeta } from "ant-design-vue/lib/list";
	import publics from "@/components/menu/public";
	import router from "@/router";
	export default {
		components: {
			List,
			ListItem: List.Item,
			ListItemMeta: ListItemMeta,
			Button
		},
		setup() {
			var data = ref([]);
			onMounted(async () => {
				const userinfo = await publics.getUserInfo();
				getDataSource(userinfo);
			});
			const getDataSource = (userinfo) => {
				var dataSource = [
					{
						title: "登录账户",
						description: userinfo.alipay
					},
					{
						title: "账户密码",
						description: "*********"
					},
					{
						title: "账户名称",
						description: userinfo.name
					},
					{
						title: "联系电话",
						description: userinfo.phone
					},
					{
						title: "联系邮箱",
						description: userinfo.emails
					},
					{
						title: "地址",
						description: userinfo.addr
					}
				];
				data.value = dataSource;
			};
			const click = () => {
				router.push("/userinfo/update");
			};
			return { data, click };
		}
	};
</script>

<style></style>
