<template>
  <div>
    <Card>
      <Row>
        <Col :span="12">
          <storageChartVue
            :externalValue="{
              min: 0, //最小值
              max: 100, // 最大值
              warn: 80, //预警值
              police: 90, //报警值
              unit: '℃',
              total: deviceDetailsInfo['总存储'],
              remain: deviceDetailsInfo['剩余存储'],
            }"
          />
          <storageDetailsVue
            :externalValue="{
              remain: deviceDetailsInfo['剩余存储'],
              data: deviceDetailsInfo['数据分区总大小'],
              datauser: deviceDetailsInfo['数据分区使用大小'],
              storage: deviceDetailsInfo['系统分区总大小'],
              storageuser: deviceDetailsInfo['系统分区使用大小'],
            }"
          />
        </Col>
        <Col :span="12">
          <memoryChartVue
            :externalValue="{
              min: 0, //最小值
              max: 100, // 最大值
              warn: 80, //预警值
              police: 90, //报警值
              value: deviceDetailsInfo['内存使用率'], //实际值
              unit: '℃',
              title: deviceDetailsInfo['内存使用率'],
              total: deviceDetailsInfo['总内存'],
              remain: deviceDetailsInfo['剩余内存'],
            }"
          />
          <memoryDetailsVue
            :externalValue="{
              remain: deviceDetailsInfo['CPU温度'],
              data: deviceDetailsInfo['CPU使用率'],
              datauser: deviceDetailsInfo['数据分区使用大小'],
              storage: deviceDetailsInfo['FLASH使用寿命'],
              storageuser: deviceDetailsInfo['数据分区总大小'],
            }"
          />
        </Col>
      </Row>
    </Card>
    <div class="exhaustiveifo">
      <Descriptions :column="3" :bordered="false">
        <DescriptionsItem label="CPU类型">
          {{ deviceDetailsInfo["CPU类型"] }}
        </DescriptionsItem>
        <DescriptionsItem label="ICCID">
          {{ deviceDetailsInfo["ICCID"] }}
        </DescriptionsItem>
        <DescriptionsItem label="MTKLogger开关">
          {{ deviceDetailsInfo["MTKLogger开关"] }}
        </DescriptionsItem>
        <DescriptionsItem label="MTKLogger自动上报">
          {{ deviceDetailsInfo["MTKLogger自动上报"] }}
        </DescriptionsItem>
        <DescriptionsItem label="内网IP地址">
          {{ deviceDetailsInfo["内网IP地址"] }}
        </DescriptionsItem>
        <DescriptionsItem label="刷脸次数">
          {{ deviceDetailsInfo["刷脸次数"] }}
        </DescriptionsItem>
        <DescriptionsItem label="单月移动网络流量">
          {{ deviceDetailsInfo["单月移动网络流量"] }}
        </DescriptionsItem>
        <DescriptionsItem label="设备日使用流量分布">
          {{ deviceDetailsInfo["设备日使用流量分布"] }}
        </DescriptionsItem>
        <DescriptionsItem label="单日设备重启次数">
          {{ deviceDetailsInfo["单日设备重启次数"] }}
        </DescriptionsItem>
        <DescriptionsItem label="连续运行时长">
          {{ deviceDetailsInfo["连续运行时长"] }}
        </DescriptionsItem>
        <DescriptionsItem label="单日运行时长">
          {{ deviceDetailsInfo["单日运行时长"] }}
        </DescriptionsItem>
        <DescriptionsItem label="异常上报开关">
          {{ deviceDetailsInfo["异常上报开关"] }}
        </DescriptionsItem>
        <DescriptionsItem label="扫码次数">
          {{ deviceDetailsInfo["扫码次数"] }}
        </DescriptionsItem>
        <DescriptionsItem label="网络信号强度">
          {{ deviceDetailsInfo["网络信号强度"] }}
        </DescriptionsItem>
        <DescriptionsItem label="网络连接方式">
          {{ deviceDetailsInfo["网络连接方式"] }}
        </DescriptionsItem>
        <DescriptionsItem label="蓝牙地址">
          {{ deviceDetailsInfo["蓝牙地址"] }}
        </DescriptionsItem>
        <DescriptionsItem label="基带版本" :span="2">
          {{ deviceDetailsInfo["基带版本"] }}
        </DescriptionsItem>
        <DescriptionsItem
          label="外设信息"
          :span="3"
          v-if="deviceDetailsInfo['外设信息']"
        >
          <span
            v-for="(v, k) in JSON.parse(deviceDetailsInfo['外设信息'])"
            :key="k"
          >
            <strong style="color: black">{{ k }}:</strong>
            <span>
              <Tag v-for="v1 in v" :key="v1" color="green">
                {{ v1 }}
              </Tag>
            </span>
          </span>
        </DescriptionsItem>
      </Descriptions>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive, toRefs } from "vue";
import {
  notification,
  Card,
  Row,
  Col,
  Descriptions,
  Tag,
} from "ant-design-vue";
import storageChartVue from "./exhaustive/storageChart.vue";
import storageDetailsVue from "./exhaustive/storageDetails.vue";
import memoryChartVue from "./exhaustive/memoryChart.vue";
import memoryDetailsVue from "./exhaustive/memoryDetails.vue";

export default {
  components: {
    Card,
    Row,
    Col,
    Tag,
    storageChartVue,
    storageDetailsVue,
    memoryChartVue,
    memoryDetailsVue,
    Descriptions,
    DescriptionsItem: Descriptions.Item,
  },
  setup(props) {
    const { proxy } = getCurrentInstance();
    const data = reactive({
      deviceDetailsInfo: {},
    });
    onMounted(() => {
      getDeviceDetails();
    });
    const getDeviceDetails = () => {
      proxy.$req
        .get("/v6/deice/details", {
          id: props.info.id,
        })
        .then((response) => {
          if (response.code) {
            notification["error"]({
              message: "获取设备详情失败",
              description: response.message,
            });
            return;
          }
          const result = JSON.parse(response.data);
          for (const key in result) {
            for (const iterator of result[key]) {
              data.deviceDetailsInfo[key] = iterator.Value;
            }
          }
        });
    };
    return { ...toRefs(data), getDeviceDetails };
  },
  props: {
    info: Object,
  },
};
</script>

<style>
.exhaustiveifo {
  padding: 10px;
  border: 1px solid rgb(231, 231, 231);
}
</style>