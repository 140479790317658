<template>
  <a-card :title="title" :bordered="true" style="min-height: 350px">
    <a-month-picker
      :allowClear="false"
      @change="searchTime"
      v-model:value="defaultTime"
    />
    <a-row style="margin-top: 8px">
      <a-col :md="14" :xl="16" :xxl="18"
        ><barChart
          url="/v6/distributed/network/flow/month"
          title="设备月流量使用分布"
          :group="group"
          :types="types"
          color="#063d8a"
          :startTime="startTime"
          :endTime="endTime"
          :refresh="refresh"
        />
      </a-col>
      <a-col :md="10" :xl="8" :xxl="6"
        ><rankTableVue
          url="/v6/distributed/network/flow/month/rank"
          title="设备当月流量排行"
          :group="group"
          :types="types"
          color="#063d8a"
          :startTime="startTime"
          :endTime="endTime"
          :refresh="refresh"
      /></a-col>
    </a-row>
  </a-card>
</template>

<script>
import {
  Row as ARow,
  Col as ACol,
  Card as ACard,
  DatePicker,
} from "ant-design-vue";
import { reactive, toRefs, ref } from "vue";
import rankTableVue from "./rankTable.vue";
import moment from "moment";
import barChart from "./barChart.vue";
export default {
  components: {
    ARow,
    ACol,
    ACard,
    barChart,
    rankTableVue,
    AMonthPicker: DatePicker.MonthPicker,
  },
  setup() {
    const detailsVue = ref(null);
    let data = reactive({
      defaultTime: moment(),
      startTime: moment().startOf("month").unix(),
      endTime: moment().endOf("month").unix(),
      group: 0,
      types: 0,
    });
    const refData = toRefs(data);
    const searchTime = (_, time) => {
      data.startTime = moment(time).startOf("month").unix();
      data.endTime = moment(time).endOf("month").unix();
    };
    return {
      searchTime,
      moment,
      detailsVue,
      ...refData,
    };
  },
  props: {
    title: String,
    group: Number,
    types: Number,
    refresh: Boolean,
  },
};
</script>

<style scoped></style>
