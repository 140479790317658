<template>
  <a-card :title="title" :bordered="true">
    <a-spin :spinning="spinning">
      <div v-if="total == 0" style="height: 267px">
        <Empty description="暂无数据" />
      </div>
      <div :id="title"></div> </a-spin
  ></a-card>
</template>

<script>
import {
  reactive,
  toRefs,
  getCurrentInstance,
  watch,
  onMounted,
  onUnmounted,
} from "vue";
import { Chart } from "@antv/g2";
import {
  Card as ACard,
  Empty,
  Spin as ASpin,
  notification,
} from "ant-design-vue";
import { deviceStatusDict } from "../../data";
export default {
  components: { ACard, Empty, ASpin },
  setup(props) {
    const { proxy } = getCurrentInstance();
    let data = reactive({
      info: [],
      chart: null,
      title: props.title,
      spinning: false,
      init: false,
      total: 0,
    });
    const getData = () => {
      data.spinning = true;
      proxy.$req
        .get(props.url, {
          group: props.group,
          types: props.types,
        })
        .then((response) => {
          if (response.code != 0) {
            notification["error"]({
              message: "获取数据失败",
              description: response.message,
            });
            return;
          }
          data.info = response.data;
          data.total = 0;
          data.info.forEach((d) => {
            d.label = d.status == 1 ? "在线" : "离线";
            data.total += d.total;
          });

          if (data.chart) {
            data.chart.destroy();
            data.chart = null;
          }
          if (data.total == 0) {
            return;
          }
          newChart();
        })
        .finally(() => {
          data.spinning = false;
        });
    };
    const newChart = () => {
      if (data.init) {
        return;
      }
      const chart = new Chart({
        container: data.title,
        autoFit: true,
        height: 267,
      });
      data.chart = chart;
      chart.data(data.info);
      chart.coordinate("theta", {
        radius: 0.65,
        innerRadius: 0.6,
      });
      chart.tooltip({
        showTitle: true,
        showMarkers: false,
      });

      chart
        .interval()
        .adjust("stack")
        .position("total")
        .color("label", (label) => {
          return label == "在线" ? "green" : "rgb(233,233,233)";
        })
        .label("label", () => {
          return {
            autoRotate: true,
            style: {
              fill: "black",
              fontSize: 12,
              shadowBlur: 2,
            },
            offset: 20,
            content: (obj) => {
              return deviceStatusDict.get(obj.status) + obj.total + "台";
            },
          };
        })
        .tooltip("label*total", (label, total) => {
          return {
            name: label,
            value: total + " 台",
          };
        });

      // 辅助文本
      chart
        .annotation()
        .text({
          position: ["50%", "50%"],
          content: "全部",
          style: {
            fontSize: 14,
            fill: "black",
            textAlign: "center",
          },
          offsetY: -20,
        })
        .text({
          position: ["50%", "50%"],
          content: data.total,
          style: {
            fontSize: 20,
            fill: "red",
            textAlign: "center",
          },
          offsetY: 5,
        });

      chart.interaction("element-single-selected");

      chart.render();
    };

    watch(
      () => props,
      () => {
        getData();
      },
      { deep: true }
    );
    onMounted(() => {
      getData();
    });
    onUnmounted(() => {
      if (data.chart) {
        data.chart.destroy();
      }
      data.init = true;
    });
    return { ...toRefs(data) };
  },
  props: {
    url: String,
    title: String,
    group: Number,
    types: Number,
  },
};
</script>
