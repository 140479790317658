<template>
  <div style="overflow: auto; height: 100%">
    <Row>
      <div class="divserach">
        <span>商户组：</span>
        <Select style="width: 240px" v-model:value="group" show-search>
          <SelectOption :key="0">全部分组</SelectOption>
          <!-- groupList -->
          <SelectOption v-for="v in groupList" :key="v.id">
            {{ v.name }}
          </SelectOption>
        </Select>
      </div>
      <div class="divserach">
        <span>设备型号：</span>
        <Select style="width: 240px" v-model:value="types" show-search>
          <SelectOption :key="0">所有型号</SelectOption>
          <SelectOption v-for="v in typesList" :key="v.id">
            {{ v.name }}
          </SelectOption>
        </Select>
      </div>
    </Row>
    <Row>
      <Col :span="12">
      <onlinestatusVue url="/v6/distributed/line" title="设备在线离线分布" :group="group" :types="types" />
      </Col>
      <Col :span="12">
      <barChartVue url="/v6/distributed/storge" title="设备存储分布" :group="group" :types="types" color="#063d8a" type="signal"
        :refresh="true" />
      </Col>
    </Row>
    <Row>
      <Col :span="24">
      <lineChartVue url="/v6/distributed/status" title="设备离线时长分布" :group="group" :types="types" color="rgb(136 222 138)"
        :refresh="true" />
      </Col>
    </Row>
  </div>
</template>

<script>
import moment from "moment";
import { Select, Row, Col } from "ant-design-vue";
import { reactive, toRefs, onMounted } from "vue";
import onlinestatusVue from "./onlinestatus.vue";
import barChartVue from "./barChart.vue";
import lineChartVue from "./lineChart.vue";

export default {
  components: {
    Select,
    Row,
    Col,
    SelectOption: Select.Option,
    onlinestatusVue,
    barChartVue,
    lineChartVue,
  },
  setup() {
    const data = reactive({
      moment,
      group: 0,
      types: 0,
    });
    onMounted(() => {});
    return { ...toRefs(data) };
  },
  props: {
    typesList: Array,
    groupList: Array,
  },
};
</script>

<style></style>