<template>
  <div v-if="title" class="asdwadwad">
    <div v-if="!show">
      <Empty style="height: 220px" description="暂无存储数据" />
    </div>
    <div v-show="show" :id="title" style="width: 320px; height: 220px"></div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted, ref, watch, onUnmounted } from "vue";
import { Chart } from "@antv/g2";
import { Empty } from "ant-design-vue";
export default {
  components: { Empty },
  setup(props) {
    let data = reactive({
      title: "dasdaw123",
      chart: null,
      data: [],
      show: true,
    });
    const refData = toRefs(data);
    onMounted(() => {
      data.title = "dasdaw123";
      newChart(props.externalValue.total, props.externalValue.remain);
    });
    onUnmounted(() => {
      // 销毁页面
      if (data.chart) {
        data.chart.destroy();
        data.chart = null;
      }
    });
    watch(
      () => props,
      () => {
        if (props.externalValue.total && props.externalValue.remain) {
          newChart(props.externalValue.total, props.externalValue.remain);
        }
      },
      { deep: true }
    );
    const newChart = (total, remain) => {
      if (data.chart) {
        data.chart.destroy();
        data.chart = null;
      }
      data.show = true;
      if (!total || !remain) {
        data.show = false;
        return;
      }
      var use = total - remain;
      const data1 = [
        {
          item: "剩余存储",
          count: remain,
          percent: Number((remain / total).toFixed(2)),
        },
        {
          item: "使用存储",
          count: use,
          percent: Number((use / total).toFixed(2)),
        },
      ];
      const chart = new Chart({
        container: data.title,
        autoFit: true,
        height: 250,
      });
      data.chart = chart;
      chart.data(data1);
      chart.scale("percent", {
        formatter: (val) => {
          val = parseInt(val * 100) + "%";
          return val;
        },
      });
      chart.coordinate("theta", {
        radius: 0.75,
        innerRadius: 0.6,
      });
      chart.tooltip({
        showTitle: false,
        showMarkers: false,
        itemTpl:
          '<li class="g2-tooltip-list-item"><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>',
      });
      // 辅助文本
      chart
        .annotation()
        .text({
          position: ["50%", "55%"],
          content: use + " MB",
          style: {
            fontSize: 13,
            fill: getColor(total, use),
            textAlign: "center",
          },
          offsetY: -20,
        })
        .text({
          position: ["50%", "50%"],
          content: "--------",
          style: {
            fontSize: 20,
            fill: "#8c8c8c",
            textAlign: "center",
          },
          offsetY: 0,
        })
        .text({
          position: ["50%", "48%"],
          content: (total / 1000).toFixed(2) + " GB",
          style: {
            fontSize: 15,
            fill: "black",
            textAlign: "center",
          },
          offsetY: 20,
        });
      chart
        .interval()
        .adjust("stack")
        .position("percent")
        .color("percent", (percent) => {
          if (percent.length == 2) {
            if (percent[0] == 0) {
              return "green";
            }
            return getColor(total, use);
          }
          return "green";
        })
        .color({
          fields: ["item"],
          values: ["darkgray", getColor(total, use)],
        })
        .label("percent", (percent) => {
          return {
            content: (data1) => {
              let value = data1.item + ":" + (percent * 100).toFixed(0) + "%";
              return value;
            },
          };
        })
        .tooltip("item*percent", (item, percent) => {
          percent = (percent * 100).toFixed(0) + "%";
          return {
            name: item,
            value: percent,
          };
        });

      chart.interaction("element-active");

      chart.render();
    };
    function getColor(max, n) {
      let c = Math.max(n / max);
      let red = (1 - c) * 255;
      let green = 255 - (1 - c) * 255;
      return "rgb(" + green + "," + red + "," + 10 + ")";
    }
    const ruleForm = ref(null);
    return {
      ruleForm,
      getColor,
      ...refData,
      newChart,
    };
  },
  props: {
    externalValue: Object,
  },
};
</script>

<style>
.asdwadwad {
  /* background: rgb(214, 213, 213); */
  float: left;
}
</style>
