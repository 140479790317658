<template>
	<div class="update1">
		<div class="update2">
			<Form
				:rules="rules"
				ref="formRef"
				:model="form"
				@finish="finish"
				style="width: 400px"
			>
				<FormItem required has-feedback name="oldpws">
					<InputPassword
						:maxlength="30"
						allowClear
						placeholder="请输入旧的登录密码"
						v-model:value="form.oldpws"
						style="width: 400px"
					/>
				</FormItem>
				<FormItem required has-feedback name="newpws">
					<InputPassword
						:maxlength="30"
						allowClear
						placeholder="请输入新的登录密码"
						v-model:value="form.newpws"
						style="width: 400px"
					/>
				</FormItem>
				<FormItem required has-feedback name="newpws1">
					<InputPassword
						:maxlength="30"
						allowClear
						placeholder="请再次输入新的登录密码"
						v-model:value="form.newpws1"
						style="width: 400px"
					/>
				</FormItem>
				<formItem>
					<Button style="width: 400px" type="primary" html-type="submit">
						确定修改
					</Button>
					<Button style="width: 400px" @click="resetForm">重置</Button>
				</formItem>
			</Form>
		</div>
	</div>
</template>

<script>
	import { Form, Input, Button, notification } from "ant-design-vue";
	import { ref, getCurrentInstance } from "vue";
	import router from "@/router";
	import md5 from "js-md5";
	import publics from "@/components/menu/public";
	export default {
		components: {
			Form,
			FormItem: Form.Item,
			InputPassword: Input.Password,
			Button
		},
		setup() {
			const { proxy } = getCurrentInstance();
			const form = ref({
				oldpws: "",
				newpws: "",
				newpws1: ""
			});
			const validator = (rule, value) => {
				switch (rule.field) {
					case "oldpws":
						if (!value) {
							return Promise.reject("请输入正确的旧登录密码");
						}
						if (value.length < 4) {
							return Promise.reject("请输入正确的旧登录密码");
						}
						if (!proxy.$req.checPassword(value)) {
							return Promise.reject("请输入正确的旧登录密码");
						}
						break;
					case "newpws":
						if (!value) {
							return Promise.reject("请输入正确的新登录密码");
						}
						if (value.length < 4) {
							return Promise.reject("请输入正确的新登录密码");
						}
						if (!proxy.$req.checPassword(value)) {
							return Promise.reject("请输入正确的新登录密码");
						}
						break;
					case "newpws1":
						if (!value) {
							return Promise.reject("请输入正确的新登录密码");
						}
						if (value != form.value.newpws) {
							return Promise.reject("请再次输入相同的新登录密码");
						}
						break;
				}
				return Promise.reject();
			};
			const rules = {
				oldpws: [{ validator: validator, trigger: "change" }],
				newpws: [{ validator: validator, trigger: "change" }],
				newpws1: [{ validator: validator, trigger: "change" }]
			};
			const resetForm = () => {
				formRef.value.resetFields();
			};
			const formRef = ref();
			const finish = (Event) => {
				let now = new Date().getTime();
				proxy.$req
					.put("/v6/user/amend", {
						newpws: md5(Event.newpws),
						oldpws: md5(md5(Event.oldpws) + now),
						time: now
					})
					.then((response) => {
						if (response.code) {
							notification["error"]({
								message: "修改密码失败",
								description: response.message
							});
							return;
						}
						proxy.$req.setCookie();
						publics.setCookie();
						router.push("/");
					});
			};
			return { formRef, form, rules, finish, resetForm };
		}
	};
</script>

<style>
	.update1 {
		height: 100%;
		widows: 100%;
		text-align: center;
	}
	.update2 {
		height: 300px;
		width: 400px;
		margin: 0 auto;
		position: relative;
		top: 20%;
	}
</style>
