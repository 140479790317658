<template>
  <Modal
    @cancel="close(null)"
    :visible="true"
    :footer="null"
    title="添加安装应用白名单"
  >
    <Form
      ref="formRef"
      @finish="finish"
      :model="form"
      :labelCol="{
        span: 4,
      }"
    >
      <FormItem
        :rules="{ validator: validator, required: true, trigger: 'change' }"
        name="name"
        label="应用包名"
      >
        <Input
          :maxlength="30"
          allowClear
          placeholder="请输入应用包名"
          v-model:value="form.name"
          style="width: 360px"
        />
      </FormItem>
      <FormItem
        :rules="{ validator: validator, required: true, trigger: 'change' }"
        name="md5Value"
        label="应用签名"
      >
        <Input
          :maxlength="32"
          allowClear
          placeholder="请输入应用签名MD5"
          v-model:value="form.md5Value"
          style="width: 360px"
        />
      </FormItem>
      <FormItem
        :rules="{ validator: validator, required: true, trigger: 'change' }"
        name="internal"
        label="系统内置应用"
        :labelCol="{
          span: 6,
        }"
      >
        <RadioGroup v-model:value="form.internal">
          <Radio :value="true">是</Radio>
          <Radio :value="false">否</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem
        :rules="{ validator: validator, required: true, trigger: 'change' }"
        name="smartSDK"
        label="允许SmartSDK权限"
        :labelCol="{
          span: 8,
        }"
      >
        <RadioGroup v-model:value="form.smartSDK">
          <Radio :value="true">允许</Radio>
          <Radio :value="false">禁止</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem :wrapper-col="{ offset: 6 }">
        <Space>
          <Button @click="close(null)"> 取消 </Button>
          <Button type="primary" html-type="submit"> 确定创建 </Button>
        </Space>
      </FormItem>
    </Form>
  </Modal>
</template>

<script>
import { Modal, Form, Input, Radio, Space, Button } from "ant-design-vue";
import { reactive, ref, toRefs } from "@vue/reactivity";
export default {
  components: {
    Modal,
    Form,
    FormItem: Form.Item,
    Input,
    Radio,
    RadioGroup: Radio.Group,
    Space,
    Button,
  },
  setup(props, { emit }) {
    const data = reactive({});
    const close = (result) => {
      emit("close", result);
    };
    const form = ref({
      name: "",
      md5Value: "",
      internal: false,
      smartSDK: false,
    });
    const finish = () => {
      const info = {
        应用包名: form.value.name,
        应用签名MD5: form.value.md5Value,
        是否系统内置应用: form.value.internal,
        允许SmartSDK权限: form.value.smartSDK,
      };
      close(info);
    };
    const validator = (rule, value) => {
      switch (rule.field) {
        case "name":
          if (value == "") {
            return Promise.reject("应用包名不能为空");
          }
          break;
        case "md5Value":
          if (value == "") {
            return Promise.reject("应用签名不能为空");
          }
          if (value.length != 32) {
            return Promise.reject("应用签名长度必须为32位");
          }
          if (!/^[\da-z]+$/i.test(value)) {
            return Promise.reject("应用签名长度必须为32位的数字/字母组合");
          }
          break;
      }

      return Promise.reject();
    };
    return { ...toRefs(data), close, finish, form, validator };
  },
};
</script>

<style>
</style>