<template>
  <div>
    <Row>
      <div class="divserach">
        <span>商户组：</span>
        <Select style="width: 240px" v-model:value="group" show-search>
          <SelectOption :key="0">全部分组</SelectOption>
          <!-- groupList -->
          <SelectOption v-for="v in groupList" :key="v.id">
            {{ v.name }}
          </SelectOption>
        </Select>
      </div>
      <div class="divserach">
        <span>设备型号：</span>
        <Select style="width: 240px" v-model:value="types" show-search>
          <SelectOption :key="0">所有型号</SelectOption>
          <SelectOption v-for="v in typesList" :key="v.id">
            {{ v.name }}
          </SelectOption>
        </Select>
      </div>
      <div class="divserach">
        <myRangePickerVue @searchTime="searchTime" :defaultTime="defaultTime" :type="'second'" :disabledDateNum="3" />
      </div>
    </Row>
    <Row>
      <Col :span="24">
      <AbnormalChartVue url="/v6/distributed/abnormal" title="设备异常分布" :group="group" :types="types"
        color="rgb(136 222 138)" :startTime="startTime" :endTime="endTime" />
      </Col>
    </Row>
  </div>
</template>

<script>
import moment from "moment";
import { Select, Row, Col } from "ant-design-vue";
import { reactive, toRefs, onMounted } from "vue";
import AbnormalChartVue from "./AbnormalChart.vue";
import myRangePickerVue from "@/global/pubilc/myRangePicker.vue";

export default {
  components: {
    Select,
    Row,
    Col,
    SelectOption: Select.Option,
    AbnormalChartVue,
    myRangePickerVue,
  },
  setup() {
    const data = reactive({
      moment,
      typesList: [],
      groupList: [],
      group: 0,
      types: 0,
      startTime: 0,
      endTime: 0,
      defaultTime: [
        moment("00:00:00", "HH:mm:ss"),
        moment("23:59:59", "HH:mm:ss"),
      ],
    });
    onMounted(() => {});
    const searchTime = (startTime, endTime) => {
      data.startTime = startTime;
      data.endTime = endTime;
    };
    return { ...toRefs(data), searchTime };
  },
  props: {
    typesList: Array,
    groupList: Array,
  },
};
</script>

<style>
.asdasd {
  float: right;
  margin-right: 0px;
}
</style>