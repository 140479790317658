import { notification } from "ant-design-vue";

export default {
  post: async (proxy, paprm) => {
    const response = await proxy.$req.post("/v6/apkinstall", paprm);
    if (response.code != 0) {
      notification["error"]({
        message: "添加失败",
        description: response.message,
      });
      return;
    }
    notification["success"]({
      message: "添加成功",
      description: "添加成功",
    });
    return response;
  },
  put: async (proxy, paprm) => {
    const response = await proxy.$req.put("/v6/apkinstall", paprm);
    if (response.code != 0) {
      notification["error"]({
        message: "提交失败",
        description: response.message,
      });
      return;
    }
    notification["success"]({
      message: "提交成功",
      description: "提交成功",
    });
    return response;
  },
  get: async (proxy, paprm) => {
    const response = await proxy.$req.get("/v6/apkinstall", paprm);
    if (response.code != 0) {
      notification["error"]({
        message: "获取列表失败",
        description: response.message,
      });
      return;
    }
    return response;
  },
  del: async (proxy, paprm) => {
    const response = await proxy.$req.del("/v6/apkinstall/" + paprm);
    if (response.code != 0) {
      notification["error"]({
        message: "删除失败",
        description: response.message,
      });
      return;
    }
    notification["success"]({
      message: "删除成功",
      description: "删除成功",
    });
    return response;
  },
  snPost: async (proxy, paprm) => {
    const response = await proxy.$req.post("/v6/apkinstall/sn", paprm);
    if (response.code != 0) {
      notification["error"]({
        message: "添加失败",
        description: response.message,
      });
      return;
    }
    notification["success"]({
      message: "添加成功",
      description: "添加成功",
    });
    return response;
  },
  snGet: async (proxy, paprm) => {
    const response = await proxy.$req.get("/v6/apkinstall/sn", paprm);
    if (response.code != 0) {
      notification["error"]({
        message: "获取列表失败",
        description: response.message,
      });
      return;
    }
    return response;
  },
  snDel: async (proxy, paprm) => {
    const response = await proxy.$req.put("/v6/apkinstall/sn", paprm);
    if (response.code != 0) {
      notification["error"]({
        message: "删除失败",
        description: response.message,
      });
      return;
    }
    notification["success"]({
      message: "删除成功",
      description: "删除成功",
    });
    return response;
  },
  countGet: async (proxy, paprm) => {
    const response = await proxy.$req.get("/v6/apkinstall/count", paprm);
    if (response.code != 0) {
      notification["error"]({
        message: "获取升级统计失败",
        description: response.message,
      });
      return;
    }
    return response;
  },
};
