import { createRouter, createWebHashHistory } from "vue-router";
import index from "../views/index/index.vue";
import update from "../views/index/update.vue";
import group from "../views/group/index.vue";
import userinfoInfo from "../views/userinfo/info/index.vue";
import userinfoUpdate from "../views/userinfo/update/index.vue";
import groupdetails from "../views/group/details.vue";
import apk from "../views/apk/apk.vue";
import apkinstall from "../views/apkinstall/apkinstall.vue";
import apksn from "../views/apkinstall/apksn.vue";
import wechat from "../views/wechat/wechat.vue";
import deviceMonitor from "../views/deviceInfo/deviceMonitor";
import deviceWarn from "../views/deviceInfo/deviceWarn";
import instruction from "../views/instruction/index";
import indexMd5 from "../views/index/indexMd5.vue";


const routes = [
  {
    path: "/",
    name: "index",
    component: index,
  },
  {
    path: "/md5",
    name: "indexMd5",
    component: indexMd5,
  },
  {
    path: "/apk/apkinfo",
    name: "apk/apkinfo",
    component: apk,
  },
  {
    path: "/apk/apkinstall",
    name: "apk/apkinstall",
    component: apkinstall,
  },
  {
    path: "/apk/apkinstall/apksn",
    name: "apk/apkinstall/apksn",
    component: apksn,
  },
  {
    path: "/update",
    name: "update",
    component: update,
  },
  {
    path: "/group",
    name: "group",
    component: group,
  },
  {
    path: "/group/details",
    name: "group/details",
    component: groupdetails,
  },

  {
    path: "/deviceInfo/deviceMonitor",
    name: "/deviceInfo/deviceMonitor",
    component: deviceMonitor,
  },
  {
    path: "/deviceInfo/deviceWarn",
    name: "/deviceInfo/deviceWarn",
    component: deviceWarn,
  },
  {
    path: "/userinfo/update",
    name: "userinfo/update",
    component: userinfoUpdate,
  },
  {
    path: "/userinfo/info",
    name: "userinfo/info",
    component: userinfoInfo,
  },
  {
    path: "/wechat",
    name: "wechat",
    component: wechat,
  },
  {
    path: "/instruction",
    name: "instruction",
    component: instruction,
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/index/About.vue"),
  },
];

// import configureallinfo from "../views/configure/allinfo";
// import configuregroupinfo from "../views/configure/groupinfo";
// import configuregroup from "../views/configure/group";

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
