import {notification} from 'ant-design-vue';

export default {
  downPost:
      async (proxy, paprm) => {
        const response = await proxy.$req.post('/v6/instruction/down', paprm);
        if (response.code != 0) {
          notification['error']({
            message: '下行失败',
            description: response.message,
          });
          return;
        }
        notification['success']({
          message: '下行成功',
          description: '下行成功',
        });
        return response;
      },
  get: async (proxy, paprm) => {
    const response = await proxy.$req.get('/v6/instruction', paprm);
    if (response.code != 0) {
      notification['error']({
        message: '获取列表失败',
        description: response.message,
      });
      return;
    }
    return response;
  },
  getDown: async (proxy, paprm) => {
    const response = await proxy.$req.get('/v6/instruction/down', paprm);
    if (response.code != 0) {
      notification['error']({
        message: '获取列表失败',
        description: response.message,
      });
      return;
    }
    return response;
  },
  getGroupList: async (proxy) => {
    const response = await proxy.$req.get('/v6/deice/group');
    if (response.code != 0) {
      notification['error']({
        message: '获取商户组失败',
        description: response.message,
      });
      return [];
    }
    return response.data;
  },
  getTypesList: async (proxy) => {
    const response = await proxy.$req.get('/v6/deice/types');
    if (response.code != 0) {
      notification['error']({
        message: '获取设备型号失败',
        description: response.message,
      });
      return [];
    }
    return response.data;
  },
  getDeviceNum: async (proxy, paprm) => {
    const response = await proxy.$req.get('/v6/deice/list', paprm);
    if (response.code != 0) {
      notification['error']({
        message: '获取设备数量失败',
        description: response.message,
      });
      return;
    }
    return response;
  }
};
