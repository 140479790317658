<template>
  <indexVue>
    <Tabs v-model:activeKey="activeKey" @change="handelChangeTab">
      <TabPane key="1" tab="设备列表">
        <tableVue :groupList="groupList" :typesList="typesList" v-if="activeKey == '1'" />
      </TabPane>
      <TabPane key="2" tab="设备状态" force-render>
        <statusVue :groupList="groupList" :typesList="typesList" v-if="activeKey == '2'" />
      </TabPane>
      <TabPane key="3" tab="网络状态">
        <networkVue :groupList="groupList" :typesList="typesList" v-if="activeKey == '3'" />
      </TabPane>
      <TabPane key="4" tab="异常分布" v-if="false">
        <abnormalVue :groupList="groupList" :typesList="typesList" v-if="activeKey == '4'" />
      </TabPane>
      <TabPane key="5" tab="异常列表">
        <abnormallistVue :groupList="groupList" :typesList="typesList" v-if="activeKey == '5'" />
      </TabPane>
    </Tabs>
  </indexVue>
</template>
<script>
// @ is an alias to /src
import { onMounted, reactive, ref, toRefs, getCurrentInstance } from "vue";
import { Tabs, notification } from "ant-design-vue";
import tableVue from "./list/table.vue";
import statusVue from "./status/status.vue";
import networkVue from "./network/network.vue";
import abnormalVue from "./abnormal/abnormal.vue";
import abnormallistVue from "./abnormallist/abnormallist.vue";
import indexVue from "@/components/menu/index.vue";

export default {
  name: "home",
  components: {
    statusVue,
    tableVue,
    Tabs,
    TabPane: Tabs.TabPane,
    networkVue,
    abnormalVue,
    abnormallistVue,
    indexVue,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    let data = reactive({
      collapsed: false,
      breadcrumbList: [],
      activeKey: "1",
      groupList: [],
      typesList: [],
    });
    const deviceList = ref(null);
    const deviceStatus = ref(null);
    const deviceNetwork = ref(null);
    const deviceDistribute = ref(null);
    onMounted(() => {
      getGroupList();
      getTypesList();
    });
    if (document.body.clientWidth <= 1200) {
      data.collapsed = true;
    }
    const refData = toRefs(data);
    const cliidclose = (value) => {
      data.breadcrumbList = value;
    };
    const handelChangeTab = (value) => {
      if (value === "1") {
        const modal = deviceList.value;
        if (!modal) {
          return;
        }
        modal.getData();
      } else if (value === "2") {
        const modal = deviceStatus.value;
        if (!modal) {
          return;
        }
        modal.init();
      } else if (value === "3") {
        const modal = deviceNetwork.value;
        if (!modal) {
          return;
        }
        modal.init();
      } else if (value === "4") {
        const modal = deviceDistribute.value;
        if (!modal) {
          return;
        }
        modal.init();
      }
    };
    const getGroupList = () => {
      proxy.$req.get("/v6/deice/group", {}).then((response) => {
        if (response.code) {
          notification["error"]({
            message: "获取商户组信息失败",
            description: response.message,
          });
          return;
        }
        data.groupList = [{ id: -1, name: "未分组" }].concat(response.data);
      });
    };
    const getTypesList = () => {
      proxy.$req.get("/v6/deice/types").then((response) => {
        if (response.code) {
          notification["error"]({
            message: "获取设备型号信息失败",
            description: response.message,
          });
          return;
        }
        data.typesList = response.data;
      });
    };
    return {
      deviceList,
      deviceStatus,
      deviceNetwork,
      deviceDistribute,
      ...refData,
      cliidclose,
      handelChangeTab,
    };
  },
};
</script>
<style scoped></style>
