<template>
  <indexVue>
    <Tabs v-model:activeKey="activeKey">
      <TabPane key="1" tab="预警列表">
        <warnListVue v-if="activeKey == '1'" />
      </TabPane>
      <TabPane key="2" tab="预警日志" force-render
        ><warnLogVue v-if="activeKey == '2'"
      /></TabPane>
    </Tabs>
  </indexVue>
</template>
<script>
// @ is an alias to /src
import { onMounted, reactive, toRefs } from "vue";
import { Tabs } from "ant-design-vue";
import warnListVue from "./warnList/index.vue";
import warnLogVue from "./warnLog/index.vue";
import indexVue from "@/components/menu/index.vue";
export default {
  name: "home",
  components: {
    indexVue,
    Tabs,
    TabPane: Tabs.TabPane,
    warnListVue,
    warnLogVue,
  },
  setup() {
    // const { proxy } = getCurrentInstance();
    let data = reactive({
      collapsed: false,
      breadcrumbList: [],
      activeKey: "1",
    });

    onMounted(() => {});
    if (document.body.clientWidth <= 1200) {
      data.collapsed = true;
    }
    const refData = toRefs(data);
    const cliidclose = (value) => {
      data.breadcrumbList = value;
    };
    return {
      ...refData,
      cliidclose,
    };
  },
};
</script>
