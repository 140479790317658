<template>
  <Drawer :visible="true" @close="returnGroup" :width="800">
    <template #title>
      <span v-if="nameDis == null">创建商户组</span>
      <span v-else>修改商户组信息</span>
    </template>
    <div class="groupadd1">
      <div class="groupadd2">
        <Form :rules="rules" ref="formRef" :model="form" @finish="finish" :labelCol="{
                      span: 4,
                    }" :wrapperCol="{
                          span: 17,
                        }" :style="{
                          minWidth: '800px',
                        }">
          <FormItem required has-feedback name="name" label="商户组名称">
            <Input :maxlength="30" allowClear placeholder="请输入商户组名称" v-model:value="form.name"
              :disabled="nameDis != null" />
          </FormItem>
          <FormItem has-feedback name="details" label="商户组备注">
            <Input :maxlength="30" allowClear placeholder="请输入商户组备注" v-model:value="form.details" />
          </FormItem>
          <FormItem has-feedback name="appid" label="小程序APPID">
            <Input :maxlength="16" allowClear placeholder="请次输入小程序APPID" v-model:value="form.appid" />
          </FormItem>
          <FormItem has-feedback name="whitelist" label="安装应用白名单">
            <whitelistVue v-model:whitelist="form.whitelist" />
          </FormItem>
          <FormItem :wrapper-col="{ offset: 3 }">
            <Space>
              <Button style="width: 300px" @click="returnGroup"> 取消 </Button>
              <Button style="width: 300px" type="primary" html-type="submit">
                确定
              </Button>
            </Space>
          </FormItem>
        </Form>
      </div>
    </div>
  </Drawer>
</template>

<script>
import {
  Form,
  Input,
  Button,
  notification,
  Space,
  Drawer,
} from "ant-design-vue";
import {
  ref,
  getCurrentInstance,
  reactive,
  toRefs,
  onMounted,
  onBeforeMount,
} from "vue";
import router from "@/router";
import whitelistVue from "./whitelist.vue";

export default {
  components: {
    Form,
    FormItem: Form.Item,
    Input,
    Button,
    Space,
    whitelistVue,
    Drawer,
  },
  setup(props, { emit }) {
    const data = reactive({
      visible: true,
    });
    const { proxy } = getCurrentInstance();
    const form = ref({
      name: "",
      details: "",
      appid: "",
      whitelist: [],
    });
    const validator = (rule, value) => {
      switch (rule.field) {
        case "whitelist":
          if (value.length == 0) {
            break;
          }
          for (const v of value) {
            if (v["应用包名"] == "" && v["应用签名MD5"] == "") {
              continue;
            }
            if (v["应用包名"] == "") {
              return Promise.reject("请输入正确的安装应用白名单组");
            }
            if (
              v["应用签名MD5"].length != 47 &&
              v["应用签名MD5"].length != 32
            ) {
              return Promise.reject("请输入正确的安装应用白名单组");
            }
          }
          break;
        case "name":
          if (!value) {
            return Promise.reject("请输入正确的商户组名称");
          }
          if (value.length < 4) {
            return Promise.reject("请输入正确的商户组名称");
          }
          if (!proxy.$req.checkName(value)) {
            return Promise.reject("商户组名称不能包含特殊字符");
          }
          break;
        case "details":
          if (!value) {
            break;
          }
          if (!proxy.$req.checkName(value)) {
            return Promise.reject("商户组备注不能包含特殊字符");
          }
          break;
        case "appid":
          if (!value) {
            return Promise.reject();
          }
          if (value.length != 16) {
            return Promise.reject("请输入正确的小程序APPID");
          }
          break;
      }
      return Promise.reject();
    };
    const rules = {
      name: [{ validator: validator, trigger: "change" }],
      details: [{ validator: validator, trigger: "change" }],
      appid: [{ validator: validator, trigger: "change" }],
      whitelist: [{ validator: validator, trigger: "change" }],
    };
    const formRef = ref();
    const finish = (Event) => {
      var dataInfo = {};
      if (Event.appid != "") {
        dataInfo["小程序"] = {
          小程序ID: Event.appid,
        };
      }
      for (const v of Event.whitelist) {
        if (!dataInfo["安装白名单"]) {
          dataInfo["安装白名单"] = [];
        }
        dataInfo["安装白名单"].push({
          应用包名: v["应用包名"],
          应用签名MD5: v["应用签名MD5"],
          是否系统内置应用: v["是否系统内置应用"],
          允许SmartSDK权限: v["允许SmartSDK权限"],
        });
      }
      if (props.nameDis == null) {
        proxy.$req
          .post("/v6/group", {
            name: Event.name,
            details: Event.details,
            data: JSON.stringify(dataInfo),
          })
          .then((response) => {
            if (response.code) {
              notification["error"]({
                message: "创建失败",
                description: response.message,
              });
              return;
            }
            notification["success"]({
              message: "创建成功",
              description: "创建成功",
            });
            returnGroup();
          });
      } else {
        proxy.$req
          .put("/v6/group", {
            ID: props.nameDis.ID,
            details: Event.details ? Event.details : "-",
            data: JSON.stringify(dataInfo),
          })
          .then((response) => {
            if (response.code) {
              notification["error"]({
                message: "修改失败",
                description: response.message,
              });
              return;
            }
            notification["success"]({
              message: "修改成功",
              description: "修改成功",
            });
            returnGroup();
          });
      }
    };
    const returnGroup = () => {
      if (props.nameDis == null) {
        router.push("/group");
      } else {
        router.push("/group/details?id=" + props.nameDis.ID);
      }
      emit("my-emit");
    };
    onMounted(() => {});
    onBeforeMount(() => {
      if (props.nameDis != null) {
        getGroupInfo();
      }
    });
    const getGroupInfo = () => {
      form.value.name = props.nameDis.name;
      form.value.details = props.nameDis.details;
      var respData = props.nameDis.data;
      form.value.appid = respData["小程序"]
        ? respData["小程序"]["小程序ID"]
        : "";
      // 安装白名单
      form.value.whitelist = respData["安装白名单"]
        ? respData["安装白名单"]
        : [];
    };
    return { ...toRefs(data), formRef, form, rules, finish, returnGroup };
  },
  props: {
    nameDis: Object,
  },
};
</script>

<style>
.groupadd1 {
  height: 100%;
  widows: 100%;
}

.groupadd2 {
  min-height: 300px;
  min-width: 800px;
  position: relative;
  top: 10%;
}
</style>
