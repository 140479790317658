<template>
  <div>
    时间：
    <DatePickerRangePicker
      :allowClear="false"
      v-model:value="searchTime"
      @calendarChange="onCalendarChange"
      format="YYYY-MM-DD HH:mm:ss"
      @openChange="onOpenChange"
      @ok="DatePickerOK"
      @change="handleSelectTime"
      :showTime="{
        hideDisabledOptions: true,
        defaultValue: [
          moment('00:00:00', 'HH:mm:ss'),
          moment('23:59:59', 'HH:mm:ss'),
        ],
      }"
      :disabledDate="disabledDate"
    />
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from "vue";
import { DatePicker } from "ant-design-vue";
import moment from "moment";

export default {
  components: {
    DatePickerRangePicker: DatePicker.RangePicker,
  },
  setup(props, { emit }) {
    let data = reactive({
      moment: moment,
      startTime: 0,
      endTime: 0,
      searchTime: [],
      searchTime1: [],
      dates: [],
      disabledDateNum: 7,
    });
    onMounted(() => {
      if (props.defaultTime && props.defaultTime.length == 2) {
        //
        data.searchTime = props.defaultTime;
        DatePickerOK(data.searchTime);
      }
      if (props.disabledDateNum > 0) {
        data.disabledDateNum = props.disabledDateNum;
      }
    });
    const onCalendarChange = (val) => {
      data.searchTime = val;
      data.dates = val;
    };
    const onOpenChange = (state) => {
      if (state) {
        data.searchTime1 = data.searchTime;
      } else {
        data.searchTime = data.searchTime1;
      }
      if (state) {
        data.dates = [];
      }
    };
    const DatePickerOK = (date) => {
      data.searchTime1 = date;
      if (props.type == "second") {
        data.startTime = date[0].unix();
        data.endTime = date[1].unix();
      } else {
        data.startTime = date[0].valueOf();
        data.endTime = date[1].valueOf();
      }
      searchTime();
    };
    const handleSelectTime = (Value) => {
      if (Value.length == 0) {
        data.startTime = 0;
        data.endTime = 0;
        searchTime();
      }
    };
    const searchTime = () => {
      emit("searchTime", data.startTime, data.endTime);
    };
    const disabledDate = (current) => {
      if (!data.dates || data.dates.length === 0) {
        return false;
      }
      if (!current) {
        return;
      }
      const diffDate = current.diff(data.dates[0], "days");
      return Math.abs(diffDate) > data.disabledDateNum;
    };
    return {
      ...toRefs(data),
      onCalendarChange,
      onOpenChange,
      DatePickerOK,
      handleSelectTime,
      disabledDate,
    };
  },
  props: {
    type: String,
    defaultTime: Array,
    disabledDateNum: Number,
  },
};
</script>

<style></style>
