<template>
  <div v-if="title" class="asdwadwad2">
    <div class="asdwadwad21">
      <div class="asdwadwad211">数据分区</div>
      <div v-if="externalValue.data" class="asdwadwad212">
        <span v-if="externalValue.datauser < 1000" style="color: green">
          {{ externalValue.datauser }}
          <span style="font-weight: initial; font-size: 10px">MB/</span>
        </span>
        <span v-else style="color: green">
          {{ (externalValue.datauser / 1000).toFixed(2) }}
          <span style="font-weight: initial; font-size: 10px">GB/</span>
        </span>

        <span v-if="externalValue.data < 1000">
          {{ externalValue.data }}
          <span style="font-weight: initial; font-size: 10px">MB</span>
        </span>
        <span v-else>
          {{ (externalValue.data / 1000).toFixed(2) }}
          <span style="font-weight: initial; font-size: 10px">GB</span>
        </span>
      </div>
      <div
        v-else
        class="asdwadwad212"
        style="font-weight: initial; font-size: 12px; color: red"
      >
        暂无数据
      </div>
    </div>
    <div class="asdwadwad21">
      <div class="asdwadwad211">系统分区</div>
      <div v-if="externalValue.storageuser" class="asdwadwad212">
        <span v-if="externalValue.storageuser < 1000" style="color: green">
          {{ externalValue.storageuser }}
          <span style="font-weight: initial; font-size: 10px">MB/</span>
        </span>
        <span v-else style="color: green">
          {{ (externalValue.storageuser / 1000).toFixed(2) }}
          <span style="font-weight: initial; font-size: 10px">GB/</span>
        </span>

        <span v-if="externalValue.storage < 1000">
          {{ externalValue.storage }}
          <span style="font-weight: initial; font-size: 10px">MB</span>
        </span>
        <span v-else>
          {{ (externalValue.storage / 1000).toFixed(2) }}
          <span style="font-weight: initial; font-size: 10px">GB</span>
        </span>
      </div>
      <div
        v-else
        class="asdwadwad212"
        style="font-weight: initial; font-size: 12px; color: red"
      >
        暂无数据
      </div>
    </div>
    <div class="asdwadwad21">
      <div class="asdwadwad211">剩余存储</div>
      <div v-if="externalValue.remain" class="asdwadwad212">
        <span v-if="externalValue.remain < 1000">
          {{ externalValue.remain }}
          <span style="font-weight: initial; font-size: 10px">MB</span>
        </span>
        <span v-else>
          {{ (externalValue.remain / 1000).toFixed(2) }}
          <span style="font-weight: initial; font-size: 10px">GB</span>
        </span>
      </div>
      <div
        v-else
        class="asdwadwad212"
        style="font-weight: initial; font-size: 12px; color: red"
      >
        暂无数据
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, ref, onUpdated } from "vue";

// import { Chart } from "@antv/g2";
export default {
  setup() {
    let data = reactive({
      title: "dasdaw",
      chart: null,
      data: [],
    });
    const refData = toRefs(data);
    onUpdated(() => {});
    onMounted(() => {});
    const ruleForm = ref(null);
    return {
      ruleForm,
      ...refData,
    };
  },
  props: {
    externalValue: Object,
  },
};
</script>

<style>
.asdwadwad2 {
  width: 128px;
  height: 200px;
  float: left;
}
.asdwadwad21 {
  height: 60px;
  width: 12px;
  margin-top: 5px;
}
.asdwadwad211 {
  height: 30px;
  width: 128px;
  padding-left: 20px;
  line-height: 40px;
  font-size: 10px;
}
.asdwadwad212 {
  height: 30px;
  width: 128px;
  padding-left: 20px;
  line-height: 25px;
  font-size: 12px;
  color: black;
  font-weight: 700;
}
</style>
