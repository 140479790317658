/**
 * 设备在线状态
 */
export const deviceStatus = [
  { value: 1, label: "在线" },
  { value: 2, label: "离线" },
];

/**
 * 预警方式
 */
export const warnTypes = [
  { value: 1, label: "大于" },
  { value: 2, label: "等于" },
  { value: 3, label: "小于" },
];

/**
 * 预警项
 */
export const warnItems = [
  { value: "CPU使用率", label: "CPU使用率" },
  { value: "CPU温度", label: "CPU温度" },
  { value: "剩余内存", label: "剩余内存(MB)" },
  { value: "剩余存储", label: "剩余存储(MB)" },
  { value: "单日移动网络流量", label: "单日移动网络流量(MB)" },
  { value: "单月移动网络流量", label: "单月移动网络流量(MB)" },
  { value: "单日设备重启次数", label: "单日设备重启次数" },
];

/**
 * 预警项颜色
 */
export const warnItemsColors = [
  { value: "CPU使用率", label: "#f50" },
  { value: "CPU温度", label: "#2db7f5" },
  { value: "剩余内存", label: "#8e20b3" },
  { value: "剩余存储", label: "#108ee9" },
  { value: "单日移动网络流量", label: "#fa8c16" },
  { value: "单日设备重启次数", label: "#ff4d4f" },
  { value: "单月移动网络流量", label: "#52c41a" },
];

export const deviceStatusDict = dictArray2Map(deviceStatus);
export const warnTypesDict = dictArray2Map(warnTypes);
export const warnItemsColorsDict = dictArray2Map(warnItemsColors);

function dictArray2Map(dictArray) {
  return new Map(dictArray.map((dict) => [dict.value, dict.label]));
}
