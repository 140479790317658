<template>
  <div class="checkall">
    <Checkbox
      v-model:checked="checkAll"
      :indeterminate="indeterminate"
      @change="onCheckAllChange"
    >
      全选
    </Checkbox>
    <div class="checkallGroup">
      <CheckboxGroup v-model:value="checkedList" @change="change">
        <div v-for="(v, k) in dataList" :key="k">
          <Checkbox :value="v.id" style="font-size: 18px">
            <span>{{ v.name }}</span>
          </Checkbox>
        </div>
      </CheckboxGroup>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, watch } from "vue";
import { Checkbox } from "ant-design-vue";

export default {
  components: { Checkbox, CheckboxGroup: Checkbox.Group },
  setup(props, { emit }) {
    const state = reactive({
      indeterminate: false,
      checkAll: false,
      checkedList: [],
    });
    const onCheckAllChange = (e) => {
      Object.assign(state, {
        checkedList: e.target.checked ? getID() : [],
        indeterminate: false,
      });
      emit("close", state.checkedList);
    };
    const getID = () => {
      return props.dataList.map((v) => {
        return v.id;
      });
    };
    const change = () => {
      emit("close", state.checkedList);
    };
    watch(
      () => state.checkedList,
      (val) => {
        state.indeterminate =
          !!val.length && val.length < props.dataList.length;
        state.checkAll = val.length === props.dataList.length;
      }
    );
    onMounted(() => {
      //   emit("close", []);
    });
    return {
      ...toRefs(state),
      onCheckAllChange,
      change,
    };
  },
  props: {
    dataList: Array,
  },
};
</script>

<style lang="scss" scoped>
.checkall {
}
.checkallGroup {
  margin-top: 15px;
  height: 350px;
  overflow: scroll;
  border: 1px solid wheat;
  padding-left: 10px;
}
</style>