<template>
    <div class="infochild">
        <Tabs v-model:activeKey="activeKey" type="card">
            <TabPane v-for=" value in info.Value" :key="value['SN']" :tab="value['名称'] + ' ' + value['SN']">
                <Descriptions :bordered="true" size="small" :column="4">
                    <template #title>
                        <div style="float: left;">
                            {{ value['名称'] }}
                        </div>
                        <div style="float: right;">
                            <Popconfirm title="是否确定解除该外设绑定状态" @confirm="confirm(value)">
                                <Button type="dashed" danger :disabled="!value['绑定状态']">
                                    解绑
                                </Button>
                            </Popconfirm>
                        </div>
                    </template>
                    <template v-for=" i, k in value" :key="k">
                        <DescriptionsItem :label="k">
                            <span v-if="k != '子设备'">{{ i }}</span>
                        </DescriptionsItem>
                    </template>
                </Descriptions>
                <div style="background: #ececec; padding: 5px;">
                    <div v-for=" item, k in value['子设备']" :key="k" style="background: #ececec; padding: 5px;">
                        <div style="background: white; padding: 5px;">
                            <Descriptions :bordered="true" size="small" :column="4">
                                <template #title>
                                    <div style="float: left;">
                                        {{ item['名称'] }}
                                    </div>
                                </template>
                                <DescriptionsItem v-for=" i, k in item" :key="k" :label="k">
                                    {{ i }}
                                </DescriptionsItem>
                            </Descriptions>
                        </div>
                    </div>
                </div>
            </TabPane>
        </Tabs>
        <!-- 更新时间 -->
        <div v-if="info.Time" style="float: right; border-bottom: 1px solid green;">
            <TypographyTitle :level="4">更新时间：
                {{ new Date(parseInt(info.Time)).toLocaleString() }}
            </TypographyTitle>
        </div>
    </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance, onMounted } from 'vue'
import { Typography, Descriptions, Button, Popconfirm, notification, Tabs } from "ant-design-vue";
export default {
    components: { TypographyTitle: Typography.Title, Descriptions, DescriptionsItem: Descriptions.Item, Button, Popconfirm, Tabs, TabPane: Tabs.TabPane },
    setup(props) {
        // 设备外挂
        const { proxy } = getCurrentInstance()
        const state = reactive({
            info: {},
            activeKey: ""
        })
        onMounted(() => {
            console.log(props.detailsinfo);
            getData()
        })
        const getData = async () => {
            proxy.$req.get("/v6/deice/waigua", { sn: props.detailsinfo.sn }).then(resp => {
                if (resp.code != 0) {
                    return
                }
                const data = JSON.parse(resp.data)
                if (!data['key'] || data['key'].length == 0) {
                    return
                }
                state.info = data['key'][0]
                state.info.Value = JSON.parse(state.info.Value)
                if (state.info.Value.length > 0) {
                    state.activeKey = state.info.Value[0]['SN']
                }
            })
        }
        const confirm = (item) => {
            var msg = {
                "product": item['产品型号'],
                "sn": item['SN'],
            }
            proxy.$req.post("/v6/deice/down", {
                "sn": props.detailsinfo.sn,
                "msg": JSON.stringify(msg),
            }).then(resp => {
                if (resp.code != 0) {
                    notification['error']({
                        message: "发送失败",
                        description: resp.message,
                    })
                    return
                }
                notification['success']({
                    message: "发送成功",
                    description: resp.message,
                })
            })
        }
        return {
            ...toRefs(state), confirm, getData
        }
    },
    props: {
        detailsinfo: Object,
    },
}
</script>

<style lang="scss" scoped></style>