<template>
  <div>
    <Card title="信号强度" :bordered="false">
      <lineChartVue
        :startTime="moment().startOf('day')"
        :id="info.id"
        title="signalChart"
        url="/v6/deice/signal"
        :maxValue="0"
        :minValue="-140"
        :name="'信号值(db)'"
      />
    </Card>
    <Card title="设备日使用流量分布" :bordered="false">
      <lineChartVue
        :startTime="moment().add(-1, 'M').startOf('day')"
        :id="info.id"
        title="flowChartVue"
        url="/v6/deice/flow"
        :maxValue="120"
        :minValue="0"
        :name="'流量值(MB)'"
      />
    </Card>
  </div>
</template>

<script>
import { Card } from "ant-design-vue";
import { reactive, toRefs } from "vue";
import lineChartVue from "@/global/pubilc/lineChart.vue";
import moment from "moment";

export default {
  components: { Card, lineChartVue },
  setup() {
    const data = reactive({ moment });

    return { ...toRefs(data) };
  },
  props: {
    info: Object,
  },
};
</script>

<style>
</style>