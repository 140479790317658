<template>
  <div style="overflow: auto; height: 100%">
    <Row>
      <div class="divserach">
        <span>商户组：</span>
        <Select style="width: 240px" v-model:value="group" show-search>
          <SelectOption :key="0">全部分组</SelectOption>
          <!-- groupList -->
          <SelectOption v-for="v in groupList" :key="v.id">
            {{ v.name }}
          </SelectOption>
        </Select>
      </div>
      <div class="divserach">
        <span>设备型号：</span>
        <Select style="width: 240px" v-model:value="types" show-search>
          <SelectOption :key="0">所有型号</SelectOption>
          <SelectOption v-for="v in typesList" :key="v.id">
            {{ v.name }}
          </SelectOption>
        </Select>
      </div>
    </Row>
    <Row>
      <Col :span="12">
      <peiChartVue url="/v6/distributed/network/link" title="活跃设备网络连接方式" :group="group" :types="types" />
      </Col>
      <Col :span="12">
      <barChartVue url="/v6/distributed/network/signal" title="活跃设备信号" :group="group" :types="types" color="#063d8a"
        type="signal" />
      </Col>
    </Row>
    <Row>
      <Col :span="24">
      <rankCardVue title="设备月流量使用分布" :group="group" :types="types" :startTime="moment().startOf('month')"
        :endTime="moment()" />
      </Col>
    </Row>
  </div>
</template>

<script>
import moment from "moment";
import { Select, Row, Col } from "ant-design-vue";
import { reactive, toRefs, onMounted } from "vue";
import peiChartVue from "./peiChart.vue";
import barChartVue from "./barChart.vue";
import rankCardVue from "./rank/rankCard.vue";

export default {
  components: {
    Select,
    Row,
    Col,
    SelectOption: Select.Option,
    peiChartVue,
    barChartVue,
    rankCardVue,
  },
  setup() {
    const data = reactive({
      moment,
      group: 0,
      types: 0,
    });
    onMounted(() => {});
    return { ...toRefs(data) };
  },
  props: {
    typesList: Array,
    groupList: Array,
  },
};
</script>

<style></style>