
import router from "@/router";
import { getCurrentInstance } from "vue";
import { notification } from "ant-design-vue";
function getRount() {
  return router.currentRoute._rawValue.name.split("/")
}
const cookieName = "userinfo"
var userinfoCache = null
function setCookie(userinfo) {
  if (userinfo == null) {
    document.cookie =
      cookieName +
      "=" + ";SameSite=Lax";
    userinfoCache = null
    return
  }
  userinfoCache = userinfo
  document.cookie =
    cookieName +
    "=" +
    escape(JSON.stringify(userinfoCache)) +
    ";SameSite=Lax";
}

// 获取登陆cookie
function getCookie() {
  var key = cookieName;
  if (document.cookie.length == 0) {
    return null;
  }
  var start = document.cookie.indexOf(key + "=");
  if (start == -1) {
    return null;
  }
  start = start + key.length + 1;
  var end = document.cookie.indexOf(";", start);
  if (end === -1) end = document.cookie.length;
  let infos = unescape(
    document.cookie.substring(start, end)
  );
  if (infos.length < 30) {
    setCookie("", -1);
    return null;
  }
  userinfoCache = JSON.parse(infos)
  return userinfoCache;
}

const getUserInfo = async () => {
  if (userinfoCache) {
    return userinfoCache
  }
  userinfoCache = getCookie()
  if (userinfoCache) {
    return userinfoCache
  }
  const { proxy } = getCurrentInstance();
  var response = await proxy.$req.get("/v6/user/info", {})
  if (response.code) {
    notification["error"]({
      message: "获取用户信息失败",
      description: response.message
    });
    return {};
  }
  setCookie(response.data)
  return response.data
};

export default {
  getRount,
  getUserInfo,
  setCookie
}