<template>
  <Descriptions :column="3" :bordered="true" size="small">
    <DescriptionsItem label="设备SN">
      {{ info.sn }}
    </DescriptionsItem>
    <DescriptionsItem label="出厂日期">
      {{
        moment(parseInt(info.updatetime))
          .utcOffset(480)
          .format("YYYY-MM-DD HH:mm:ss")
      }}
    </DescriptionsItem>
    <DescriptionsItem label="设备状态">
      <Tag :color="info.status == 1 ? 'green' : 'red'">{{
        info.status == 1 ? "在线" : "离线"
      }}</Tag>
    </DescriptionsItem>
    <DescriptionsItem label="Android版本号">
      {{ baseInfo.android }}
    </DescriptionsItem>
    <DescriptionsItem label="App版本号">
      {{ baseInfo.app }}
    </DescriptionsItem>
    <DescriptionsItem label="芯片固件版本号">
      {{ baseInfo.chipversion }}
    </DescriptionsItem>
    <DescriptionsItem label="CPU版本">
      {{ baseInfo.cpu }}
    </DescriptionsItem>
    <DescriptionsItem label="dragonfly版本号">
      {{ baseInfo.dragonflv }}
    </DescriptionsItem>
    <DescriptionsItem label="操作系统版本号">
      {{ baseInfo.systemversion }}
    </DescriptionsItem>
    <DescriptionsItem label="3D固件版本号">
      {{ baseInfo.version3d }}
    </DescriptionsItem>
    <DescriptionsItem label="xpaas版本号">
      {{ baseInfo.xpaas }}
    </DescriptionsItem>
    <DescriptionsItem label="IOT SDK版本号">
      {{ baseInfo.iotsdk }}
    </DescriptionsItem>
    <DescriptionsItem label="smile版本号">
      {{ baseInfo.smile }}
    </DescriptionsItem>
    <DescriptionsItem label="内核版本">
      {{ baseInfo.kernel }}
    </DescriptionsItem>
    <DescriptionsItem label="MAC地址">
      {{ baseInfo.mac }}
    </DescriptionsItem>
    <DescriptionsItem label="Barcode">
      {{ baseInfo.barcode }}
    </DescriptionsItem>
    <DescriptionsItem label="Imsi" :span="2">
      {{ baseInfo.imsi }}
    </DescriptionsItem>
    <DescriptionsItem label="定位地址" :span="3" v-if="false">
      <span v-if="deviceAddrInfo['省']">
        {{ deviceAddrInfo["省"] + deviceAddrInfo["市"] + deviceAddrInfo["区"] }}
        <a>
          ({{
            moment(parseInt(deviceAddrInfo["Time"]))
              .utcOffset(480)
              .format("YYYY-MM-DD HH:mm:ss")
          }})
        </a>
      </span>
      <span v-else style="color: red">未知</span>
    </DescriptionsItem>
  </Descriptions>
</template>

<script>
import { getCurrentInstance, onMounted, reactive, toRefs } from "vue";
import { Descriptions, Tag, notification } from "ant-design-vue";
import moment from "moment";
export default {
  components: { Descriptions, DescriptionsItem: Descriptions.Item, Tag },
  setup(props) {
    const { proxy } = getCurrentInstance();
    const data = reactive({ moment, baseInfo: {}, deviceAddrInfo: {} });
    onMounted(() => {
      getDataBasicInfo();
      getDeviceAddr();
    });
    const getDataBasicInfo = () => {
      proxy.$req
        .get("/v6/deice/info", {
          id: props.info.id,
        })
        .then((response) => {
          if (response.code != 0) {
            notification["error"]({
              message: "获取设备基本信息失败",
              description: response.message,
            });
            return;
          }
          data.baseInfo = response.data;
        });
    };

    const getDeviceAddr = () => {
      proxy.$req
        .get("/v6/deice/addr", {
          id: props.info.id,
        })
        .then((response) => {
          if (response.code) {
            notification["error"]({
              message: "获取设备地址失败",
              description: response.message,
            });
            return;
          }
          if (!response.data) {
            return;
          }
          const result = JSON.parse(response.data);
          data.deviceAddrInfo = result.length ? result[0] : {};
        });
    };
    return { ...toRefs(data), getDataBasicInfo, getDeviceAddr };
  },
  props: {
    info: Object,
  },
};
</script>

<style></style>