<template>
  <indexVue>
    <div class="installApk">
      <div>
        <Button @click="retInstall" type="link"
          ><DoubleLeftOutlined /> 返回升级任务列表
        </Button>
      </div>
      <Descriptions title="升级任务详细" bordered size="small" :column="3">
        <DescriptionsItem label="名称">{{ dataInfo.name }}</DescriptionsItem>
        <DescriptionsItem label="创建时间">
          {{
            moment(parseInt(dataInfo.createtime))
              .utcOffset(480)
              .format("YYYY-MM-DD HH:mm:ss")
          }}
        </DescriptionsItem>
        <DescriptionsItem label="审核时间" v-if="dataInfo.updatetime != '0'">
          {{
            moment(parseInt(dataInfo.updatetime))
              .utcOffset(480)
              .format("YYYY-MM-DD HH:mm:ss")
          }}
        </DescriptionsItem>
        <!-- data.dataInfo.apkname = response.data.name;
          data.dataInfo.apkdetails = response.data.details;
          data.dataInfo.apkedition = response.data.edition; -->
        <DescriptionsItem label="APK名称">
          {{ dataInfo.apkname }}
        </DescriptionsItem>
        <DescriptionsItem label="APK版本">
          {{ dataInfo.versionname }}
        </DescriptionsItem>
        <DescriptionsItem label="状态">
          <span v-if="dataInfo.status == 3" style="color: green">审核成功</span>
          <span v-else-if="dataInfo.status == 4" style="color: red">
            审核失败
          </span>
          <span v-else-if="dataInfo.status == 2" style="color: blue">
            待审核
          </span>
          <span v-else>待提交</span>
        </DescriptionsItem>
        <DescriptionsItem label="设备总数">
          {{ dataInfo.await + dataInfo.success + dataInfo.fails }}
        </DescriptionsItem>
        <DescriptionsItem label="待升级数">
          <span style="color: blue"> {{ dataInfo.await }}</span>
        </DescriptionsItem>
        <DescriptionsItem label="升级成功数">
          <span style="color: green">{{ dataInfo.success }}</span>
        </DescriptionsItem>
        <DescriptionsItem label="升级失败数">
          <span style="color: red">{{ dataInfo.fails }}</span>
        </DescriptionsItem>
        <DescriptionsItem label="更新说明">{{
          dataInfo.details
        }}</DescriptionsItem>
      </Descriptions>
    </div>
    <div class="divserach" v-if="dataInfo.status == 1">
      <Button @click="addShow = true" type="primary">添加设备</Button>
    </div>
    <div class="divserach">
      <span>升级状态：</span>
      <Select
        style="width: 240px"
        v-model:value="status"
        show-search
        @change="changeSelect"
      >
        <SelectOption :key="0">全部</SelectOption>
        <SelectOption :key="1"
          ><span style="color: blue">待升级</span>
        </SelectOption>
        <SelectOption :key="2">
          <span style="color: green">升级成功</span>
        </SelectOption>
        <SelectOption :key="3">
          <span style="color: red">升级失败</span>
        </SelectOption>
      </Select>
    </div>
    <div class="divserach">
      <span>检索：</span>
      <Input
        style="width: 240px"
        @change="changeSelect"
        allowClear
        v-model:value="fuzzy"
      />
    </div>

    <div class="divserach" style="float: right">
      <Popconfirm
        title="是否确认选中的设备删除?"
        @confirm="del(selectedRowKeys)"
        v-if="selectedRowKeys.length != 0 && dataInfo.status == 1"
      >
        <Button type="danger">删除选中</Button>
      </Popconfirm>
    </div>
    <div class="divserach" style="float: right">
      <Button type="primary" v-show="total != 0" @click="exportSN"
        >导出SN</Button
      >
    </div>
    <Table
      :columns="columns"
      :rowKey="(record) => record.ID"
      :pagination="false"
      :data-source="dataSource"
      :loading="loading"
      style="float: left; width: 100%"
      size="small"
      :rowSelection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
    >
      <template #index="{ index }">
        {{ index + (offset - 1) * limit + 1 }}
      </template>
      <template #createtime="{ text }">
        {{
          moment(parseInt(text)).utcOffset(480).format("YYYY-MM-DD HH:mm:ss")
        }}
      </template>
      <template #updatetime="{ text }">
        <span v-if="text != 0">{{
          moment(parseInt(text)).utcOffset(480).format("YYYY-MM-DD HH:mm:ss")
        }}</span>
        <span v-else> - </span>
      </template>
      <template #fails="{ text }">
        <Tooltip
          ><template #title>{{ text }}</template>
          <span> {{ text.substr(0, 20) }} </span>
          <span v-if="text.length > 20">....</span>
        </Tooltip>
      </template>
      <template #status="{ text }">
        <span v-if="text == 2" style="color: green">升级成功</span>
        <span v-else-if="text == 3" style="color: red">升级失败</span>
        <span v-else>待升级</span>
      </template>
      <template #operation="{ record }">
        <Space>
          <Popconfirm
            title="是否确认删除?"
            @confirm="del([record.ID])"
            v-if="dataInfo.status == 1"
          >
            <Button type="danger">删除</Button>
          </Popconfirm>
        </Space>
      </template>
    </Table>
    <Pagination
      :total="total"
      :showTotal="(total) => `总共 ${total} `"
      :pageSize="limit"
      show-quick-jumper
      :showSizeChanger="true"
      @change="paginationChange"
      @showSizeChange="showSizeChange"
      v-model:current="offset"
      :pageSizeOptions="['10', '20', '30', '50', '100']"
      style="float: right"
    >
      <template #buildOptionText="props">
        <span>{{ props.value }}/页</span>
      </template>
    </Pagination>
    <addVue
      v-if="addShow"
      @my-emit="cliidClose"
      :installID="Number(dataInfo.ID)"
    />
  </indexVue>
</template>

<script>
import { DoubleLeftOutlined } from "@ant-design/icons-vue";
import {
  Table,
  Pagination,
  Select,
  Input,
  Space,
  Button,
  Popconfirm,
  Descriptions,
  Tooltip,
} from "ant-design-vue";
import { reactive, toRefs, onMounted, getCurrentInstance } from "vue";
import moment from "moment";
import indexVue from "../../components/menu/index.vue";
import addVue from "./addsn.vue";
import Que from "./que";
import router from "@/router";
import QueApk from "../apk/que";

export default {
  components: {
    Table,
    Pagination,
    Select,
    SelectOption: Select.Option,
    Input,
    Space,
    Button,
    indexVue,
    addVue,
    Popconfirm,
    Descriptions,
    DescriptionsItem: Descriptions.Item,
    DoubleLeftOutlined,
    Tooltip,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    let data = reactive({
      moment,
      columns: [
        {
          title: "序号",
          key: "index",
          width: 70,
          slots: { customRender: "index" },
          fixed: "left",
        },
        {
          title: "SN",
          dataIndex: "sn",
        },
        {
          title: "升级状态",
          dataIndex: "success",
          slots: {
            customRender: "status",
          },
        },
        {
          title: "创建时间",
          dataIndex: "createtime",
          slots: {
            customRender: "createtime",
          },
        },
        {
          title: "升级时间",
          dataIndex: "updatetime",
          slots: {
            customRender: "updatetime",
          },
        },
        {
          title: "说明",
          dataIndex: "fails",
          slots: {
            customRender: "fails",
          },
        },
      ],
      dataSource: [],
      total: 0,
      loading: false,
      limit: 10,
      offset: 1,
      selectTimeout: null,
      status: 0,
      fuzzy: "",
      addShow: false,
      detailsShow: false,
      info: {},
      dataInfo: {},
      selectedRowKeys: [],
    });
    const refData = toRefs(data);
    const getData = async () => {
      data.loading = true;
      const response = await Que.snGet(proxy, {
        limit: data.limit,
        offset: (data.offset - 1) * data.limit,
        fuzzy: data.fuzzy,
        installid: data.dataInfo.ID,
        success: data.status,
      });
      data.loading = false;
      if (response) {
        data.dataSource = response.data;
        data.total = parseInt(response.total);
      }
    };
    onMounted(async () => {
      data.dataInfo = router.currentRoute.value.query;
      if (!data.dataInfo.ID) {
        retInstall();
      }
      getApkInfo(data.dataInfo.apkid);
      getCount(data.dataInfo.ID);
      data.loading = true;
      getData();
    });
    const paginationChange = () => {
      getData();
    };
    const showSizeChange = (current, size) => {
      data.offset = 1;
      data.limit = size;
      getData();
    };
    const changeSelect = () => {
      if (data.selectTimeout) {
        clearTimeout(data.selectTimeout);
      }
      data.selectTimeout = setTimeout(() => {
        getData();
      }, 500);
    };
    const del = async (ID) => {
      await Que.snDel(proxy, {
        ID: ID,
      });
      data.selectedRowKeys = [];
      getData();
    };
    const cliidClose = () => {
      data.addShow = false;
      getData();
      getApkInfo(data.dataInfo.apkid);
      getCount(data.dataInfo.ID);
    };
    const retInstall = () => {
      router.push({
        path: "/apk/apkinstall",
        name: "apk/apkinstall",
      });
    };
    const getApkInfo = (ID) => {
      QueApk.IDGet(proxy, ID).then((response) => {
        if (response) {
          data.dataInfo.apkname = response.data.name;
          data.dataInfo.apkdetails = response.data.details;
          data.dataInfo.versionname = response.data.versionname;
        }
      });
    };
    const getCount = (installID) => {
      Que.countGet(proxy, { installid: installID }).then((response) => {
        if (response) {
          Object.assign(data.dataInfo, response.data);
        }
      });
    };
    const onSelectChange = (selectedRowKeys) => {
      console.log(selectedRowKeys);
      data.selectedRowKeys = selectedRowKeys;
    };
    const exportSN = async () => {
      const response = await Que.snGet(proxy, {
        limit: 9999,
        offset: 0,
        fuzzy: data.fuzzy,
        installid: data.dataInfo.ID,
        success: data.status,
      });
      if (response) {
        let info = [];
        for (const v of response.data) {
          let success = "待升级";
          if (v.success == 2) {
            success = "升级成功";
          } else if (v.success == 3) {
            success = "升级失败";
          }
          info.push({
            SN: v.sn,
            失败信息: v.fails,
            状态: success,
            更新时间:
              v.updatetime == 0
                ? ""
                : moment(parseInt(v.updatetime))
                    .utcOffset(480)
                    .format("YYYY-MM-DD HH:mm:ss"),
          });
        }
        proxy.$req.exportExcel("导出SN列表", info);
      }
    };
    return {
      ...refData,
      paginationChange,
      showSizeChange,
      changeSelect,
      del,
      cliidClose,
      retInstall,
      getApkInfo,
      getCount,
      onSelectChange,
      exportSN,
    };
  },
};
</script>

<style>
.installApk {
  background: white;
  padding: 10px;
  border: 1px solid rgb(202, 202, 202);
}
</style>