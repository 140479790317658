<template>
  <a-card :title="title" :bordered="true" style="min-height: 350px">
    <a-date-picker
      :allowClear="false"
      @change="searchTime"
      v-model:value="defaultTime"
      :type="'second'" />
    <div style="margin-top: 5px" />
    <a-spin :spinning="spinning">
      <div v-if="info.length == 0" style="height: 230px">
        <Empty description="暂无数据" />
      </div>
      <div :id="title"></div> </a-spin
  ></a-card>
</template>

<script>
import {
  reactive,
  toRefs,
  getCurrentInstance,
  watch,
  onMounted,
  onUnmounted,
} from "vue";
import { Chart } from "@antv/g2";
import {
  Card as ACard,
  Empty,
  Spin as ASpin,
  DatePicker as ADatePicker,
  notification,
} from "ant-design-vue";
import moment from "moment";
export default {
  components: { ACard, Empty, ASpin, ADatePicker },
  setup(props) {
    const { proxy } = getCurrentInstance();
    let data = reactive({
      info: [],
      chart: null,
      title: props.title,
      defaultTime: moment(),
      startTime: moment("00:00:00", "HH:mm:ss").unix(),
      endTime: moment("23:59:59", "HH:mm:ss").unix(),
      spinning: false,
      init: false,
    });
    const getData = () => {
      data.spinning = true;
      proxy.$req
        .get(props.url, {
          group: props.group,
          types: props.types,
          startTime: data.startTime,
          endTime: data.endTime,
        })
        .then((response) => {
          if (response.code != 0) {
            notification["error"]({
              message: "获取数据失败",
              description: response.message,
            });
            return;
          }

          if (data.chart) {
            data.chart.destroy();
            data.chart = null;
          }
          data.info = JSON.parse(response.data);
          if (props.type == "storge") {
            data.info.sort(function (a, b) {
              if (a.label.includes(">")) {
                return 1;
              }
              if (b.label.includes(">")) {
                return -1;
              }
              if (a.label && b.label) {
                const sort1 = a.label.substring(0, a.label.indexOf("-"));
                const sort2 = b.label.substring(0, b.label.indexOf("-"));
                return sort1 - sort2;
              } else {
                return -1;
              }
            });
          } else if (props.type == "signal") {
            data.info.sort((star, next) => {
              const order = ["优秀", "良好", "一般", "差", "非常差"];
              return order.indexOf(star.label) - order.indexOf(next.label);
            });
          }

          if (!data.info.length) {
            return;
          }
          newChart();
        })
        .finally(() => {
          data.spinning = false;
        });
    };
    const newChart = () => {
      if (data.init) {
        return;
      }
      const chart = new Chart({
        container: props.title,
        autoFit: true,
        height: 230,
        padding: [50, 0, 20, 30],
      });
      data.chart = chart;
      chart.data(data.info);
      chart.scale("value", {
        nice: true,
      });
      chart.scale("label", {
        nice: true,
      });
      chart.axis("value", {
        label: {
          formatter: (val) => {
            return val;
          },
        },
      });
      chart.axis("label", {
        tickLine: null,
      });
      chart.tooltip({
        showMarkers: false,
      });
      chart.interaction("element-active");

      chart.legend(false);
      chart
        .interval({
          background: {
            style: {
              radius: 8,
            },
          },
        })
        .position("label*value")
        .color("label", () => {
          return props.color;
        })
        .label("value", {
          content: (originData) => {
            return originData.value + "台";
          },
          offset: 5,
        })
        .size("label", () => {
          let width = data.chart.width;
          const maxWidth = Math.min(50, 0.1 * width);
          return maxWidth;
        })
        .tooltip("label*value", (label, value) => {
          return {
            name: label,
            value: value + " 台",
          };
        });

      chart.render();
    };
    const searchTime = (_, time) => {
      data.startTime = moment(time + " 00:00:00").unix();
      data.endTime = moment(time + " 23:59:59").unix();
      getData();
    };
    watch(
      () => props,
      () => {
        getData();
      },
      { deep: true }
    );
    onMounted(() => {
      getData();
    });
    onUnmounted(() => {
      if (data.chart) {
        data.chart.destroy();
      }
      data.init = true;
    });
    return { moment, searchTime, ...toRefs(data) };
  },
  props: {
    type: String,
    url: String,
    title: String,
    group: Number,
    types: Number,
    color: String,
    refresh: Boolean,
  },
};
</script>
