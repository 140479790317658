<template>
  <div>
    <a-modal
      :maskClosable="false"
      :width="500"
      centered
      v-model:visible="visible"
      title="添加邮箱"
      @ok="handleOk"
    >
      <a-form :model="emailForm" ref="ruleForm">
        <a-form-item
          name="email"
          :rules="[{ validator: validateemail, trigger: 'change' }]"
        >
          <a-input
            v-model:value="emailForm.email"
            placeholder="请输入邮箱"
            style="width: 450px"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import {
  defineComponent,
  reactive,
  toRefs,
  ref,
  getCurrentInstance,
} from "vue";
import {
  Modal as AModal,
  Form as AForm,
  Input as AInput,
} from "ant-design-vue";
export default defineComponent({
  components: {
    AModal,
    AForm,
    AFormItem: AForm.Item,
    AInput,
  },
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    const data = reactive({
      visible: false,
      emailForm: { email: "" },
    });
    const ruleForm = ref(null);
    const showModal = () => {
      data.visible = true;
    };

    const handleOk = () => {
      if (!ruleForm.value) {
        return;
      }
      ruleForm.value.validate().then(() => {
        data.visible = false;
        const email = data.emailForm.email;
        data.emailForm.email = "";
        emit("saveEmails", email);
      });
    };

    const validateemail = (item, value) => {
      return !proxy.$req.checkEmail(value)
        ? Promise.reject("请输入正确的邮箱格式")
        : Promise.resolve();
    };
    return {
      ruleForm,
      validateemail,
      ...toRefs(data),
      showModal,
      handleOk,
    };
  },
});
</script>

<style>
.divserach {
  padding: 10px;
  float: left;
  position: relative;
}
</style>
